import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Popconfirm, Space, Table, Tag, Tooltip, Typography } from "antd";
import truncate from "lodash.truncate";
import { useTranslation } from "react-i18next";

import { teamActions } from "@fitness-app/app-store";
import {
  TeamMemberRole,
  TeamMemberStatus,
  type AssignedTrainer,
  type TeamMember,
  type TeamMemberRoleEnum,
  type TeamMemberWithUser,
} from "@fitness-app/data-models/entities/TeamMember";
import { getUserInitials } from "@fitness-app/utils";

import { useTagOptions } from "~/hooks/useTagOptions";
import { useAppDispatch } from "~/store/initializeStore";

interface TeamMemberTableProps {
  trainers: TeamMemberWithUser[];
  openEditForm: (trainer: TeamMemberWithUser) => void;
  role?: TeamMemberRoleEnum | "office";
}

const badgeStatus = {
  [TeamMemberStatus.PENDING]: "processing",
  [TeamMemberStatus.ACTIVE]: "success",
  [TeamMemberStatus.INACTIVE]: "error",
  [TeamMemberStatus.INVITED]: "warning",
} as const;

export const TeamMemberTable = ({ trainers, openEditForm, role }: TeamMemberTableProps): React.ReactElement => {
  const { t } = useTranslation(["team", "common"]);
  const dispatch = useAppDispatch();
  const { filters: tagsFilters } = useTagOptions("specializationTags");

  const removeTrainer = async (trainerId: string) => {
    await dispatch(teamActions.removeTrainerFromTeam(trainerId));
  };

  return (
    <Table
      dataSource={trainers}
      pagination={{ pageSize: 50, hideOnSinglePage: true }}
      rowKey="id"
      scroll={{ x: true }}
      expandable={{
        expandedRowRender: (record) => (
          <div className="max-w-[800px] space-y-4" key={record.id}>
            <Typography.Title level={5}>{t("common.assignedTrainers")}</Typography.Title>
            <Table
              rowKey={(record) => record.trainer.id}
              pagination={false}
              scroll={{ x: true }}
              dataSource={record.assignedTrainers || []}
            >
              <Table.Column<AssignedTrainer>
                title={t("common.name")}
                key="name"
                dataIndex="name"
                render={(_, row) => {
                  const name = `${row.trainer.firstName ?? ""} ${row.trainer.lastName ?? ""}`;
                  return (
                    <Space direction="horizontal">
                      <Avatar src={row.trainer?.user?.avatarUrl}>{getUserInitials(name)}</Avatar>
                      <span>{name}</span>
                    </Space>
                  );
                }}
              />
              <Table.Column<AssignedTrainer>
                title={t("common.email")}
                key="email"
                dataIndex={["trainer", "email"]}
                render={(email: string) => (
                  <Typography.Paragraph
                    copyable={{
                      tooltips: [t("common:copy"), t("common:copied")],
                      text: email,
                    }}
                    style={{ marginBottom: 0 }}
                  >
                    {email}
                  </Typography.Paragraph>
                )}
              />

              <Table.Column<AssignedTrainer>
                title={t("common.status")}
                key="status"
                dataIndex={["trainer", "status"]}
                render={(status: AssignedTrainer["trainer"]["status"]) => {
                  return (
                    <Badge
                      className="whitespace-nowrap"
                      status={badgeStatus[status]}
                      text={t(`status.${status.toLowerCase()}`)}
                    />
                  );
                }}
              />
            </Table>
          </div>
        ),
        rowExpandable: (record) => record.assignedTrainers?.length > 0,
      }}
    >
      <Table.Column
        key="id"
        dataIndex="id"
        title="ID"
        ellipsis
        render={(id: string) => (
          <Typography.Paragraph copyable={{ tooltips: [id, t("common:copied")], text: id }} style={{ marginBottom: 0 }}>
            {truncate(id, { length: 10 })}
          </Typography.Paragraph>
        )}
      />
      <Table.Column
        key="userId"
        dataIndex="userId"
        title="User ID"
        ellipsis
        render={(id: string) =>
          id ? (
            <Typography.Paragraph
              copyable={{ tooltips: [id, t("common:copied")], text: id }}
              style={{ marginBottom: 0 }}
            >
              {truncate(id, { length: 10 })}
            </Typography.Paragraph>
          ) : (
            "-"
          )
        }
      />
      <Table.Column<TeamMemberWithUser>
        title={t("common.name")}
        width="15%"
        key="name"
        dataIndex="name"
        render={(_, row) => {
          const name = `${row.firstName ?? ""} ${row.lastName ?? ""}`;
          return (
            <Space direction="horizontal">
              <Avatar src={row.user?.avatarUrl}>{getUserInitials(name)}</Avatar>
              <span>{name}</span>
            </Space>
          );
        }}
      />
      <Table.Column<TeamMemberWithUser>
        title={t("common.email")}
        key="email"
        ellipsis
        dataIndex="email"
        render={(email: string) => (
          <Typography.Paragraph
            copyable={{
              tooltips: [t("common:copy"), t("common:copied")],
              text: email,
            }}
            style={{ marginBottom: 0 }}
          >
            {email}
          </Typography.Paragraph>
        )}
      />
      <Table.Column<TeamMemberWithUser>
        title={t("common.role")}
        key="role"
        dataIndex="role"
        render={(role: string) => t(`role.${role.toLowerCase()}`)}
      />
      <Table.Column<TeamMember>
        title={t("common.status")}
        key="status"
        dataIndex="status"
        render={(status: TeamMember["status"], row) => {
          if (row.selectable === null) {
            return (
              <Badge
                status={badgeStatus[status]}
                className="whitespace-nowrap"
                text={t(`status.${status.toLowerCase()}`)}
              />
            );
          }

          if (!row.selectable) {
            return (
              <Space direction="horizontal">
                <Badge className="whitespace-nowrap" status="error" text={t("status.notSelectable")} />
                <Tooltip title={t("cannotCreateAccount")}>
                  <InfoCircleOutlined />
                </Tooltip>
              </Space>
            );
          }

          return (
            <Badge
              className="whitespace-nowrap"
              status={badgeStatus[status]}
              text={t(`status.${status.toLowerCase()}`)}
            />
          );
        }}
      />
      {role !== TeamMemberRole.ACCOUNT_OWNER && role !== "office" && (
        <Table.Column<TeamMemberWithUser>
          title={t("common.clientsNumber")}
          key="assignedTrainees"
          dataIndex="assignedTrainees"
          render={(assignedTrainees: string[]) => assignedTrainees?.length ?? 0}
        />
      )}

      {role === TeamMemberRole.SHARED_TRAINER && (
        <Table.Column<TeamMemberWithUser>
          title={t("common.trainersNumbers")}
          key="assignedTrainers"
          dataIndex="assignedTrainers"
          render={(trainersNumbers: string[]) => {
            return trainersNumbers?.length ?? 0;
          }}
        />
      )}

      {role !== TeamMemberRole.ACCOUNT_OWNER && role !== "office" && (
        <>
          <Table.Column<TeamMemberWithUser>
            title={t("membersSpecializations")}
            dataIndex="specializationTags"
            key="specializationTags"
            filterMultiple
            filterSearch
            filters={tagsFilters}
            onFilter={(value, record) => (record.specializationTags || []).includes(value as string)}
            render={(tags: string[]) =>
              tags?.length ? (
                <Space direction="vertical">
                  {tags.map((tag, i) => (
                    <Tag key={`${tag}-${i}`}>{tag}</Tag>
                  ))}
                </Space>
              ) : (
                "-"
              )
            }
          />
        </>
      )}

      {role !== TeamMemberRole.ACCOUNT_OWNER && (
        <Table.Column<TeamMemberWithUser>
          title={t("common:options")}
          key="options"
          dataIndex="options"
          render={(text, record) => (
            <div>
              <Button onClick={() => openEditForm(record)} type="link" disabled={!record.selectable}>
                {t("common:button.edit")}
              </Button>
              <Popconfirm
                title={t("removeTrainerConfirm")}
                okText={t("common:yes")}
                cancelText={t("common:no")}
                placement="left"
                onConfirm={() => removeTrainer(record.id)}
              >
                <Button danger type="link" disabled={record?.disabled || false}>
                  {t("common:button.delete")}
                </Button>
              </Popconfirm>
            </div>
          )}
        />
      )}
    </Table>
  );
};
