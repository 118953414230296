import { useState, type FunctionComponent } from "react";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Input, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { productActions } from "@fitness-app/app-store";

import { useEntityChange } from "~/hooks/useEntityChange";
import { useAppDispatch } from "~/store/initializeStore";

interface OwnProps {
  label?: string;
  id: string;
  productId: string;
  readonly?: boolean;
}

type Props = OwnProps;

const EditCustomInvoiceName: FunctionComponent<Props> = ({ label = "", productId, id, readonly }) => {
  const { t } = useTranslation("products");
  const [inputValue, setInputValue] = useState(label);
  const [editMode, toggleEditMode] = useState(false);
  const [loader, onSuccess, _, onStart] = useEntityChange(() => {
    toggleEditMode(false);
  });
  const dispatch = useAppDispatch();

  const onSave = async () => {
    onStart();
    if (!inputValue.length || inputValue === "") {
      await dispatch(
        productActions.updateProductPriceCustomInvoiceName({
          payload: {
            productId,
            priceId: id,
            customInvoiceName: "",
          },
        }),
      );
      setInputValue("");
    } else {
      await dispatch(
        productActions.updateProductPriceCustomInvoiceName({
          payload: {
            productId,
            priceId: id,
            customInvoiceName: inputValue,
          },
        }),
      );
    }

    onSuccess();
  };

  if (readonly && !inputValue) {
    return null;
  }

  if (!editMode) {
    return (
      <Space direction="horizontal" size={4}>
        <Typography.Text type="secondary">{t("productDetails.customInvoiceNameLabel")}</Typography.Text>
        {inputValue && <Typography.Text style={{ marginLeft: 5 }}>{inputValue}</Typography.Text>}
        {!readonly && <EditOutlined onClick={() => toggleEditMode((prev) => !prev)} />}
      </Space>
    );
  }
  return (
    <Space direction="horizontal" size={6}>
      <Input
        style={{ minWidth: 350 }}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        disabled={!!loader}
      />
      <CheckOutlined style={{ color: "#1bc5bd" }} onClick={onSave} />
      <CloseOutlined style={{ color: "#f64e60" }} onClick={() => toggleEditMode(false)} />
    </Space>
  );
};

export default EditCustomInvoiceName;
