import { type Database } from "@fitness-app/supabase";

import { type AppAccessType } from "./Automation";

export type TrainerConfigEntity = Database["public"]["Tables"]["trainer_config"]["Row"];
export type AppConfigEntity = Database["public"]["Tables"]["app_config"]["Row"];

export interface TrainerConfig extends TrainerConfigEntity {
  config: {
    limit?: number;
  };
  mealsPlanTags: string[];
  trainingProgramTags: string[];
  mealDaysTags: string[];
  traineeTags: string[];
  workoutTags: string[];
  specializationTags: string[];
  exercisesTags: string[];
}

export enum AppConfigType {
  APP_VERSION = "app_version",
}

export interface AppVersionData {
  current: string;
  minRequired: string;
  versionsWithIssues: string[];
}

export interface AppVersionByPlatform {
  ios: AppVersionData | null;
  android: AppVersionData | null;
  web: AppVersionData | null;
}

export enum FeatureStatus {
  Enabled = "enabled",
  Disabled = "disabled",
  Maintenance = "maintenance",
}

export type FeatureStatusPerAccessType = Record<AppAccessType, FeatureStatus>;

export interface ClientFeaturesFlags {
  aiAnalyzer?: FeatureStatus | FeatureStatusPerAccessType;
}

export interface TraineeFeaturesFlags {
  aiAnalyzer?: FeatureStatus;
}

export interface AppVersionConfig extends Omit<AppConfigEntity, "data" | "clientFeatures"> {
  type: AppConfigType.APP_VERSION;
  data: AppVersionByPlatform;
  clientFeatures: null | ClientFeaturesFlags;
}
