import { createAsyncThunk } from "@reduxjs/toolkit";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { TEAM_REDUCER_NAME, type AddTrainerDto } from "../types";
import { fetchTrainerConfig } from "./fetchTrainerConfig";

type Payload = AddTrainerDto;

export const addTrainerToTeam = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${TEAM_REDUCER_NAME}/addTrainerToTeam`,
  async ({ assignedTrainers, ...member }, { rejectWithValue, dispatch, extra: { db, auth, analytics } }) => {
    const { id } = await getLoggedUser(auth);

    const { error } = await db.from("team_member").insert({
      ...member,
      trainer: id,
    });

    if (error) {
      return rejectWithValue(error.message);
    }

    if (assignedTrainers.length) {
      const rows = assignedTrainers.map((trainerId) => ({
        headCoachId: member.id,
        assignedTrainerId: trainerId,
      }));

      await db.from("team_member_assigned").insert(rows);
    }

    void dispatch(fetchTrainerConfig());

    analytics.track("trainer-added-to-team", { trainerId: id, memberRole: member.role });
  },
);
