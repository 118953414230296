import { type Database } from "@fitness-app/supabase";

import { type AddProgramAutomationToClient, type AppAccessType } from "./Automation";
import { type DietaryPreference } from "./MealsPlan";
import { type ProductClient } from "./ProductClient";
import { type Gender } from "./shared";
import { type TraineeSettings } from "./TraineeSettings";
import { type TraineeFeaturesFlags } from "./TrainerConfig";

export type TraineeEntity = Database["public"]["Tables"]["trainee"]["Row"];

export enum TraineeStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  TERMINATED = "TERMINATED",
  ARCHIVED = "ARCHIVED",
}

export interface BaseTrainee extends Omit<TraineeEntity, "gender" | "status" | "metadata" | "type" | "tags"> {
  gender: Gender.MALE | Gender.FEMALE | null;
  status: TraineeStatus;
  metadata?: Record<string, null | string | number | object[] | object> & {
    automationToApply?: AddProgramAutomationToClient["programAutomation"][] | null;
    phoneNumber?: string | number | null;
    flags?: TraineeFeaturesFlags;
  };
  type: AppAccessType;
  dietaryPreference: DietaryPreference | null;
  tags: string[];
}

export interface TraineeWithFullAccess extends BaseTrainee {
  type: AppAccessType;
}

export interface TraineeWithLimitedAccess extends BaseTrainee {
  type: AppAccessType;
}

export interface AssignedTrainer {
  userId: string | null;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export type Trainee = TraineeWithFullAccess | TraineeWithLimitedAccess;

export type TraineeWithAssignedTrainer = Trainee & {
  assignedTrainer: (AssignedTrainer & { profile?: { avatarUrl?: string | null } }) | null;
};

export type TraineeWithAssignedTrainerAndProduct = TraineeWithAssignedTrainer & {
  productClient: { product: { name: string }; productId: string } | null;
};

export type TraineeProfile = TraineeWithAssignedTrainer & {
  user: { avatarUrl: string; firstName: string; lastName: string } | null;
} & {
  productClient: {
    product: { name: string };
    productId: string;
    status: ProductClient["status"];
    accessPeriod: ProductClient["accessPeriod"];
    productDuration: ProductClient["productDuration"];
    phoneNumber: string | null;
  } | null;
  settings: null | { onboarding: TraineeSettings["onboarding"] };
  nutrition: { id: string; name: string; startAt: string | null } | null;
};
