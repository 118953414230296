import React from "react";
import { SaveOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, Form, Input, Popconfirm, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { teamActions } from "@fitness-app/app-store";

import { useUserClaims } from "~/hooks/trainer/useUserClaims";
import { useEntityChange } from "~/hooks/useEntityChange";
import { useSurveysVariables } from "~/modules/SurveysTemplates/SurveysVariables/useSurveysVariables";
import {
  useSurveyTemplateVariables,
  type SurveysVariablesForm,
} from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeSurveys/useDefaultVariables";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

export const SurveysVariables = (): React.ReactElement => {
  const { isLoading, variables } = useSurveysVariables();
  const { memberId, isOwner } = useUserClaims();
  const selectedMember = useAppSelector((store) => store.team.selectedMember);
  const [formController] = Form.useForm<SurveysVariablesForm>();
  const { t } = useTranslation(["surveys", "common"]);
  const dispatch = useAppDispatch();
  useSurveyTemplateVariables(variables, formController);
  const [loading, onSuccess, onFailure, onStart] = useEntityChange();

  if (!variables?.length) {
    return (
      <Card className="!shadow-none" bordered={false} loading={isLoading}>
        <Empty description="Brak zmiennych globalnych w ankietach do ustawienia" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Card>
    );
  }

  const handleSubmit = async (values: SurveysVariablesForm) => {
    const memberIdToUpdate = isOwner ? selectedMember?.id : memberId;

    if (!memberIdToUpdate) {
      onFailure("Nie udało się pobrać informacji o użytkowniku. Spróbuj ponownie.");
      return;
    }
    onStart();

    try {
      await dispatch(
        teamActions.updateTrainerInTeam({
          memberId: memberIdToUpdate,
          data: { variables: values.variables },
          assignedTrainers: null,
        }),
      ).unwrap();
      onSuccess();
    } catch {
      onFailure();
    }
  };

  return (
    <Card className="!shadow-none" bordered={false} loading={isLoading}>
      <Form<SurveysVariablesForm>
        name="form"
        className="max-w-[750px]"
        wrapperCol={{ span: 14 }}
        form={formController}
        initialValues={{
          variables: {},
        }}
        onFinish={handleSubmit}
      >
        <div>
          <Row>
            <Col
              xs={{
                span: 24,
                offset: 0,
              }}
            >
              <Typography.Title level={5}>Zmienne dla automatyzacji</Typography.Title>
            </Col>
          </Row>

          {variables.map((variableName) => (
            <Form.Item
              key={variableName}
              label={variableName}
              name={["variables", variableName]}
              rules={[
                {
                  required: true,
                  message: t<string>("common:validationErrors.fieldIsRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          ))}
        </div>

        <Popconfirm
          title="Czy na pewno chcesz zapisać zmiany?"
          description="Po zapisaniu zmienne w obecnie zaplanowanych ankietach zostaną zaktualizowane."
          onConfirm={() => formController.submit()}
          okText="Tak"
          cancelText="Nie"
        >
          <Button loading={Boolean(loading)} icon={<SaveOutlined />} type="primary" className="mt-4">
            Aktualizuj
          </Button>
        </Popconfirm>
      </Form>
    </Card>
  );
};
