import React, { useEffect } from "react";
import { Alert, Card, Col, Layout, Row, Skeleton, Space } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import { RequestStatus, surveysTemplatesActions, traineeActions, traineeProgramActions } from "@fitness-app/app-store";

import { ChatUtilitiesProvider } from "~/modules/Chat/providers/ChatUtilitiesProvider";
import { TraineeFloatingButton } from "~/modules/Trainee/components/TraineeFloatingButton/TraineeFloatingButton";
import LatestActivity from "~/modules/Trainee/TraineeProfile/components/LatestActivity";
import ProfileInfo from "~/modules/Trainee/TraineeProfile/components/ProfileInfo";
import { TraineeDashboardNotes } from "~/modules/Trainee/TraineeProfile/components/TraineeDashboardNotes";
import TraineeFeatures from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeFeatures";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import TraineeStats from "./components/TraineeStats";

const TraineeProfile = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profileStatus = useAppSelector((store) => store.trainee.profileStatus);
  const profile = useAppSelector((store) => store.trainee.profile);
  const surveyTemplates = useAppSelector((store) => store.surveysTemplates.list);

  useEffect(() => {
    if (id) {
      void dispatch(traineeActions.subscribeToTrainee(id));
    } else {
      navigate("/trainee");
    }
    return () => {
      dispatch(traineeActions.unsubscribeFromTrainee());
    };
  }, [id, navigate, dispatch]);

  useEffect(() => {
    if (surveyTemplates.length === 0) {
      void dispatch(surveysTemplatesActions.fetchSurveysTemplates());
    }

    return () => {
      dispatch(traineeActions.unsubscribeFromTrainee());
    };
  }, []);

  const planId = profile?.activeTrainingProgramId;
  useEffect(() => {
    if (planId) {
      void dispatch(traineeProgramActions.fetchProgramWithDetails({ id: planId }));
    }
  }, [planId]);

  if (!id) {
    return null;
  }

  if (profileStatus === RequestStatus.FAILED) {
    return <Alert type="error" message="Nie udało się pobrać profilu" />;
  }

  return (
    <Layout className="p-6">
      <Space direction="vertical" size={16} className="w-full">
        <TraineeDashboardNotes traineeId={id} />
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={12}>
            <ProfileInfo />
          </Col>
          <Col xs={24} lg={12}>
            <LatestActivity />
          </Col>
        </Row>
        <TraineeStats />
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            {!profileStatus || profileStatus === RequestStatus.FETCHING ? (
              <Card>
                <Skeleton active loading round paragraph={{ rows: 5 }} />
              </Card>
            ) : (
              <TraineeFeatures />
            )}
          </Col>
        </Row>
      </Space>
      <ChatUtilitiesProvider>
        <TraineeFloatingButton />
      </ChatUtilitiesProvider>
    </Layout>
  );
};

export default TraineeProfile;
