import { LinkOutlined } from "@ant-design/icons";
import { Button, Divider, message, Popover, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { type ProductClient } from "@fitness-app/data-models/entities/ProductClient";

import { useGenerateRenewalLink } from "~/modules/Trainee/hooks/useGenerateRenewalLink";

interface TraineeRenewalLinkProps {
  productClient: ProductClient;
}

export const TraineeRenewalLink = ({ productClient }: TraineeRenewalLinkProps) => {
  const { t } = useTranslation(["products", "trainees"]);
  const { data, getVariantLink, restLinkWithoutDefault } = useGenerateRenewalLink(
    productClient.productId,
    productClient.email,
  );

  const copyLink = async (link: string) => {
    if (link) {
      await navigator.clipboard.writeText(link);
      message.success(t("Skopiowano link do schowka"));
    } else {
      message.warning("Nie udało się wygenerować linku do odnowienia. Brak dostępnych wariantów koszyka.");
    }
  };

  const defaultLink = getVariantLink(productClient.email);

  if (!data?.length || !defaultLink) {
    return null;
  }

  const content = (
    <div>
      <Typography.Text italic>Domyślny link do odnowienia:</Typography.Text>
      <div className="mt-2">
        <Button onClick={() => copyLink(defaultLink.link)} icon={<LinkOutlined />} type="link" size="small">
          /{defaultLink.slug}
        </Button>
      </div>

      <Divider />

      <Typography.Text italic>Pozostałe</Typography.Text>

      <div className="mt-2 flex flex-col items-start gap-2">
        {restLinkWithoutDefault.map((variant) => (
          <Button
            key={variant.slug}
            onClick={() => copyLink(variant.link)}
            icon={<LinkOutlined />}
            type="link"
            size="small"
          >
            /{variant.slug}
          </Button>
        ))}
      </div>
    </div>
  );

  return (
    <Popover trigger="click" content={content} title="Skopiuj link do odnowienia">
      <Button icon={<LinkOutlined />} type="link">
        {t(`productClients.copyLinkToRenewal`)}
      </Button>
    </Popover>
  );
};
