import { type Dayjs } from "dayjs";

import { type UserRoleEnum } from "@fitness-app/data-models";
import { MediaContentType, type CampaignType } from "@fitness-app/data-models/entities/Campaign";

import i18next from "~/i18nextConfig";

export enum FormMode {
  Calendar,
  Template,
  Template_Add,
}

export const weekDayLabels = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"] as const;

export type WeekdayLabels = (typeof weekDayLabels)[number];

export const contentTypeOptions = Object.values(MediaContentType).map((type) => ({
  value: type,
  label: i18next.t(`common:mediaContentType.${type}`),
}));

export const visualContentTypeOptions = [
  {
    value: "sheet",
    label: "Bottom sheet",
  },
  { value: "modal", label: "Modal" },
];

export interface CampaignMessageComposeFormModel {
  id: string | null;
  mode: FormMode;
  name: string;
  contentType: MediaContentType;
  type: CampaignType;
  message: {
    title: string;
    description: string;
    ctaLink: string;
    ctaText: string;
    image?: string[];
    videoId?: string | null;
    videoLink?: string | null;
    pushTitle?: string | null;
    pushDescription?: string | null;
  };
  visualType?: "sheet" | "modal" | null;
  authorId: string;
  authorName: string;
  authorRole: UserRoleEnum;
  authorAvatar: string | null;
  sendImmediately: boolean;
  sentDate?: Dayjs;
  sentTime: Dayjs;
  saveAsTemplate?: boolean;
  daysOfDelivery?: WeekdayLabels[];
  numberOfRepeats?: number;
}

export type SelectedUsersListItem = {
  id: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  assignedTrainerId: string | null;
};
