import React, { useState } from "react";
import { CommentOutlined, FileAddOutlined, MenuOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Drawer, FloatButton, Timeline } from "antd";
import orderBy from "lodash.orderby";

import { chatActions } from "@fitness-app/app-store";
import { ChatStatus } from "@fitness-app/data-models/entities/Chat";
import { type ClientNoteWithAuthorData } from "@fitness-app/data-models/entities/ClientNote";
import { TraineeStatus } from "@fitness-app/data-models/entities/Trainee";

import { useUserClaims } from "~/hooks/trainer/useUserClaims";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useChatUtilitiesContext } from "~/modules/Chat/providers/ChatUtilitiesProvider";
import { useTimelineItem } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNotes/useTimelineItem";
import { supabase, useAppDispatch, useAppSelector } from "~/store/initializeStore";

export const TraineeFloatingButton = () => {
  const profile = useAppSelector((store) => store.trainee.profile);
  const dispatch = useAppDispatch();
  const { isRegularTrainer } = useUserClaims();
  const { memberId } = useUserClaims();
  const { isTeamMember, isHeadCoach } = useUserRole();
  const { setNoteModel } = useChatUtilitiesContext();

  const [open, setOpen] = useState(false);
  const { createTimelineItem } = useTimelineItem();

  const { data } = useQuery(["floatingNotes", profile?.id], {
    keepPreviousData: true,
    queryFn: async () => {
      if (!profile) {
        return;
      }
      const { data } = await supabase
        .from("client_note")
        .select("*, author:authorId(avatarUrl, firstName, lastName)")
        .order("createdAt", { ascending: false })
        .eq("traineeId", profile.id)
        .returns<ClientNoteWithAuthorData[]>();

      return data;
    },
  });

  if (!profile) {
    return null;
  }

  const clientNotAssigned = isRegularTrainer && profile.assignedTrainerId !== memberId;
  const shouldDisableOption =
    (profile.status === TraineeStatus.ARCHIVED && isTeamMember) || profile.status === TraineeStatus.INACTIVE;

  const showMessageButton = !clientNotAssigned && !shouldDisableOption && profile.userId;

  return (
    <>
      <FloatButton.Group trigger="hover" type="primary" className="floating-button" icon={<MenuOutlined />}>
        <FloatButton
          tooltip="Dodaj notatkę"
          icon={<FileAddOutlined />}
          onClick={() =>
            setNoteModel({
              traineeId: profile.id,
              content: "",
            })
          }
        />
        {data?.length ? (
          <FloatButton
            onClick={() => setOpen(true)}
            tooltip="Pokaż notatki"
            badge={{ count: data.length, color: "blue" }}
          />
        ) : (
          <></>
        )}
        {showMessageButton ? (
          <FloatButton
            onClick={() => {
              dispatch(
                chatActions.findAndAddChannelToList({
                  userId: profile.userId!,
                  type: profile?.status === TraineeStatus.ARCHIVED ? ChatStatus.Archived : ChatStatus.Active,
                  isHeadCoach,
                }),
              );
            }}
            icon={<CommentOutlined />}
            tooltip="Wyślij wiadomość"
          />
        ) : (
          <></>
        )}
      </FloatButton.Group>
      <Drawer size="large" title="Notatki" onClose={() => setOpen(false)} open={open}>
        <Timeline mode="left">{orderBy(data, ["pinned"], ["desc"]).map(createTimelineItem)}</Timeline>
      </Drawer>
    </>
  );
};
