import { type NutritionDaySummary } from "@fitness-app/data-models/entities/ClientNutritionWeek";
import {
  type BodyMeasurements,
  type ClientPhoto,
  type HydrationMeasurement,
  type Measurement,
  type WeightMeasurement,
} from "@fitness-app/data-models/entities/Measurement";

import { type RequestStatus } from "../../../enums/requestStatus";

export const TRAINEE_MEASUREMENTS_REDUCER_NAME = "traineeMeasurements";

export interface TraineeMeasurementsReducer {
  measurements: (BodyMeasurements | WeightMeasurement)[];
  measurementsStatus: RequestStatus | null;
  measurementsPageSize: number;
  measurementsCurrentPage: number;
  measurementsTotalPages: number;

  photos: ClientPhoto[];
  photosStatus: RequestStatus | null;
  photosCurrentPage: number;
  photosTotalPages: number;

  hydrationMeasurement: HydrationMeasurement[];
  hydrationMeasurementStatus: RequestStatus | null;

  measurementsForDateRange: Measurement[];
  latestFetchedWeight: WeightMeasurement[] | null;

  selectedNutritionDay: null | NutritionDaySummary;
  addingNewMeasurement: boolean;
}
