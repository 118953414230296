import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  DietaryExclusions,
  DietaryPreference,
  MacroSplit,
  MealsPerDay,
  TargetGender,
} from "@fitness-app/data-models/entities/MealsPlan";

export const useMealPlanOptions = () => {
  const { t } = useTranslation(["nutrition", "common"]);

  const nutritionExclusions = useMemo(
    () => [
      {
        value: DietaryExclusions.Lactose,
        label: t(`dietaryExclusions.lactose`),
      },
      {
        value: DietaryExclusions.Casein,
        label: t(`dietaryExclusions.casein`),
      },
      {
        value: DietaryExclusions.Gluten,
        label: t(`dietaryExclusions.gluten`),
      },
      {
        value: DietaryExclusions.LowFoodmap,
        label: t(`dietaryExclusions.lowFoodmap`),
      },
    ],
    [],
  );

  const dietaryPreferences = useMemo(
    () => [
      {
        value: DietaryPreference.NoPreference,
        label: t(`dietaryPreference.noPreference`),
      },
      {
        value: DietaryPreference.SemiVegetarian,
        label: t(`dietaryPreference.semiVegetarian`),
      },
      {
        value: DietaryPreference.Vegetarian,
        label: t(`dietaryPreference.vegetarian`),
      },
      {
        value: DietaryPreference.Vegan,
        label: t(`dietaryPreference.vegan`),
      },
    ],
    [],
  );

  const targetGroups = useMemo(
    () => [
      {
        value: TargetGender.UNISEX,
        label: t(`targetGroup.${TargetGender.UNISEX}`),
      },
      {
        value: TargetGender.FEMALE,
        label: t(`targetGroup.${TargetGender.FEMALE}`),
      },
      {
        value: TargetGender.MALE,
        label: t(`targetGroup.${TargetGender.MALE}`),
      },
    ],
    [t],
  );

  const macroSplits = useMemo(
    () =>
      Object.values(MacroSplit).map((value) => ({
        value,
        label: t(`macroSplitDetailedShort.${value}`),
      })),
    [t],
  );

  const mealsPerDay = useMemo(
    () =>
      Object.values(MealsPerDay)
        .filter((value) => typeof value === "number")
        .map((numberOfMeals) => ({
          value: numberOfMeals,
          label: numberOfMeals,
        })),
    [],
  );

  return {
    dietaryPreferences,
    mealsPerDay,
    targetGroups,
    macroSplits,
    nutritionExclusions,
  };
};
