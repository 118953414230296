import React from "react";
import { CheckOutlined, CloseOutlined, ExportOutlined, MessageOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Card, Title } from "@tremor/react";
import { Badge, Button, message, Space, Table, Tag } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { chatActions } from "@fitness-app/app-store";
import { getTrainersTeam } from "@fitness-app/app-store/src/store/reducers/team/selectors";
import {
  type ChatMessageMetadata,
  type ChatMessageMetadataWithTrainee,
  type MessageSentiment,
} from "@fitness-app/data-models/entities/Chat";

import { useTeamMembers } from "~/modules/Team/hooks/useTeamMembers";
import { createTrainerOptions } from "~/modules/Trainee/helpers/createTrainerOptions";
import { supabase, useAppDispatch, useAppSelector } from "~/store/initializeStore";

export const useChatAlerts = () => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(["chatAlerts"], {
    keepPreviousData: true,
    queryFn: async () => {
      const query = supabase
        .from("chat_message_metadata")
        .select(
          "*, message:chat_message(authorId), trainee:traineeId(firstName, lastName, email, id, assignedTrainerId)",
          { count: "exact" },
        )
        .not("handled", "is", true)
        .in("sentiment", ["highly_negative", "negative"])
        .is("actionable", true)
        .in("context", [
          "workouts",
          "nutrition",
          "workouts_and_nutrition",
          "consultation",
          "churn",
          "subscription_renewal",
          "app_bugs",
        ])
        .order("sentiment")
        .order("createdAt", { ascending: false })
        .limit(150)
        .throwOnError()
        .returns<ChatMessageMetadataWithTrainee[]>();

      const { data, error, count } = await query;

      if (error) {
        throw new Error(error.message);
      }

      return {
        messages: data,
        count,
      };
    },
  });

  const updateMessage = async (id: string, data: Partial<ChatMessageMetadata>) => {
    const previousList: { messages: ChatMessageMetadataWithTrainee[] } | undefined = queryClient.getQueryData([
      "chatAlerts",
    ]);
    if (previousList) {
      queryClient.setQueryData(["chatAlerts"], {
        ...previousList,
        messages: previousList.messages.map((item) => (item.id === id ? { ...item, ...data } : item)),
      });
    }
    const { error } = await supabase.from("chat_message_metadata").update(data).eq("id", id);

    void queryClient.invalidateQueries(["chatAlerts"]);
    if (error) {
      void message.error("Nie udało się zaktualizować wiadomości");
    }
  };

  return {
    data,
    isLoading,
    updateMessage,
  };
};

export const ChatAlerts = ({ isHeadCoach }: { isHeadCoach?: boolean }): React.ReactElement => {
  const { data, isLoading, updateMessage } = useChatAlerts();
  const navigate = useNavigate();
  const { byId } = useTeamMembers();
  const dispatch = useAppDispatch();
  const fetchingExtraChannel = useAppSelector((store) => store.chat.channelsListStatus.fetchingExtraChannel);
  const trainers = useAppSelector(getTrainersTeam);

  const { t } = useTranslation(["trainees", "dashboard"]);

  return (
    <Card>
      <Title className="mb-6">
        Zastrzeżenia klientów do weryfikacji {data?.count && data.count > 0 ? `(${data.count})` : ""}
      </Title>
      <Table
        locale={{
          emptyText: "Lista wiadomości do weryfikacji jest pusta",
        }}
        pagination={false}
        dataSource={data?.messages || []}
        rowKey="id"
        size="middle"
        scroll={{ x: true }}
        loading={isLoading}
      >
        <Table.Column<ChatMessageMetadataWithTrainee>
          title={t("tableHeader.name")}
          dataIndex={["trainee", "firstName"]}
          key="name"
          render={(_, row) => (
            <Button type="link" className="px-0" onClick={() => navigate(`/trainee/details/${row.traineeId}`)}>
              {row.trainee?.firstName} {row.trainee?.lastName}
            </Button>
          )}
        />
        <Table.Column
          title="Data wiadomości"
          dataIndex="createdAt"
          key="createdAt"
          render={(createdAt) => <span>{dayjs(createdAt).format("DD.MM.YYYY HH:mm")}</span>}
        />

        <Table.Column title={t("tableHeader.summary")} dataIndex="summary" key="summary" />

        <Table.Column
          title={t("tableHeader.sentiment")}
          dataIndex="sentiment"
          key="sentiment"
          render={(sentiment: MessageSentiment) => {
            return (
              <Badge
                className="whitespace-pre"
                status={sentiment === "highly_negative" ? "error" : "warning"}
                text={t(`dashboard:sentiment.${sentiment}`)}
              />
            );
          }}
        />
        <Table.Column<ChatMessageMetadataWithTrainee>
          title={t("tableHeader.context")}
          dataIndex="context"
          key="context"
          filterMultiple
          filters={[
            {
              text: t("dashboard:context.general"),
              value: "general",
            },
            {
              text: t("dashboard:context.workouts"),
              value: "workouts",
            },
            {
              text: t("dashboard:context.nutrition"),
              value: "nutrition",
            },
            {
              text: t("dashboard:context.workouts_and_nutrition"),
              value: "workouts_and_nutrition",
            },
            {
              text: t("dashboard:context.consultation"),
              value: "consultation",
            },
            {
              text: t("dashboard:context.churn"),
              value: "churn",
            },
            {
              text: t("dashboard:context.subscription_renewal"),
              value: "subscription_renewal",
            },
            {
              text: t("dashboard:context.private_life"),
              value: "private_life",
            },
            {
              text: t("dashboard:context.app_bugs"),
              value: "app_bugs",
            },
            {
              text: t("dashboard:context.other"),
              value: "other",
            },
          ]}
          onFilter={(value, record) => record.context === value || (!record.context && value === "other")}
          render={(context) =>
            context ? (
              <Tag color={context === "churn" ? "red" : undefined}>{t(`dashboard:context.${context}`)}</Tag>
            ) : (
              "-"
            )
          }
        />
        <Table.Column<ChatMessageMetadataWithTrainee>
          title={t("tableHeader.trainer")}
          dataIndex={["trainee", "assignedTrainerId"]}
          filterMultiple
          filterSearch
          filters={createTrainerOptions(trainers, t, false)}
          onFilter={(value, record) => {
            return (
              record.trainee?.assignedTrainerId === value ||
              Boolean(
                record.trainee?.assignedTrainerId &&
                  Array.isArray(value) &&
                  value.includes(record.trainee.assignedTrainerId),
              )
            );
          }}
          key="assignedTrainerId"
          render={(trainerId) => {
            const trainer = byId[trainerId];

            if (!trainer) {
              return "-";
            }

            return (
              <Tag color="magenta">
                {trainer.firstName} {trainer.lastName}
              </Tag>
            );
          }}
        />
        <Table.Column<ChatMessageMetadataWithTrainee>
          title={t("tableHeader.status")}
          dataIndex={["handled"]}
          key="handled"
          render={(handled, row) => {
            if (typeof handled !== "boolean") {
              return "-";
            }

            return handled ? (
              <CheckOutlined style={{ color: "#25b10f" }} />
            ) : (
              <Space>
                <CloseOutlined style={{ color: "#ED1C24" }} />
                <Button type="dashed" size="small" onClick={() => updateMessage(row.id, { handled: true })}>
                  Oznacz jako obsłużone
                </Button>
              </Space>
            );
          }}
        />
        <Table.Column<ChatMessageMetadataWithTrainee>
          title={t("tableHeader.options")}
          dataIndex="action"
          key="action"
          render={(_, row) => (
            <Space size={4}>
              <Button type="link" className="px-0" onClick={() => navigate(`/trainee/details/${row.traineeId}`)}>
                {t(`common:details`)}
              </Button>
              <Button
                icon={<ExportOutlined />}
                title="Otwórz w nowy oknie"
                href={`${window.location.origin}/trainee/details/${row.traineeId}`}
                target="_blank"
                type="link"
              />

              {row.channelId && (
                <Button
                  icon={<MessageOutlined />}
                  style={{ paddingLeft: 2, paddingRight: 2 }}
                  title="Wyślij wiadomość"
                  loading={fetchingExtraChannel}
                  onClick={() =>
                    dispatch(
                      chatActions.findAndAddChannelToList({
                        userId: row.message.authorId,
                        channelId: String(row.channelId),
                        isHeadCoach,
                      }),
                    )
                  }
                  type="link"
                />
              )}
            </Space>
          )}
        />
      </Table>
    </Card>
  );
};
