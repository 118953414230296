import { createAsyncThunk } from "@reduxjs/toolkit";

import { UserRole, type UserRoleEnum } from "@fitness-app/data-models";
import {
  MessageType,
  type ChatIncomingRealtimeEvents,
  type ChatMember,
  type ChatMessage,
  type ContextMessage,
  type MessageVisibleBy,
  type StandardMessage,
} from "@fitness-app/data-models/entities/Chat";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { addMessageToChannel, removeMessageFromChannel } from "../reducer";
import { CHAT_REDUCER_NAME } from "../types";

type Payload = {
  channelId: string;
  messageId?: string;
  selectedAuthor?: ChatMember | null;
  content: ChatMessage["content"];
  context?: ChatMessage["context"];
  files?: ChatMessage["files"];
  visibleBy?: MessageVisibleBy[];
  onRejected?: (error: string) => void;
  onSuccess?: () => void;
  isMobile?: boolean;
};

export const sendNewMessage = createAsyncThunk<ChatMessage, Payload, AsyncThunkCreator<string>>(
  `${CHAT_REDUCER_NAME}/sendNewMessage`,
  async (
    payload,
    { rejectWithValue, dispatch, getState, extra: { db, auth, parties, analytics, errorTrackingService } },
  ) => {
    const currentUser = await getLoggedUser(auth);

    const message = {
      type: payload.context ? MessageType.WithContext : MessageType.Standard,
      content: payload.content,
      files: payload.files || [],
      context: payload.context || null,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      channelId: payload.channelId,
      authorRole: payload.selectedAuthor?.role || (currentUser.customClaims.role as UserRoleEnum) || UserRole.TRAINER,
      authorId: payload.selectedAuthor?.uid || currentUser.id,
      automated: false,
      sendBy: currentUser.id,
      visibleBy: payload.visibleBy || ["all"],
      seenBy: [currentUser.id],
      deleted: false,
      id: payload.messageId || generateUniqId(),
      extra: {
        version: 2,
      },
    } as StandardMessage | ContextMessage;

    dispatch(
      addMessageToChannel({
        channelId: payload.channelId,
        message: {
          ...message,
          status: "sending",
        },
      }),
    );

    const socket = parties.chatRooms[payload.channelId];
    const socketNative = parties.chatRoomsNative[payload.channelId];

    const { createdAt, updatedAt, ...rest } = message;

    const { error, data } = await db.from("chat_message").insert(rest).select("*").single<ChatMessage>();

    if (error) {
      if (payload.isMobile) {
        errorTrackingService?.log(`Cannot send message in action: ${error.message}`);
        payload.onRejected?.(error.message);
        // @ts-expect-error ignore
        error.messageId = message.id;
        return rejectWithValue(error.message);
      }

      const retries = getState().chat.sendRetriesCount;
      errorTrackingService?.recordError(error, "sendNewMessage");
      if (retries > 0) {
        dispatch(removeMessageFromChannel({ channelId: payload.channelId, messageId: message.id }));
        void dispatch(sendNewMessage({ ...payload }));
        return rejectWithValue(error.message);
      }
      payload.onRejected?.(error.message);
      dispatch(removeMessageFromChannel({ channelId: payload.channelId, messageId: message.id }));
      return rejectWithValue(error.message);
    }

    payload.onSuccess?.();

    analytics.track("send_new_message", {
      channelId: payload.channelId,
      message: message.content?.length ?? 0,
      files: message.files?.length ?? 0,
    });

    if (socket) {
      const socketMessage: ChatIncomingRealtimeEvents = {
        type: "message",
        operation: "add",
        senderId: currentUser.id,
        data: message,
      };
      socket.send(JSON.stringify(socketMessage));
    }

    if (socketNative) {
      const socketMessage: ChatIncomingRealtimeEvents = {
        type: "message",
        operation: "add",
        senderId: currentUser.id,
        data: message,
      };
      socketNative.send(JSON.stringify(socketMessage));
    }

    return data;
  },
);
