import { type Database } from "@fitness-app/supabase";

export type CheckoutVariantEntity = Database["public"]["Tables"]["checkout_variant"]["Row"];

export type VariantPrice = string;

export enum CheckoutVariantStatus {
  Active = "active",
  Inactive = "inactive",
  Archived = "archived",
}

export enum CheckoutVariantTarget {
  All = "all",
  New = "new",
  Renewal = "renewal",
}

interface PricingFeature {
  name: string;
  value?: string;
  originalPrice?: string;
  isFree?: boolean;
  hidden?: boolean;
}

interface PricingPlan {
  title: string;
  subtitle?: string;
  regularPrice: string;
  promoPrice: string;
  monthlyInfo: string;
  oneTimeInfo?: string;
  features: PricingFeature[];
  bonuses: PricingFeature[];
}

export interface PageConfiguration {
  pricing: PricingPlan[];
}

export interface CheckoutVariant
  extends Omit<CheckoutVariantEntity, "metadata" | "prices" | "pricesStats" | "pageConfiguration"> {
  prices: VariantPrice[];
  pricesStats: Record<string, number>;
  metadata: Record<string, string | number | null | boolean | string[] | number[]> | null;
  status: CheckoutVariantStatus;
  target: CheckoutVariantTarget;
  pageConfiguration: PageConfiguration | null;
}
