import { HIDDEN_CHECKOUT_PATH, RENEWAL_CHECKOUT_PATH } from "../checkout/checkout";

type GetProductLinkParams = {
  productId: string;
  slug?: string;
  priceId?: string;
  ref?: string;
  customDomain?: string;
  domain: string;
  checkoutId?: string | string[] | null;
  renewal?: boolean;
  email?: string;
  isVariant?: boolean;
  isRenewal?: boolean;
};

export const getProductLinkForMultiplePrices = ({
  productId,
  ref,
  slug,
  checkoutId,
  domain,
  renewal,
  email,
}: GetProductLinkParams) => {
  const url = new URL(`${domain}/c/${renewal ? RENEWAL_CHECKOUT_PATH : HIDDEN_CHECKOUT_PATH}/${slug || productId}`);

  if (checkoutId) {
    url.searchParams.append("variant", Array.isArray(checkoutId) ? checkoutId.join(",") : checkoutId);
  }
  if (ref) {
    url.searchParams.append("ref", ref);
  }

  if (email) {
    url.searchParams.append("email", email);
  }

  return url.toString();
};

export const getProductLink = ({
  productId,
  priceId = "",
  ref,
  customDomain,
  slug,
  checkoutId,
  domain,
  isVariant,
  email,
  isRenewal,
}: GetProductLinkParams) => {
  if (customDomain) {
    const joinSlashOrEmptyStr = customDomain.endsWith("/") || customDomain.includes("?") ? "" : "/";
    const refParam = customDomain.includes("?") ? `&ref=${ref}` : `?ref=${ref}`;

    return `${customDomain}${joinSlashOrEmptyStr}${ref ? refParam : ""}`;
  }

  if (domain && priceId === "free") {
    return `${domain}/embed/${productId}${ref ? `?ref=${ref}` : ""}`;
  }

  if (checkoutId) {
    const url = new URL(`${domain}/c/${HIDDEN_CHECKOUT_PATH}/${slug || productId}`);

    if (email) {
      url.searchParams.append("email", email);
    }

    if (ref) {
      url.searchParams.append("ref", ref);
    }

    url.searchParams.append("variant", Array.isArray(checkoutId) ? checkoutId.join(",") : checkoutId);

    return url.toString();
  }

  const checkoutPathPrefix = isRenewal ? "przedluzenie" : isVariant ? "step" : "c";

  const url = new URL(`${domain}/${checkoutPathPrefix}/${slug || productId}`);

  if (email) {
    url.searchParams.append("email", email);
  }

  if (ref) {
    url.searchParams.append("ref", ref);
  }

  if (priceId) {
    url.searchParams.append("priceId", priceId);
  }

  return url.toString();
};
