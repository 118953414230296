import React from "react";
import { LinkOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space, Switch, Table, Tag, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import truncate from "lodash.truncate";
import { useTranslation } from "react-i18next";

import {
  CheckoutVariantStatus,
  CheckoutVariantTarget,
  type CheckoutVariant,
} from "@fitness-app/data-models/entities/CheckoutVariant";
import { type ProductPrice } from "@fitness-app/data-models/entities/Product";
import { getProductLink } from "@fitness-app/utils/src/products/getProductLink";

import ModalForm from "~/components/ModalForm/ModalForm";
import ProductCheckoutVariantForm from "~/modules/Products/Product/ProductDetails/components/ProductCheckoutVariantForm";
import { useCheckoutVariants } from "~/modules/Products/Product/ProductDetails/hooks/useCheckoutVariants";
import ProductPrices from "~/modules/Products/Product/ProductPrices/ProductPrices";
import { getPriceFormatter } from "~/modules/Products/utils/getPriceFormatter";

interface ProductCheckoutVariantsProps {
  prices: ProductPrice[];
  productId: string;
}

const priceFormatter = getPriceFormatter("PLN");

export const ProductCheckoutVariants = ({ productId, prices }: ProductCheckoutVariantsProps): React.ReactElement => {
  const {
    data,
    isLoading,
    updateVariant,
    showFormModal,
    setShowFormModal,
    onVariantSubmit,
    setEditedItem,
    showSavingIndicator,
    editedModel,
  } = useCheckoutVariants(productId);
  const { t } = useTranslation("products");

  return (
    <div>
      <div className="flex justify-end gap-3 py-4">
        <Button type="primary" onClick={() => setShowFormModal(true)}>
          Dodaj nowy wariant
        </Button>
      </div>

      <Table<CheckoutVariant>
        dataSource={data || []}
        loading={isLoading}
        rowKey="id"
        scroll={{ x: true }}
        pagination={{
          hideOnSinglePage: true,
        }}
        expandable={{
          expandedRowRender: (rowData) => {
            const includesIds = rowData.prices;
            return (
              <ProductPrices
                showPriceLabel
                prices={includesIds
                  .map((id) => prices.find((price) => price.id === id))
                  .filter((price): price is ProductPrice => !!price)}
                productId={rowData.id}
                productSlug={rowData.slug || ""}
                readonly
                allowCopyLink={false}
                onPriceOrderUpdate={(prices) => {
                  const updatePricesOrder: CheckoutVariant["prices"] = [];

                  prices.forEach((price) => {
                    const foundPrice = rowData.prices.find((p) => p === price.id);
                    if (foundPrice) {
                      updatePricesOrder.push(foundPrice);
                    }
                  });

                  void updateVariant(rowData.id, { prices: updatePricesOrder, target: rowData.target });
                }}
              />
            );
          },
          rowExpandable: (row) => row.prices?.length > 0,
        }}
      >
        <Table.Column<CheckoutVariant>
          title="ID"
          dataIndex="id"
          key="id"
          render={(id: string) => (
            <Typography.Paragraph
              copyable={{ tooltips: [id, t("common:copied")], text: id }}
              style={{ marginBottom: 0 }}
            >
              {truncate(id, { length: 10 })}
            </Typography.Paragraph>
          )}
        />
        <Table.Column<CheckoutVariant>
          title="Slug"
          key="slug"
          dataIndex="slug"
          render={(slug: string) => (
            <Typography.Paragraph
              copyable={{ tooltips: [slug, t("common:copied")], text: slug }}
              style={{ marginBottom: 0 }}
            >
              {truncate(slug, { length: 10 })}
            </Typography.Paragraph>
          )}
        />
        <Table.Column<CheckoutVariant>
          title="Ceny"
          key="prices"
          dataIndex="prices"
          render={(prices: CheckoutVariant["prices"]) => prices?.length}
        />
        <Table.Column title="Odsłony" key="views" dataIndex="views" />
        <Table.Column title="Ilość tranksacji" key="purchase" dataIndex="purchase" />
        <Table.Column
          title="Przychody"
          key="revenue"
          dataIndex="revenue"
          render={(revenue) => priceFormatter.format(revenue / 100)}
        />
        <Table.Column
          title="Typ koszyka"
          key="target"
          dataIndex="target"
          render={(target) => t(`checkoutVariants.target.${target}`)}
        />

        <Table.Column<CheckoutVariant>
          title={t("list.columns.link")}
          dataIndex="link"
          key="link"
          render={(_, row) => {
            const link = getProductLink({
              productId,
              domain: import.meta.env.VITE_ECOMMERCE_DOMAIN,
              slug: row.slug,
              isVariant: true,
              isRenewal: row.target === CheckoutVariantTarget.Renewal,
            });
            return (
              <Tooltip title={t("list.copyToClipboard")}>
                <Button
                  type="link"
                  icon={<LinkOutlined style={{ fontSize: 22 }} />}
                  onClick={() => navigator.clipboard.writeText(link)}
                />
              </Tooltip>
            );
          }}
        />

        <Table.Column<CheckoutVariant>
          title={t("list.columns.active")}
          dataIndex="status"
          key="status"
          render={(status: CheckoutVariant["status"], row) => {
            return (
              <Switch
                checked={status === CheckoutVariantStatus.Active}
                onChange={() => {
                  void updateVariant(row.id, {
                    status:
                      status === CheckoutVariantStatus.Active
                        ? CheckoutVariantStatus.Inactive
                        : CheckoutVariantStatus.Active,
                    target: row.target,
                  });
                }}
              />
            );
          }}
        />

        <Table.Column<CheckoutVariant>
          title="Wariant domyślny"
          dataIndex="default"
          key="default"
          render={(isDefault: CheckoutVariant["default"], row) => {
            return (
              <Switch
                checked={isDefault ?? false}
                onChange={() => {
                  void updateVariant(row.id, {
                    default: !isDefault,
                    target: row.target,
                  });
                }}
              />
            );
          }}
        />

        <Table.Column<CheckoutVariant>
          title="Dostępny do"
          dataIndex="activeUntil"
          key="activeUntil"
          render={(activeUntil: CheckoutVariant["activeUntil"]) => {
            if (!activeUntil) {
              return "Brak ograniczeń";
            }

            if (dayjs.unix(activeUntil).isBefore(dayjs())) {
              return <Tag color="red">{dayjs.unix(activeUntil).format("YYYY-MM-DD HH:mm")} Wygasł</Tag>;
            }

            return <Tag color="blue">{dayjs.unix(activeUntil).format("YYYY-MM-DD HH:mm")}</Tag>;
          }}
        />

        <Table.Column<CheckoutVariant>
          title={t("list.columns.options")}
          key="action"
          align="left"
          render={(_, row) => (
            <Space>
              <Button type="link" onClick={() => setEditedItem(row)}>
                {t("common:button.edit")}
              </Button>
              <Popconfirm
                overlayStyle={{ maxWidth: 400 }}
                placement="leftTop"
                title={t("checkoutVariants.archiveWarning")}
                onConfirm={() =>
                  void updateVariant(row.id, {
                    status: CheckoutVariantStatus.Archived,
                    target: row.target,
                  })
                }
              >
                <Button type="link" danger>
                  {t("common:button.archive")}
                </Button>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>

      <ModalForm
        title="Dodaj nowy wariant koszyka"
        open={showFormModal}
        onCancel={() => setShowFormModal(false)}
        loading={showSavingIndicator}
      >
        <ProductCheckoutVariantForm
          model={
            !editedModel
              ? undefined
              : {
                  target: editedModel.target,
                  slug: editedModel.slug,
                  isActive: editedModel.status === CheckoutVariantStatus.Active,
                  prices: editedModel.prices,
                  default: editedModel.default ?? false,
                  activeUntil: editedModel.activeUntil ? dayjs.unix(editedModel.activeUntil) : null,
                }
          }
          onSubmit={onVariantSubmit}
        />
      </ModalForm>
    </div>
  );
};
