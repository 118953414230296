import React from "react";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Badge, Card, Collapse, Tag, Typography, Upload } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { ClientNoteCategory, type ClientNoteWithAuthorData } from "@fitness-app/data-models/entities/ClientNote";

import { mapCategoryColor } from "~/modules/Trainee/TraineeProfile/TraineeFeatures/TraineeNotes/TraineeNotes";
import { supabase } from "~/store/initializeStore";

interface TraineeDashboardNotesProps {
  traineeId: string;
}

const useTraineeDashboardNotes = (traineeId: string) => {
  const query = useQuery({
    queryKey: ["traineeDashboardNote", traineeId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("client_note")
        .select("*, author:authorId(avatarUrl, firstName, lastName)")
        .order("createdAt", { ascending: false })
        .eq("traineeId", traineeId)
        .eq("showInDashboard", true)
        .returns<ClientNoteWithAuthorData[]>();

      if (error) {
        throw new Error(error.message);
      }

      return data || [];
    },
  });

  return query;
};

export const TraineeDashboardNotes = ({ traineeId }: TraineeDashboardNotesProps) => {
  const query = useTraineeDashboardNotes(traineeId);
  const { t } = useTranslation("trainees");

  if (!query.data?.length) {
    return null;
  }

  return (
    <Collapse size="large" className="bg-white">
      <Collapse.Panel
        header={
          <div className="flex items-center gap-2">
            <span className="font-medium">Ważne notatki</span>
            <Badge count={query.data?.length ?? 0} color="#faad14" />
          </div>
        }
        key="1"
      >
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
          {query.data.map((item) => (
            <Card
              type="inner"
              key={item.id}
              title={
                <div className="flex items-center justify-between py-1">
                  <div className="flex items-baseline gap-x-2">
                    <Typography.Text type="secondary" className="text-sm">
                      <ClockCircleOutlined style={{ marginRight: 8, marginBottom: 10 }} />
                      {item.createdAt ? dayjs(item.createdAt).format("DD.MM.YYYY HH:mm") : ""}
                    </Typography.Text>

                    <Tag color={mapCategoryColor[item.category]}>
                      {t(`notes.categories.${item.category || ClientNoteCategory.General}`)}
                    </Tag>
                  </div>
                </div>
              }
              className={item.pinned ? "border border-yellow-500" : ""}
            >
              <p className="whitespace-pre-wrap">{item.content}</p>

              {item.attachments?.length ? <Upload fileList={item.attachments} disabled /> : null}
            </Card>
          ))}
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};
