import React, { useEffect, useMemo, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Typography } from "antd";
import groupBy from "lodash.groupby";
import isEqual from "lodash.isequal";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

import { RequestStatus, teamActions, teamSelectors } from "@fitness-app/app-store";
import { TeamMemberRole, TeamMemberStatus } from "@fitness-app/data-models";
import { type TeamMemberWithUser } from "@fitness-app/data-models/entities/TeamMember";

import ModalForm from "~/components/ModalForm/ModalForm";
import { TeamMemberTable } from "~/modules/Team/pages/TeamMemberList/TeamMemberTable";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import TeamTrainerForm from "./TeamTrainerForm";
import { type TeamTrainerFormModel } from "./types";

const TeamMemberList = (): React.ReactElement => {
  const { t } = useTranslation(["team", "common"]);
  const dispatch = useAppDispatch();
  const trainers = useAppSelector(teamSelectors.getTrainersTeam) ?? [];
  const listStatus = useAppSelector((state) => state.team.listStatus);
  const [showLoader, toggleLoader] = useState(false);
  const [model, setModel] = useState<TeamTrainerFormModel | null>(null);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (listStatus === null) {
      dispatch(teamActions.subscribeToTeam());
    }
    void dispatch(teamActions.fetchTrainerConfig());
  }, [listStatus, dispatch]);

  const openForm = () => setIsOpen(true);
  const closeForm = () => {
    setIsOpen(false);
    setModel(null);
  };

  const addTrainer = async (formModel: TeamTrainerFormModel) => {
    toggleLoader(true);

    const { assignedTrainers, showOnlyNotAssigned, ...trainer } = formModel;

    if (model) {
      await dispatch(
        teamActions.updateTrainerInTeam({
          memberId: model.id,
          data: {
            role: trainer.role || model.role,
            specializationTags: trainer.specializationTags || [],
            status: trainer.status ? TeamMemberStatus.ACTIVE : TeamMemberStatus.INACTIVE,
          },
          assignedTrainers: isEqual(model.assignedTrainers, assignedTrainers) ? null : assignedTrainers,
        }),
      );
    } else {
      const trainerData = {
        ...trainer,
        status: trainer.status ? TeamMemberStatus.PENDING : TeamMemberStatus.INACTIVE,
        id: uuid(),
        assignedTrainers,
      };
      await dispatch(teamActions.addTrainerToTeam(trainerData));
    }
    toggleLoader(false);
    setModel(null);
    closeForm();
  };

  const openEditForm = (selected: TeamMemberWithUser) => {
    setModel({
      ...selected,
      specializationTags: selected.specializationTags || [],
      status: selected.status === TeamMemberStatus.ACTIVE,
      assignedTrainers: selected.assignedTrainers?.map((trainer) => trainer.trainer.id) || [],
      showOnlyNotAssigned: true,
      capabilities: selected.capabilities,
    });
    setIsOpen(true);
  };

  const groupByRole = useMemo(() => groupBy(trainers, "role"), [trainers]);

  const activeTrainers = useMemo(
    () =>
      trainers.filter(
        (trainer) => trainer.status === TeamMemberStatus.ACTIVE && trainer.role === TeamMemberRole.REGULAR_TRAINER,
      ).length,
    [trainers],
  );

  return (
    <Row>
      <Col xs={24}>
        <Card
          title={t("team")}
          loading={listStatus === RequestStatus.SUBSCRIBING}
          extra={
            <Button type="primary" icon={<PlusOutlined />} onClick={openForm}>
              {t("common.addTrainer")}
            </Button>
          }
        >
          <div className="w-full space-y-12">
            <div className="space-y-3">
              <Typography.Title level={4}>{t("role.account_owner")}</Typography.Title>
              <TeamMemberTable
                trainers={groupByRole[TeamMemberRole.ACCOUNT_OWNER] || []}
                role={TeamMemberRole.ACCOUNT_OWNER}
                openEditForm={openEditForm}
              />
            </div>

            <div className="space-y-3">
              <Typography.Title level={4}>{t("role.administration")}</Typography.Title>
              <TeamMemberTable
                trainers={
                  groupByRole[TeamMemberRole.SHARED_TRAINER]?.filter(
                    (trainer) => trainer.capabilities?.onlyForVerification,
                  ) || []
                }
                openEditForm={openEditForm}
                role="office"
              />
            </div>

            <div className="space-y-3">
              <Typography.Title level={4}>{t("role.head_coach")}</Typography.Title>
              <TeamMemberTable
                trainers={
                  groupByRole[TeamMemberRole.SHARED_TRAINER]?.filter(
                    (trainer) => !trainer.capabilities?.onlyForVerification,
                  ) || []
                }
                openEditForm={openEditForm}
                role={TeamMemberRole.SHARED_TRAINER}
              />
            </div>

            <div className="space-y-3">
              <Typography.Title level={4}>
                {t("role.trainers")} ({activeTrainers} {t("common.activated")})
              </Typography.Title>
              <TeamMemberTable
                trainers={groupByRole[TeamMemberRole.REGULAR_TRAINER] || []}
                openEditForm={openEditForm}
              />
            </div>
          </div>
        </Card>
      </Col>
      <ModalForm
        title={model ? t("editTrainer") : t("common.addTrainer")}
        open={isOpen}
        onCancel={closeForm}
        loading={showLoader}
      >
        <TeamTrainerForm onSubmit={addTrainer} model={model || undefined} />
      </ModalForm>
    </Row>
  );
};

export default TeamMemberList;
