import { type Stripe } from "stripe";

import { type Database } from "@fitness-app/supabase";

import { type DiscordAutomationTask } from "./Automation";
import { type ClientInvoiceData } from "./Order";
import {
  type Currency,
  type EndAccessStrategy,
  type InfiniteProductDuration,
  type LastsForProductDuration,
  type OrderPaymentType,
  type OrderPaymentTypeOption,
  type ProductTypeEnum,
  type UntilDateProductDuration,
} from "./Product";
import { type TraineeStatus } from "./Trainee";

export type ProductClientEntity = Database["public"]["Tables"]["product_client"]["Row"];

export interface Coupon {
  code: string;
}

export type SubscriptionStatus =
  | "active"
  | "canceled"
  | "incomplete"
  | "incomplete_expired"
  | "past_due"
  | "trialing"
  | "unpaid"
  | "imported"
  | "paused";

export type OneTimePaymentStatus = "active" | "past_due" | "imported" | "expired";

export type SubscriptionInterval = "day" | "month" | "week" | "year";

export interface Recurring {
  interval_count: number;
  trial_period_days?: number | null;
  interval: SubscriptionInterval;
  endAccessStrategy?: EndAccessStrategy;
}

export type AccessPeriodType = "recurring" | "one_time";

interface PauseCollection {
  behavior: "keep_as_draft" | "mark_uncollectible" | "void";
  resumes_at: number | null;
  original_access_date: number;
  reason?: "automated_product_access_reschedule" | "manual_pause";
}

export interface SubscriptionProductAccessPeriod {
  cycleNumber: number;
  startAt: number;
  currentPeriodStart: number;
  currentPeriodEnd: number;
  type: "recurring";
  productId: string;
  productName: string;
  subscriptionId: string;
  status: SubscriptionStatus;
  recurring: Recurring;
  cancelAtPeriodEnd: boolean;
  pauseCollection?: null | PauseCollection;
  endDate?: number | null;
  installmentEndsAt?: number | null;
  installments?: number | null;
  connectedStripeAccountId?: string;
  trial?: null | { startAt: number; endAt: number };
}

export interface OneTimeProductAccessPeriod {
  currentPeriodStart: number;
  currentPeriodEnd: number;
  type: "one_time";
  productId: string;
  productName: string;
  recurring: Recurring;
  endAccessStrategy?: EndAccessStrategy;
  status?: OneTimePaymentStatus;
  pauseCollection?: null | PauseCollection;
  connectedStripeAccountId?: string;
  trial?: never;
}

export interface NoneLimitedAccessPeriod {
  currentPeriodStart: number;
  currentPeriodEnd?: never;
  recurring?: never;
  endAccessStrategy: EndAccessStrategy;
  productId: string;
  productName: string;
  type: "none_limited";
  pauseCollection?: never;
  connectedStripeAccountId?: string;
  trial?: never;
}

export type PaidLastOrder = {
  amount: number;
  currency: Currency;
  priceId: string;
  orderId: string;
  free: false;
  paymentGatewayInvoiceId?: string | null;
  customInvoiceName?: string;
};

export type FreeLastOrder = {
  orderId: string;
  free: true;
  priceId: null;
  amount?: never;
  current?: never;
};

export type NFTLastOrder = {
  orderId: string;
  free: true;
  priceId: string;
  amount?: never;
  current?: never;
};

export type LastOrder = PaidLastOrder | FreeLastOrder | NFTLastOrder;

export interface ProductClientAuthor {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  avatarUrl: string | null;
  stripeAccountId?: string | null;
  creator?: string;
}

export interface RenewalAccessPeriodReminder {
  eventId: string;
  timestamp: number;
  notificationId?: string | null;
  emailSentTimestamp?: number;
}

export interface DefaultReminderForYear {
  1: null | RenewalAccessPeriodReminder;
  3: null | RenewalAccessPeriodReminder;
  7: null | RenewalAccessPeriodReminder;
  14: null | RenewalAccessPeriodReminder;
}

export interface DefaultReminderForMonths {
  1: null | RenewalAccessPeriodReminder;
  3: null | RenewalAccessPeriodReminder;
  7: null | RenewalAccessPeriodReminder;
  14?: never;
}

export type RenewalAccessPeriodReminders = DefaultReminderForYear | DefaultReminderForMonths;

export enum RenewalAccessPeriodStatus {
  InProgress = "inProgress",
  Finished = "finished",
}

export interface RenewalAccessPeriod {
  strategy: EndAccessStrategy;
  checkoutLink: string;
  reminders: RenewalAccessPeriodReminders;
  status: RenewalAccessPeriodStatus;
}

export enum ClientArchiveReason {
  AccessEnd = "access_end",
  Refund = "refund",
  Unsubscribe = "unsubscribe",
  NFTSell = "nft_sell",
}

export interface ArchivedInfo {
  archivedAt: number;
  by: string | "system";
  reason: ClientArchiveReason;
}

export type ProductClientDuration =
  | null
  | (UntilDateProductDuration & { startAt: number; endAt: number })
  | (InfiniteProductDuration & { startAt?: never; endAt?: never })
  | (LastsForProductDuration & { startAt: number; endAt: number });

export interface BaseProductClientDocument
  extends Omit<
    ProductClientEntity,
    | "archivedInfo"
    | "accessPeriod"
    | "accessPeriodRenewal"
    | "scheduledTasks"
    | "upsellToProduct"
    | "author"
    | "client"
    | "downgradedToFromNextPeriod"
    | "productDuration"
    | "coupon"
    | "paymentMethod"
    | "scheduledAccessPeriod"
  > {
  status: "active" | "archived";
  archivedInfo?: ArchivedInfo | null;
  productType: ProductTypeEnum;
  updatedAt: string;
  createdAt: string;
  firstName: string;
  lastName: string;
  email: string;
  coupon: Coupon | null;
  refCode: string | null;
  paymentType: OrderPaymentType;
  productId: string;
  author: ProductClientAuthor;
  paymentGatewayAuthorId: string | null;
  connectedOrders: string[];
  product: {
    name: string;
    description: string;
    images: string[];
  };
  client: ClientInvoiceData;
  imported: boolean | null;
  importId: string | null;
  invoiceRequested: boolean | null;
  scheduledTasks?: DiscordAutomationTask[] | null;
  discordId: string | null;
  previousEmails: string[];
  productDuration: ProductClientDuration;
  upsellToProduct?: null | {
    productId: string;
    productName: string;
    date: number;
    type: ProductTypeEnum;
  };
  downgradedToFromNextPeriod: null | { priceId: string; amount: number; currency: string };
  userId: string | null;
}

// export type ScheduledAccess =
//   | SubscriptionProductAccessPeriod
//   | OneTimeProductAccessPeriod
//   | Stripe.SubscriptionSchedule.Phase;

export type ScheduledAccess = Stripe.SubscriptionSchedule.Phase;

export interface SubscriptionProductClient extends BaseProductClientDocument {
  paymentType: typeof OrderPaymentTypeOption.Recurring;
  accessPeriod: SubscriptionProductAccessPeriod;
  scheduledAccessPeriod?: ScheduledAccess[] | null;
  paymentMethod: Stripe.PaymentMethod.Card | null;
  paymentGatewayCustomerId: string;
  invoiceRequested: boolean | null;
  accessPeriodRenewal?: RenewalAccessPeriod | null;
  lastOrder: string;
  downgradedToFromNextPeriod: null | { priceId: string; amount: number; currency: string };
}

export interface OneTimeProductClient extends BaseProductClientDocument {
  paymentType: typeof OrderPaymentTypeOption.OneTime;
  paymentGatewayCustomerId: string;
  scheduledAccessPeriod?: ScheduledAccess[] | null;
  accessPeriod?: OneTimeProductAccessPeriod | NoneLimitedAccessPeriod | null;
  invoiceRequested: boolean | null;
  accessPeriodRenewal?: RenewalAccessPeriod | null;
  lastOrder: string;
}

export interface FreeProductClient extends BaseProductClientDocument {
  paymentType: typeof OrderPaymentTypeOption.Free;
  coupon: null;
  accessPeriod: null;
  invoiceRequested: null;
  lastOrder: string;
}

export type ProductClient = SubscriptionProductClient | OneTimeProductClient | FreeProductClient;

export type ProductClientWithTraineeInfo = ProductClient & { trainee: { status: TraineeStatus | null } | null };
