import { createAsyncThunk } from "@reduxjs/toolkit";

import { UserRole } from "@fitness-app/data-models";
import { AppAccessType } from "@fitness-app/data-models/entities/Automation";
import { TraineeStatus, type TraineeWithAssignedTrainer } from "@fitness-app/data-models/entities/Trainee";

import { type AsyncThunkCreator } from "../../../index";
import { getUserClaims } from "../../user/selectors";
import { TRAINEES_REDUCER_NAME } from "../types";

type Payload = void;

export const fetchTraineesWithoutPlan = createAsyncThunk<
  {
    list: TraineeWithAssignedTrainer[];
  },
  Payload,
  AsyncThunkCreator<string>
>(
  `${TRAINEES_REDUCER_NAME}/fetchTraineesWithoutPlan`,
  async (_payload, { rejectWithValue, extra: { db }, getState }) => {
    const { role, memberId } = getUserClaims(getState());

    if (!memberId) {
      throw new Error("user-is-not-logged");
    }

    let query = db
      .from("trainee")
      .select(
        "id, userId, firstName, lastName, email, type, createdAt, status, hasActiveNutrition, hasActiveTrainingProgram, activeTrainingProgramId, activeMealsPlanId, activePlan:activeTrainingProgramId(name), activeMealsPlan:activeMealsPlanId(name)",
      )
      .order("createdAt", { ascending: false })
      .eq("status", TraineeStatus.ACTIVE)
      .eq("assignedTrainerId", memberId)
      .eq("type", AppAccessType.FullAccess)
      .or("hasActiveNutrition.is.false,hasActiveTrainingProgram.is.false");

    if (role === UserRole.TEAM_MEMBER && memberId) {
      query = query.eq("assignedTrainerId", memberId);
    }

    const { error, data } = await query.returns<TraineeWithAssignedTrainer[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return {
      list: data,
    };
  },
);
