import { type Database } from "@fitness-app/supabase";

import { type ProductAutomation } from "./Automation";
import { type InvoicesClient } from "./Product";

export enum IntegrationClient {
  Notion = "notion",
  Zapier = "zapier",
  Integromat = "integromat",
}

export enum ConversionTrackingClient {
  MetaConversionApi = "metaConversionApi",
  ActiveCampaign = "activeCampaign",
}

export enum EmailClient {
  ActiveCampaign = "ActiveCampaign",
  MailChimp = "MailChimp",
  ConvertKit = "ConvertKit",
  MailerLite = "MailerLite",
  GetResponse = "GetResponse",
  FreshMail = "FreshMail",
}

export interface ProductFee {
  value: number;
  type: "percent" | "amount";
}

export type EmbeddedFormVariant = "basic" | "card" | "richCard";

export enum EmbeddedFormColor {
  RED = "red",
  BLUE = "blue",
  GREEN = "green",
  YELLOW = "yellow",
  GRAY = "gray",
  INDIGO = "indigo",
  PURPLE = "purple",
  PINK = "pink",
}

export interface EmbeddedForm {
  variant?: EmbeddedFormVariant;
  buttonText?: string;
  emailPlaceholder?: string;
  headerText?: string;
  successMessage?: string;
  descriptionText?: string;
  color?: EmbeddedFormColor;
  claim?: string;
  privacyPolicyUrl?: string;
  showFirstNameInput?: boolean;
  showClaim?: boolean;
  showHeader?: boolean;
  showDescription?: boolean;
  showPrivacyPolicyInfo?: boolean;
  firstNameRequired?: boolean;
  customDomain?: string;
}

export interface BaseMailingList {
  id: string;
  name: string;
  url: string;
  type?: string;
}

export interface ActiveCampaignList {
  id: string;
  name: string;
}

export interface ActiveCampaignTag {
  tag: string;
  id: string;
}

export interface ConvertKitTag {
  tag: string;
  id: string;
}

export interface MailChimpTag {
  tag: string;
  id: string;
}

export interface GetResponseTag {
  tag: string;
  id: string;
}

// technically FreshMail doesn't have tags so we use custom field to achieve that
export interface FreshMailTag {
  tag: string;
  id: string;
}

export interface ConvertKitFormList {
  id: string;
  name: string;
  url: string;
  type: string;
}

export interface MailChimpList {
  id: string;
  name: string;
  web_id: number;
}

export interface GetResponseList {
  id: string;
  name: string;
}

export interface FreshMailList {
  // hash
  id: string;
  name: string;
  description: string;
  subscribersNumber: number;
}

// MailerLite have only one list
export interface DefaultMailerLiteList {
  id: "default";
  name: "Default";
}

export interface Property {
  href: null | string;
  text: { content: string; link: null | string };
  plain_text: string;
  type: "text";
}

export interface TitleProperty {
  id: "title";
  title: Property[];
  type: "title";
}

export interface NotionPage {
  object: "page";
  id: string;
  cover: null | string;
  icon: null | string;
  created_time: string;
  last_edited_time: string;
  url: string;
  archived: boolean;
  properties: {
    title: TitleProperty;
  };
  parent:
    | {
        database_id: string;
        type: "database_id";
      }
    | { type: "workspace"; workspace: true }
    | { type: "page_id"; page_id: string };
}

export interface NotionDatabasePage extends Omit<NotionPage, "properties"> {
  properties: {
    Name: {
      title: Property[];
    };
  };
}

export interface MainNotionPage extends Omit<NotionPage, "properties"> {
  parent: { type: "workspace"; workspace: true };
  pages?: NotionPage[];
  properties: {
    title: TitleProperty;
  };
}

export interface NotionDatabase {
  object: "database";
  id: string;
  cover: null | string;
  icon: null | string;
  created_time: string;
  last_edited_time: string;
  title: Property[];
  properties: {
    Name: {
      title: string;
    };
  };
  parent: { type: "workspace"; workspace: true };
  url: string;
  pages: NotionDatabasePage[];
}

export interface INotionIntegration {
  databases: NotionDatabase[];
  pages: MainNotionPage[];
  name: string;
  id: string;
  apiSecretPath: string;
  createdAt: string;
  updatedAt: string;
}

export interface ActiveCampaignIntegration {
  url: string;
  client: EmailClient.ActiveCampaign;
  apiKeySecretVersionName: string;
  createdAt: string;
  updatedAt?: string;
  lists: ActiveCampaignList[];
  tags: ActiveCampaignTag[];
}

export interface IConvertKitIntegration {
  url: string;
  client: EmailClient.ConvertKit;
  apiKeySecretVersionName: string;
  createdAt: string;
  updatedAt?: string;
  lists: ConvertKitFormList[];
  tags: ConvertKitTag[];
}

export interface IFreshMailIntegration {
  url: string;
  client: EmailClient.FreshMail;
  apiKeySecretVersionName: string;
  createdAt: string;
  updatedAt?: string;
  lists: FreshMailList[];
  tags: FreshMailTag[];
  firstNameKey?: string;
  lastNameKey?: string;
}

export interface IMailChimpIntegration {
  url: string;
  serverPrefix: string;
  client: EmailClient.MailChimp;
  apiKeySecretVersionName: string;
  createdAt: string;
  updatedAt?: string;
  lists: MailChimpList[];
  tags: MailChimpTag[];
}

// for MailerLite we just treat group as a tags
export interface MailerLiteGroup {
  tag: string;
  id: string | number;
  name: string;
}

export interface IMailerLiteIntegration {
  url: string;
  client: EmailClient.MailerLite;
  apiKeySecretVersionName: string;
  createdAt: string;
  updatedAt?: string;
  lists: [DefaultMailerLiteList];
  tags: MailerLiteGroup[];
}

export interface IGetResponseIntegration {
  url: string;
  client: EmailClient.GetResponse;
  apiKeySecretVersionName: string;
  createdAt: string;
  updatedAt?: string;
  lists: GetResponseList[];
  tags: GetResponseTag[];
}

export type IssuerWithVAT = {
  isVatIssuer: true;
  defaultVatRate: 23;
  isVatExemption?: boolean;
};

export type IssueWithoutVAT = {
  isVatIssuer: false;
  legalVatExemption: string;
};

export type InvoiceSettings = IssuerWithVAT | IssueWithoutVAT;

export interface FakturowniaIntegration {
  url: string;
  apiKeySecretVersionName: string;
  client: InvoicesClient.Fakturownia;
  createdAt: string;
  updatedAt: string;
  accountName: string;
  departmentId?: string | null;
  settings?: InvoiceSettings | null;
}

export type IFirmaKeyName = "faktura" | "abonent" | "rachunek" | "wydatek";

export interface IFirmaIntegration {
  url: string;
  accountApiKeyVersionName: string;
  apiKeySecretVersionName: string;
  client: InvoicesClient.iFirma;
  createdAt: string;
  updatedAt: string;
  accountName: string;
  departmentId?: string | null;
  settings?: InvoiceSettings | null;
}

export interface InFaktIntegration {
  url: string;
  apiKeySecretVersionName: string;
  client: InvoicesClient.inFakt;
  createdAt: string;
  updatedAt: string;
  accountName: string;
  settings?: InvoiceSettings | null;
}

export enum WFirmaIntegrationStatus {
  InProgress = "inProgress",
  Completed = "completed",
  Rejected = "rejected",
}

export interface WFirmaIntegration {
  url: string; // in that case url is clientId
  clientSecretVersionName: string;
  apiKeySecretVersionName: string;
  refreshTokenSecretVersionName: string;
  client: InvoicesClient.wFirma;
  createdAt: string;
  updatedAt: string;
  accountName: string;
  settings?: InvoiceSettings | null;
  status: WFirmaIntegrationStatus;
  companyId: string;
  errorMessage?: string | null;
}

export type AvailableLang = "pl";

export interface CustomConsent {
  label: string;
  url: string | null;
  required: boolean;
  name: string;
  applyToProducts?: string[] | true;
}

export enum CheckoutTheme {
  Indigo = "indigo",
  Black = "black",
  Pink = "pink",
  Rose = "rose",
  Sky = "sky",
  Emerald = "emerald",
  Blue = "blue",
  Teal = "teal",
  Violet = "violet",
  Purple = "purple",
  Fuchsia = "fuchsia",
  Cyan = "cyan",
  Gray = "gray",
  Yellow = "yellow",
  YellowLight = "yellow-light",
}

export interface CartSettings {
  termsUrl: string;
  privacyPolicyUrl: string;
  claim: string;
  googleAnalyticsId?: string;
  googleAnalyticsGtmId?: string | null;
  facebookPixelId?: string;
  customConsents?: CustomConsent[] | null;
  creator?: string;
  checkoutTheme?: CheckoutTheme;
  embeddedForm?: EmbeddedForm | null;
  termsContent?: string;
  privacyPolicyContent?: string;
  promoteNetPrices?: boolean;
  isProductTerms?: boolean;
  isProductPrivacyPolicy?: boolean;
  useTPayBlik?: boolean;
}

export interface CheckoutSettings {
  cart: Record<AvailableLang, CartSettings>;
}

export type ProductsSettingsEntity = Database["public"]["Tables"]["products_settings"]["Row"];

export interface TPayIntegration {
  name: string;
  posId: string;
  clientId: string;
  clientSecretPath: string;
  safetyKeyPath: string;
}

export interface TPayConfig {
  successUrl: string;
  cancelUrl: string;
  productAutomation: ProductAutomation | null;
}

export interface MetaConversionApiIntegration {
  id: string;
  name: string;
  pixelId: string;
  apiSecretPath: string;
  createdAt: string;
  updatedAt: string;
  trackInitCheckout: boolean;
  trackAddToCart: boolean;
  trackPurchase: boolean;
}

export interface ProductsSettings
  extends Omit<
    ProductsSettingsEntity,
    "checkoutSettings" | "mailers" | "invoices" | "extra" | "fees" | "tpay" | "conversionTracking"
  > {
  checkoutSettings: CheckoutSettings | null;
  stripeConnectedAccountId: string | null;
  isStripeConnected: boolean;
  stripeVerificationToken: string;
  contactEmail: string | null;
  tpay?: null | TPayIntegration;
  userId: string;
  fees?: Record<string, ProductFee>;
  mailers: {
    [EmailClient.ActiveCampaign]?: ActiveCampaignIntegration[];
    [EmailClient.ConvertKit]?: IConvertKitIntegration[];
    [EmailClient.MailChimp]?: IMailChimpIntegration[];
    [EmailClient.MailerLite]?: IMailerLiteIntegration[];
    [EmailClient.GetResponse]?: IGetResponseIntegration[];
    [EmailClient.FreshMail]?: IFreshMailIntegration[];
  } | null;
  conversionTracking?: {
    [ConversionTrackingClient.MetaConversionApi]?: MetaConversionApiIntegration[];
    [ConversionTrackingClient.ActiveCampaign]?: {
      connectionId: string;
      accountUrl: string;
      enabled: boolean;
    };
  };
  invoices: {
    [InvoicesClient.Fakturownia]?: FakturowniaIntegration[];
    [InvoicesClient.iFirma]?: IFirmaIntegration[];
    [InvoicesClient.wFirma]?: WFirmaIntegration[];
    [InvoicesClient.inFakt]?: InFaktIntegration[];
  } | null;
  extra?: {
    [IntegrationClient.Notion]?: INotionIntegration[];
  } | null;
}

export type EmailClientTag =
  | ActiveCampaignTag
  | ConvertKitTag
  | MailerLiteGroup
  | MailChimpTag
  | GetResponseTag
  | FreshMailTag;
export type EmailClientList =
  | ActiveCampaignList
  | ConvertKitFormList
  | DefaultMailerLiteList
  | MailChimpList
  | GetResponseList
  | FreshMailList;
export type EmailClientIntegration =
  | ActiveCampaignIntegration
  | IConvertKitIntegration
  | IMailerLiteIntegration
  | IMailChimpIntegration
  | IGetResponseIntegration
  | IFreshMailIntegration;

export type Mailers = ProductsSettings["mailers"];
export type Invoices = ProductsSettings["invoices"];
