type ACConversionTrackingProps = {
  integration: {
    connectionId: string;
    accountUrl: string;
    enabled: boolean;
  } | null;
};

export const ACConversionTracking = (_: ACConversionTrackingProps) => {
  return null;
};
