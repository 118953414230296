import { createAsyncThunk } from "@reduxjs/toolkit";

import { type ChatMessage, type UpdateMessageEvent } from "@fitness-app/data-models/entities/Chat";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { CHAT_REDUCER_NAME } from "../types";

type Payload = {
  content?: ChatMessage["content"];
  reactions?: ChatMessage["reactions"];
  id: string;
  authorId: string;
  channelId: string;
  deleted?: true;
};

export const updateChatMessage = createAsyncThunk<ChatMessage, Payload, AsyncThunkCreator<string>>(
  `${CHAT_REDUCER_NAME}/updateChatMessage`,
  async (payload, { rejectWithValue, getState, extra: { db, auth, parties, analytics, errorTrackingService } }) => {
    const { id } = await getLoggedUser(auth);

    const message: Partial<ChatMessage> & { id: string } = {
      id: payload.id,
      updatedAt: new Date().toISOString(),
      ...(payload.content && payload.authorId === id ? { content: payload.content } : {}),
      ...(payload.reactions ? { reactions: payload.reactions } : {}),
      ...(payload.deleted ? { deleted: payload.deleted } : {}),
    };

    const socket = parties.chatRooms[payload.channelId];
    const socketNative = parties.chatRoomsNative[payload.channelId];

    const { error, data } = await db
      .from("chat_message")
      .update(message)
      .eq("id", payload.id)
      .select("*")
      .returns<ChatMessage>();

    if (error) {
      errorTrackingService?.recordError(error, "updateChatMessage");
      return rejectWithValue(error.message);
    }

    analytics.track(payload.deleted ? "delete_chat_message" : "update_chat_message");

    if (socket) {
      const socketMessage: UpdateMessageEvent = {
        type: "message",
        operation: "update",
        senderId: id,
        data: { ...message, extra: { ...message.extra, version: 2 } },
      };
      socket.send(JSON.stringify(socketMessage));
    }

    if (socketNative) {
      const socketMessage: UpdateMessageEvent = {
        type: "message",
        operation: "update",
        senderId: id,
        data: { ...message, extra: { ...message.extra, version: 2 } },
      };
      socketNative.send(JSON.stringify(socketMessage));
    }

    if (payload.deleted) {
      const activeChannel = getState().chat.activeChannels.find((channel) => channel.id === payload.channelId);

      if (activeChannel?.lastMessageId === payload.id) {
        const newLastMessage = getState().chat.messages[payload.channelId]?.find(
          (item) => item.id !== payload.id && !item.deleted,
        );
        const { error } = await db
          .from("chat_channel")
          .update({ lastMessageId: newLastMessage?.id || null, updatedAt: new Date().toISOString() })
          .eq("id", payload.channelId);

        if (error) {
          return rejectWithValue(error.message);
        }
      }
    }

    return data;
  },
);
