import { createAsyncThunk } from "@reduxjs/toolkit";

import { type TeamMember } from "@fitness-app/data-models";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { TEAM_REDUCER_NAME } from "../types";
import { fetchTeamMembers } from "./fetchTeamMembers";
import { fetchTrainerConfig } from "./fetchTrainerConfig";

type Payload = {
  memberId: string;
  data: Partial<TeamMember>;
  assignedTrainers: string[] | null;
};

export const updateTrainerInTeam = createAsyncThunk<void, Payload, AsyncThunkCreator<string>>(
  `${TEAM_REDUCER_NAME}/updateTrainerInTeam`,
  async (payload, { rejectWithValue, dispatch, extra: { db, auth, analytics } }) => {
    const { id } = await getLoggedUser(auth);

    const { error } = await db
      .from("team_member")
      .update({ ...payload.data, updatedAt: new Date().toISOString() })
      .eq("id", payload.memberId);

    if (error) {
      return rejectWithValue(error.message);
    }

    if (payload.assignedTrainers) {
      if (payload.assignedTrainers.length) {
        await db.from("team_member_assigned").delete().in("assignedTrainerId", payload.assignedTrainers);
        await db.from("team_member_assigned").delete().eq("headCoachId", payload.memberId);

        const rows = payload.assignedTrainers.map((trainerId) => ({
          headCoachId: payload.memberId,
          assignedTrainerId: trainerId,
        }));

        await db.from("team_member_assigned").insert(rows);
      } else {
        await db.from("team_member_assigned").delete().eq("headCoachId", payload.memberId);
      }
    }

    void dispatch(fetchTrainerConfig());
    void dispatch(fetchTeamMembers());

    analytics.track("trainer-updated-team-member", { trainerId: id, memberId: payload.memberId });
  },
);
