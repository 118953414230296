import React, { useEffect, useRef } from "react";
import { useVisibilityChange } from "@uidotdev/usehooks";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { productActions, productAutomationActions, productClientsActions } from "@fitness-app/app-store";
import { ProductChargeType } from "@fitness-app/data-models/entities/Product";

import ProductAutomation from "~/modules/Products/Product/ProductAutomation/ProductAutomation";
import ProductClientsList from "~/modules/Products/Product/ProductClientsList/ProductClientsList";
import ProductDetails from "~/modules/Products/Product/ProductDetails/ProductDetails";
import ProductOrders from "~/modules/Products/Product/ProductOrders/ProductOrders";
import ProductResources from "~/modules/Products/Product/ProductResources/ProductResources";
import ProductSettings from "~/modules/Products/Product/ProductSettings/ProductSettings";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

const TABS_KEY = ["details", "clients", "transactions", "automation", "content", "resources", "settings"];

const Product = (): React.ReactElement | null => {
  const { t } = useTranslation(["products", "common"]);

  const { id, tab } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const productDetailsStatus = useAppSelector((state) => state.product.productDetailsStatus);

  const tabFromUrl = tab || location.pathname.split("/").slice(-1)[0] || "";

  const activeTab = TABS_KEY.includes(tabFromUrl) ? tabFromUrl : "details";

  const isFree = useAppSelector((state) => state.product.details?.chargeType === ProductChargeType.FREE);
  const dispatch = useAppDispatch();
  const documentVisible = useVisibilityChange();
  const initialLoad = useRef(true);

  useEffect(() => {
    if (id && documentVisible && productDetailsStatus === null && !initialLoad.current) {
      dispatch(productActions.subscribeToProduct(id));
      dispatch(productAutomationActions.subscribeToProductAutomation(id));
    }

    initialLoad.current = false;
  }, [documentVisible, productDetailsStatus, dispatch, id]);

  useEffect(() => {
    if (id) {
      dispatch(productActions.subscribeToProduct(id));
      dispatch(productAutomationActions.subscribeToProductAutomation(id));
    }

    return () => {
      dispatch(productActions.unsubscribeFromProductDetails());
      dispatch(productAutomationActions.unsubscribeFromProductAutomation());
      dispatch(productClientsActions.clearProductClients());
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (!TABS_KEY.includes(tabFromUrl)) {
      navigate(`/products/${id}/details`);
    }
  }, [tabFromUrl, id]);

  if (!id) {
    return null;
  }

  return (
    <div className="p-6">
      <Tabs
        type="card"
        className="tabs-reset"
        activeKey={activeTab}
        onChange={(tab) => navigate(`/products/${id}/${tab}`)}
      >
        <Tabs.TabPane key="details" tab={t("productDetails.title")}>
          <ProductDetails />
        </Tabs.TabPane>

        <Tabs.TabPane key="clients" tab={t("clientsList")}>
          <ProductClientsList productId={id} />
        </Tabs.TabPane>

        {!isFree && (
          <Tabs.TabPane key="transactions" tab={t("orders.title")}>
            <ProductOrders productId={id} />
          </Tabs.TabPane>
        )}

        <Tabs.TabPane key="automation" tab={t("productAutomation.title")}>
          <ProductAutomation productId={id} />
        </Tabs.TabPane>

        <Tabs.TabPane key="resources" tab={t("productResources.title")} destroyInactiveTabPane>
          <ProductResources productId={id} />
        </Tabs.TabPane>

        <Tabs.TabPane key="settings" tab={t("productAutomation.settings")} destroyInactiveTabPane>
          <ProductSettings productId={id} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default Product;
