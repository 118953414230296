import { type Database } from "@fitness-app/supabase";

import { type BusinessEventType } from "../businessEvents/domain/businessEventTypes";
import { type ProductEventType } from "../businessEvents/domain/product/ProductEvents";
import { type TraineeEventType } from "../businessEvents/domain/trainee/TraineeEvents";
import { type EmailClient, type EmbeddedForm } from "./ProductsSettings";

export type WebhookLogEntity = Database["public"]["Tables"]["webhook_log"]["Row"];

export enum ProductIntegrationClient {
  Discord = "discord",
  Slack = "slack",
}

export enum SubscriberIntegrationClient {
  Discord = "discord",
}
export interface ConstantMessage {
  type: "firstDayOfSignIn";
  message: string;
}

export interface EmailList {
  id: string;
  name: string;
  client: EmailClient;
  account: string;
}

export type SpecialParamValue =
  | "productId"
  | "price"
  | "priceId"
  | "clientEmail"
  | "clientFirstName"
  | "clientLastName"
  | "clientName"
  | "paymentType"
  | "productName"
  | "orderId";

export interface NumberFieldValue {
  type: "number";
  value: number | SpecialParamValue;
}

export interface StringFieldValue {
  type: "string";
  value: string | SpecialParamValue;
}

export enum AuthType {
  BasicAuth = "basicAuth",
  BearerToken = "bearerToken",
  ApiKey = "apiKey",
  None = "none",
}

export interface BasicAuth {
  username: string;
  password: string;
  type: AuthType.BasicAuth;
}

export interface BearerToken {
  token: string;
  type: AuthType.BearerToken;
}

export interface APIKey {
  key: string;
  value: string;
  addTo: "header" | "query";
  type: AuthType.ApiKey;
}

export type AuthTypeOption = BasicAuth | BearerToken | APIKey;

export interface BaseWebhook {
  triggers: BusinessEventType[];
  method: "get" | "post";
  url: string;
  description?: string;
  auth: null | AuthTypeOption;
  active: boolean;
  id: string;
  payload?: Record<string, any>;
}

export interface PostWebhook extends BaseWebhook {
  method: "post";
  payload: Record<string, StringFieldValue | NumberFieldValue>;
  payloadAsParams: boolean;
}

export interface GetWebhook extends BaseWebhook {
  method: "get";
  payload?: Record<string, SpecialParamValue | string>;
}

export type Webhook = PostWebhook | GetWebhook;

export enum TaskType {
  EmailClient = "emailClient",
  DiscordIntegration = "discordIntegration",
  FitnessApp = "fitnessApp",
  ProductTask = "productTask",
}

export enum EmailClientTask {
  AddTags = "add_tags",
  RemoveTags = "remove_tags",
  AddToLists = "add_to_lists",
  RemoveFromLists = "remove_from_lists",
  AddFieldValues = "add_field_values",
}

export enum DiscordIntegrationTask {
  AddToServer = "add_to_server",
  RemoveFromServer = "remove_from_server",
  AddRole = "add_role",
  RemoveRole = "remove_role",
}

export enum FitnessAppTask {
  AddClientToApp = "add_client_to_app",
  RemoveClientFromApp = "remove_client_from_app",
  AddProgramAutomationToClient = "add_automation_to_client",
}

export enum TaskCreator {
  System = "system",
  Owner = "owner",
}

export enum ProductTask {
  ImportIntoProduct = "import_into_product",
  RemoveFromProduct = "remove_from_product",
}

export enum PriceFilterType {
  Included = "included",
  Excluded = "excluded",
}

export interface PriceFilter {
  type: PriceFilterType;
  prices: string[];
}

export type AutomationFilter = PriceFilter;

export interface BaseTask {
  active: boolean;
  description?: string;
  id: string;
  protected?: boolean;
  creator?: TaskCreator;
  filters?: AutomationFilter[] | null;
}

export enum RefundType {
  None = "none",
  AllLastPayment = "allLastPayment",
  Manual = "manual",
  ProratedLastPayment = "proratedLastPayment",
}

export interface ImportIntoProductTask {
  type: ProductTask.ImportIntoProduct;
  products: { productId: string; priceId: string | null }[];
  sendEmailAboutAddToProduct?: boolean;
}

export interface RemoveFromProductTask {
  type: ProductTask.RemoveFromProduct;
  products: { productId: string }[];
  cancelActiveSubscription?: boolean;
  refundType?: RefundType;
}

export interface AddTagsTask {
  type: EmailClientTask.AddTags;
  tags: string[];
  emailClients: (TagsEmailClient | TagsEmailList)[];
}

export interface FieldValuesEmailList {
  id: string;
  name: string;
  client: EmailClient.MailChimp | EmailClient.GetResponse | EmailClient.FreshMail;
  account: string;
}

export interface TagsEmailList {
  id: string;
  name: string;
  client: EmailClient.MailChimp | EmailClient.GetResponse | EmailClient.FreshMail;
  account: string;
}

export interface TagsEmailClient {
  client: EmailClient.ActiveCampaign | EmailClient.ConvertKit | EmailClient.MailerLite;
  account: string;
}

export interface FieldValuesEmailClient {
  client: EmailClient.ActiveCampaign | EmailClient.ConvertKit | EmailClient.MailerLite;
  account: string;
}

export type TaskVariable = `{{${string}}}`;

export interface AddFieldValues {
  type: EmailClientTask.AddFieldValues;
  values: Record<string, string | number | TaskVariable>;
  emailLists: (FieldValuesEmailList | FieldValuesEmailClient)[];
}

export interface RemoveTagsTask {
  type: EmailClientTask.RemoveTags;
  tags: string[];
  emailClients: (TagsEmailClient | TagsEmailList)[];
}

export interface AddToListsTask {
  type: EmailClientTask.AddToLists;
  emailLists: EmailList[];
}

export interface RemoveFromListsTask {
  type: EmailClientTask.RemoveFromLists;
  emailLists: EmailList[];
}

export interface ProductAutomationTask extends BaseTask {
  triggers: BusinessEventTriggers[];
  type: TaskType.ProductTask;
  action: ImportIntoProductTask | RemoveFromProductTask;
}

export type BusinessEventTriggers =
  | ProductEventType.ProductPurchased
  | ProductEventType.ProductSubscriptionEnd
  | ProductEventType.ProductSubscriptionRenewed
  | ProductEventType.ProductAccessRenewed
  | ProductEventType.ProductAccessPeriodEnd
  | ProductEventType.ProductClientImported
  | ProductEventType.ProductAccessPeriodEndingIn14Days
  | ProductEventType.ProductAccessPeriodEndingIn7Days
  | ProductEventType.ProductAccessPeriodEndingIn3Days
  | ProductEventType.ProductAccessPeriodEndingIn1Day
  | ProductEventType.ProductClientArchived
  | ProductEventType.ProductClientRemoved
  | ProductEventType.ProductClientEmailChanged
  | TraineeEventType.TraineeActivatedAccount;

export interface EmailClientAutomationTask extends BaseTask {
  triggers: BusinessEventTriggers[];
  type: TaskType.EmailClient;
  action: AddTagsTask | RemoveTagsTask | AddToListsTask | RemoveFromListsTask | AddFieldValues;
  sendNotificationToSlackChannel?: false | null | string | true;
}

export interface AddToServer {
  type: DiscordIntegrationTask.AddToServer;
  serverId: string;
}

export interface RemoveFromServer {
  type: DiscordIntegrationTask.RemoveFromServer;
  serverId: string;
}

export interface AddRole {
  type: DiscordIntegrationTask.AddRole;
  serverId: string;
  roleId: string;
  roleName: string;
}

export interface RemoveRole {
  type: DiscordIntegrationTask.RemoveRole;
  serverId: string;
  roleId: string;
  roleName: string;
}

export type EmailTaskGroup = AddTagsTask | RemoveTagsTask | AddToListsTask | RemoveFromListsTask | AddFieldValues;

export interface AddClientToFitnessApp {
  type: FitnessAppTask.AddClientToApp;
  programAutomation: null | {
    name: string;
    id: string;
    startAutomationFrom: AutomationStartPoint;
  };
  accessType: AppAccessType;
  tags: string[];
  sendReminderAboutAccess?:
    | false
    | {
        afterDays: number;
        actions: EmailTaskGroup[];
      }[];
}

export interface RemoveClientFromFitnessApp {
  type: FitnessAppTask.RemoveClientFromApp;
  accessType?: AppAccessType;
  programAutomation?: {
    name: string;
    id: string;
    startAutomationFrom: AutomationStartPoint;
  };
}

export interface AddProgramAutomationToClient {
  type: FitnessAppTask.AddProgramAutomationToClient;
  programAutomation: {
    name: string;
    id: string;
    startAutomationFrom: AutomationStartPoint;
  };
}

export interface DiscordAutomationTask extends BaseTask {
  triggers: BusinessEventTriggers[];
  type: TaskType.DiscordIntegration;
  action: AddToServer | RemoveFromServer | AddRole | RemoveRole;
}

export enum AppAccessType {
  FullAccess = "fullAccess",
  Limited = "limited",
}

export enum AutomationStartPoint {
  AfterPurchase = "afterPurchase",
  StartOfWeek = "startOfWeek",
}

export interface FitnessAppAutomationTask extends BaseTask {
  triggers: BusinessEventTriggers[];
  type: TaskType.FitnessApp;
  action: AddClientToFitnessApp | RemoveClientFromFitnessApp | AddProgramAutomationToClient;
}

export type AutomationTask =
  | EmailClientAutomationTask
  | DiscordAutomationTask
  | FitnessAppAutomationTask
  | ProductAutomationTask;

export type AutomationTaskWithoutTriggers =
  | Omit<EmailClientAutomationTask, "triggers">
  | Omit<DiscordAutomationTask, "triggers">
  | Omit<FitnessAppAutomationTask, "triggers">;

export type ProductTaxSettings =
  | {
      vatRate: 0 | 4 | 5 | 7 | 8 | 23;
      legalVatExemption?: never;
    }
  | {
      vatRate: "np" | "zw";
      legalVatExemption: string;
    };

export type GTUCode = `GTU_${number}`;

export interface ProductInvoiceConfig {
  flatTaxRate: number | null;
  itemName: string | null;
  taxSettings?: ProductTaxSettings | null;
  pkwiu?: string | null;
  gtu?: GTUCode | null;
}

export interface ProductCartSettings {
  features?: string[];
  customButtonLabel?: string;
  preSaleUrl?: string;
  hidePriceWhenPreSaleLinkExists?: boolean;
  useTPayBlik?: boolean;
}

export interface AutomationSettings {
  successUrl: string | null;
  showPromotionCodesField?: boolean;
  showPromotionCodesFieldOnlyForSubscription?: boolean;
  invoiceAsOption?: boolean;
  hideCountryField?: boolean;
  issueReceiptWhenInvoiceIsOptIn?: boolean;
  disableEmailNotificationAboutPurchase?: boolean;
  disableEmailNotificationAboutPurchaseToOwner?: boolean;
  allowUpgradeSubscriptionPlan?: boolean;
  allowDowngradeSubscriptionPlan?: boolean;
  embeddedForm?: EmbeddedForm & { pl?: EmbeddedForm };
  invoiceConfig?: ProductInvoiceConfig;
  cart?: ProductCartSettings;
  facebookPixelId?: string | null;
  googleAnalyticsId?: string | null;
  termsUrl?: string | null;
  privacyPolicyUrl?: string | null;
  claim?: string | null;
  showAdditionalInfoOptionalField?: boolean;
  showAdditionalInfoRequiredField?: boolean;
  showPhoneField?: boolean | null;
  showPhoneOptionalField?: boolean;
  showPhoneRequiredField?: boolean;
  metaConversionIntegrationId?: string | null;
  googleAnalyticsGtmId?: string | null;
}

export interface DiscordUser {
  id: string;
  username: string;
  avatar?: string;
  bot?: boolean;
  system?: boolean;
  locale?: string;
}

export interface Tags {
  bot_id: string;
}

export interface Role {
  id: string;
  name: string;
  permissions: string;
  position: number;
  color: number;
  hoist: boolean;
  managed: boolean;
  mentionable: boolean;
  icon: string | null;
  unicode_emoji: string | null;
  tags: Tags;
}

export interface DiscordGuild {
  id: string;
  name: string;
  icon: string;
  description: null | string;
  splash?: null | string;
  discovery_splash?: null | string;
  features: string[];
  emojis: string[];
  stickers: string[];
  banner?: null | string;
  owner_id: string;
  application_id: null | string;
  region: string;
  afk_channel_id?: string | null;
  afk_timeout: number;
  system_channel_id: string;
  widget_enabled: boolean;
  widget_channel_id?: string | null;
  verification_level: number;
  roles: Role[];
  default_message_notifications: number;
  mfa_level: number;
  explicit_content_filter: number;
  max_members: number;
  max_video_channel_users: number;
  premium_tier: number;
  premium_subscription_count: number;
  system_channel_flags: number;
  preferred_locale: string;
  rules_channel_id: string | null;
  public_updates_channel_id: string | null;
  hub_type?: string | null;
  premium_progress_bar_enabled: boolean;
  nsfw: boolean;
  nsfw_level: number;
}

export type DiscordIntegration = {
  guild: DiscordGuild;
  guildId: string;
  ownerId: string;
  createdAt: Date;
  updatedAt: Date;
};

export interface AppAutomationDocument {
  createdAt: Date;
  updatedAt: Date;
  webhooks: Webhook[];
  tasks: AutomationTask[];
  settings?: AutomationSettings;
  messages: ConstantMessage[];
  hasActiveWelcomeMessages: boolean;
  productIntegrations?: {
    [ProductIntegrationClient.Discord]?: DiscordIntegration[];
  };
}

export interface AutomatedEmail {
  subject: string;
  content: {
    html: string;
    json: string;
  };
}

export interface EmailsAutomation {
  afterPurchase: AutomatedEmail;
}

export type ProductAutomationEntity = Database["public"]["Tables"]["product_automation"]["Row"];

export interface ProductAutomation
  extends Omit<ProductAutomationEntity, "emails" | "tasks" | "productIntegrations" | "settings" | "webhooks"> {
  owner: string;
  createdAt: string;
  updatedAt: string;
  productId: string;
  contactEmail: string | null;
  webhooks: Webhook[] | null;
  tasks: AutomationTask[] | null;
  settings: AutomationSettings;
  productIntegrations: {
    [ProductIntegrationClient.Discord]?: DiscordIntegration[];
  } | null;
  emails: EmailsAutomation | null;
}
