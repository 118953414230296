import { useState, type FunctionComponent } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Radio, Row, Space, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

import {
  ConversionTrackingClient,
  type MetaConversionApiIntegration as IMetaConversionApiIntegration,
} from "@fitness-app/data-models/entities/ProductsSettings";

import { conversionTrackingOptions } from "~/modules/Products/ProductsSettings/ConversionTracking/contants";
import MetaConversionApiIntegration from "~/modules/Products/ProductsSettings/ConversionTracking/integration/MetaConversionApiIntegration/MetaConversionApiIntegration";
import { useAppSelector } from "~/store/initializeStore";
import { ACConversionTracking } from "./integration/MetaConversionApiIntegration/ACConversionTracking";

interface OwnProps {}

type Props = OwnProps;

const isIngtegrated = (
  integration?:
    | IMetaConversionApiIntegration[]
    | { connectionId: string; accountUrl: string; enabled: boolean }
    | undefined,
) => {
  if (Array.isArray(integration)) {
    return integration.length > 0;
  }

  return integration && "enabled" in integration && integration.enabled;
};

const ConversionTracking: FunctionComponent<Props> = () => {
  const { t } = useTranslation("settings");
  const { data } = useAppSelector((state) => state.productsSettings);

  const [selectedIntegration, setIntegration] = useState<ConversionTrackingClient>(
    ConversionTrackingClient.MetaConversionApi,
  );

  const integrationElements: { [K in ConversionTrackingClient]?: JSX.Element } = {
    [ConversionTrackingClient.MetaConversionApi]: (
      <MetaConversionApiIntegration
        integration={data?.conversionTracking?.[ConversionTrackingClient.MetaConversionApi] || null}
      />
    ),
    [ConversionTrackingClient.ActiveCampaign]: (
      <ACConversionTracking integration={data?.conversionTracking?.[ConversionTrackingClient.ActiveCampaign] || null} />
    ),
  };

  const renderActiveIntegration = () => {
    return integrationElements[selectedIntegration];
  };

  return (
    <div className="flex flex-col items-center pt-5">
      <Space direction="vertical" size={24}>
        <Row gutter={24} justify="center">
          <Radio.Group size="large" value={selectedIntegration} onChange={(e) => setIntegration(e.target.value)}>
            {conversionTrackingOptions
              .filter((client) => !client.hidden)
              .map((client) => (
                <Tooltip title={client.disabled ? t<string>("common:availableSoon") : undefined} key={client.value}>
                  <Radio.Button value={client.value} disabled={client.disabled}>
                    <img
                      src={client.icon}
                      alt={`${client.value}-icon`}
                      className="mx-2 h-6 w-6 object-contain align-middle"
                    />
                    {t<string>(`common:integration.${client.value}`)}
                    {data?.conversionTracking &&
                    client.value in data.conversionTracking &&
                    isIngtegrated(data.conversionTracking[client.value]) ? (
                      <CheckCircleOutlined style={{ color: "#1bc5bd", marginLeft: 8 }} />
                    ) : null}
                  </Radio.Button>
                </Tooltip>
              ))}
          </Radio.Group>
        </Row>
      </Space>
      {renderActiveIntegration()}
    </div>
  );
};

export default ConversionTracking;
