import { type TeamMember } from "@fitness-app/data-models";
import {
  type AssignedTrainerWithId,
  type TeamMemberRoleEnum,
  type TeamMemberStatusEnum,
  type TeamMemberWithUser,
} from "@fitness-app/data-models/entities/TeamMember";
import { type AppVersionConfig, type TrainerConfig } from "@fitness-app/data-models/entities/TrainerConfig";

import { type RequestStatus } from "../../../enums/requestStatus";

export const TEAM_REDUCER_NAME = "team";

export interface TeamReducer {
  selectedMember: null | TeamMember;
  trainers: TeamMemberWithUser[];
  listStatus: RequestStatus | null;
  memberStatus: RequestStatus | null;
  trainerConfig: TrainerConfig | null;
  appVersionConfig: AppVersionConfig | null;
  redirectToUpdate: boolean;
  assignedTrainers: AssignedTrainerWithId[];
}

export interface AddTrainerDto {
  email: string;
  firstName: string;
  lastName: string;
  status: TeamMemberStatusEnum;
  role: TeamMemberRoleEnum;
  id: string;
  assignedTrainers: string[];
}
