import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { type ClientNutritionDay } from "@fitness-app/data-models/entities/ClientNutritionDay";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_NUTRITION_TRACKER_REDUCER_NAME } from "../types";

type Payload = {
  nutritionDay: ClientNutritionDay;
  onSuccess?: () => void;
};

export const upsertNutritionTrackedDay = createAsyncThunk<ClientNutritionDay, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_NUTRITION_TRACKER_REDUCER_NAME}/upsertNutritionTrackedDay`,
  async ({ nutritionDay, onSuccess }, { rejectWithValue, extra: { db, analytics, errorTrackingService } }) => {
    const lastMonthIndex = 11;
    const selectedDay = dayjs(nutritionDay.eventDate);

    let week = `${selectedDay.format("YYYY")}-${selectedDay.isoWeek()}`;

    if (selectedDay.get("month") === lastMonthIndex && selectedDay.isoWeek() === 1) {
      week = `${selectedDay.add(1, "year").format("YYYY")}-${selectedDay.isoWeek()}`;
    }

    const { data, error } = await db.rpc("upsert_nutrition_tracked_day", {
      p_nutrition_day: {
        ...nutritionDay,
        eventWeek: week,
      },
    });

    if (error) {
      errorTrackingService?.log(`Error when upserting nutrition day: ${error.message}`);
      return rejectWithValue(error.message);
    }

    analytics.track("saved_nutrition_tracked_day");

    onSuccess?.();

    return data as ClientNutritionDay;
  },
);
