import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";

import { type TaskDate } from "@fitness-app/app-store/src/store/reducers/chat/actions/sendMessageToMultipleUsers";
import { type UserRoleEnum } from "@fitness-app/data-models";
import { CampaignType } from "@fitness-app/data-models/entities/Campaign";
import { type TraineeStatus } from "@fitness-app/data-models/entities/Trainee";
import { getErrorMessage } from "@fitness-app/utils";

import { useEntityChange } from "~/hooks/useEntityChange";
import { generateDaysOfSent } from "~/modules/Automation/AutomatedMessagesForm/useGeneratedDaysForMessages";
import {
  type CampaignMessageComposeFormModel,
  type SelectedUsersListItem,
} from "~/modules/Campaigns/CampaignForm/types";
import { analyticsService, automationApi } from "~/store/initializeStore";

const prepareSuccessMessage = ({ count, clients, now }: { count: number; clients: number; now: boolean }) => {
  if (clients === 0) {
    return "Nie znaleziono klientów spełniających kryteria wysyłki wiadomości";
  }

  if (count !== clients && !now) {
    return `Zaplanowano wysłanie ${count} wiadomości do ${clients} klientów`;
  }

  if (count !== clients && now) {
    return `Wysłano ${count} wiadomości do ${clients} klientów`;
  }

  return `Wysłano wiadomość do ${clients} klientów`;
};

type Payload =
  | {
      clients: {
        id: string;
        userId: string;
        email: string;
        firstName: string;
        lastName: string | null;
        assignedTrainerId: string | null;
      }[];
      name: string;
      visualType: "sheet" | "modal";
      message: {
        title: string;
        description: string;
        ctaLink: string;
        ctaText: string;
        pushTitle?: string | null;
        pushDescription?: string | null;
        image?: string | null;
        videoId?: string | null;
        videoLink?: string | null;
      };
      contentType: CampaignMessageComposeFormModel["contentType"];
      authorId: string;
      authorRole: UserRoleEnum;
      authorName: string;
      authorAvatar: string | null;
      sendImmediately: boolean;
      dates?: TaskDate[];
    }
  | {
      tags: string[];
      name: string;
      condition: "hasTag" | "notHasTag" | false;
      filteredProducts: "all" | string[];
      visualType: "sheet" | "modal";
      message: {
        title: string;
        description: string;
        ctaLink: string;
        ctaText: string;
        pushTitle?: string | null;
        pushDescription?: string | null;
        image?: string | null;
        videoId?: string | null;
        videoLink?: string | null;
      };
      contentType: CampaignMessageComposeFormModel["contentType"];
      authorId: string;
      authorRole: UserRoleEnum;
      authorName: string;
      authorAvatar: string | null;
      sendImmediately: boolean;
      dates?: TaskDate[];
      statuses: TraineeStatus[];
    };

export const useCampaignCreate = (onClose: () => void) => {
  const [loading, onSuccess, onFailure, onStart] = useEntityChange(onClose);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    retry: 1,
    async mutationFn(data: Payload) {
      const response = await automationApi.post<{ count: number; clients: number }>("/campaigns/schedule", data);

      return response.data;
    },
    async onSuccess() {
      analyticsService.track("add_new_campaign", { type: CampaignType.PopUp });
      await queryClient.invalidateQueries(["campaigns"]);
    },
  });

  const addNewCampaign = async (
    model: CampaignMessageComposeFormModel,
    {
      filteredProducts,
      selectedUsers,
      tagCondition,
      selectedTags,
      statuses,
    }: {
      filteredProducts: string[];
      tagCondition: "hasTag" | "notHasTag" | false;
      selectedUsers: SelectedUsersListItem[];
      selectedTags: string[];
      statuses: TraineeStatus[];
    },
  ) => {
    let dates:
      | undefined
      | {
          sendAt: number;
          sendDate: string;
          sentTime: {
            hours: number;
            minutes: number;
          };
        }[];
    try {
      onStart();
      if (model.daysOfDelivery && !model.sendImmediately) {
        const generatedDaysOfDelivery = generateDaysOfSent({
          sentDate: model.sentDate,
          daysOfDelivery: model.daysOfDelivery,
          numberOfRepeats: model.numberOfRepeats || 1,
        });
        dates = generatedDaysOfDelivery.map((day) => {
          const dayjsDate = dayjs(day);
          const sendDate = new Date(
            dayjsDate.get("year"),
            dayjsDate.get("month"),
            dayjsDate.get("date"),
            model.sentTime.get("hours"),
            model.sentTime.get("minutes"),
          );

          return {
            sendDate: sendDate.toISOString(),
            sentTime: {
              hours: model.sentTime.get("hour"),
              minutes: model.sentTime.get("minutes"),
            },
            sendAt: Math.floor(sendDate.getTime() / 1000),
          };
        });
      }
      if (selectedUsers.length) {
        const response = await mutation.mutateAsync({
          message: {
            ...model.message,
            pushTitle: model.message.pushTitle || null,
            pushDescription: model.message.pushDescription || null,
            image: model.message.image?.[0] || null,
          },
          name: model.name,
          contentType: model.contentType,
          sendImmediately: model.sendImmediately,
          clients: selectedUsers,
          authorId: model.authorId,
          authorRole: model.authorRole,
          authorName: model.authorName,
          authorAvatar: model.authorAvatar,
          dates,
          visualType: model.visualType || "sheet",
        });
        onSuccess(
          prepareSuccessMessage({ count: response.count, clients: response.clients, now: model.sendImmediately }),
          response.clients === 0 ? "warning" : "success",
        );
      } else {
        const response = await mutation.mutateAsync({
          message: {
            ...model.message,
            image: model.message.image?.[0] || null,
          },
          name: model.name,
          contentType: model.contentType,
          sendImmediately: model.sendImmediately,
          tags: selectedTags,
          authorId: model.authorId,
          authorRole: model.authorRole,
          authorName: model.authorName,
          authorAvatar: model.authorAvatar,
          condition: tagCondition,
          filteredProducts: filteredProducts.length ? filteredProducts : "all",
          dates,
          statuses,
          visualType: model.visualType || "sheet",
        });
        onSuccess(
          prepareSuccessMessage({ count: response.count, clients: response.clients, now: model.sendImmediately }),
          response.clients === 0 ? "warning" : "success",
        );
      }
    } catch (e) {
      onFailure(getErrorMessage(e));
    }
  };

  return {
    loading,
    addNewCampaign,
  };
};
