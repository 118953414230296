import { useState } from "react";
import omit from "lodash.omit";
import round from "lodash.round";
import uniqBy from "lodash.uniqby";

import { type DishIngredient } from "@fitness-app/data-models/entities/Dish";
import {
  type Ingredient,
  type IngredientOnShoppingList,
  type IngredientWithPortion,
} from "@fitness-app/data-models/entities/Ingredient";
import { type DishInMeal } from "@fitness-app/data-models/entities/MealsPlan";
import { calculateForPortion, countCaloriesByIngredients } from "@fitness-app/utils/src/nutrition/countNutrients";
import { findPreferableMeasure } from "@fitness-app/utils/src/nutrition/findPreferableMeasure";
import { getInitialMeasure } from "@fitness-app/utils/src/nutrition/getInitialMeasure";

import { useNutritionMeasures } from "~/modules/Nutrition/hooks/useNutritionMeasures";
import { type ReplaceIngredientFormModel } from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanShoppingList/components/ReplaceIngredient.types";
import { useMealsPlanContext } from "~/shared/providers/MealsPlanProvider";
import { analyticsService } from "~/store/initializeStore";

export const useReplaceIngredientForm = () => {
  const [selectedIngredient, setSelectedIngredient] = useState<IngredientOnShoppingList | null>(null);
  const [showForm, toggleForm] = useState(false);
  const { planDetails, updateMealsPlanDays } = useMealsPlanContext();
  const { measuresMap } = useNutritionMeasures();
  const selectIngredientToReplace = (ingredient: IngredientOnShoppingList) => {
    setSelectedIngredient(ingredient);
    toggleForm(true);
  };
  const [saving, setSaving] = useState(false);

  const handleCloseForm = () => {
    toggleForm(false);
    setTimeout(() => {
      setSelectedIngredient(null);
    }, 300);
  };

  const handleFormSubmit = async (model: ReplaceIngredientFormModel) => {
    const nutritionPlanDays = planDetails.weeks[0]?.days;

    if (!nutritionPlanDays || !selectedIngredient) {
      return;
    }

    let copyOfPlanDays = structuredClone(nutritionPlanDays);
    const dishesForUpdate: (DishInMeal | IngredientWithPortion)[] = [];
    setSaving(true);

    selectedIngredient.inMeals.forEach((meal) => {
      const mealDay = copyOfPlanDays.find((day) => day.id === meal.dayId);

      if (!mealDay) {
        return;
      }

      const mealToReplace = mealDay.meals.find((m) => m.id === meal.mealId);

      if (!mealToReplace) {
        return;
      }

      const filteredForUpdate = mealToReplace.dishes.filter((dish) => dish.id === meal.dishId);

      filteredForUpdate.forEach((dishToUpdate) => {
        if (!dishToUpdate || !model.selectedIngredientForReplace) {
          return;
        }

        dishToUpdate.name = model.dishes.find((d) => d.id === dishToUpdate.id)?.name || dishToUpdate.name;

        if (dishToUpdate.type === "ingredient") {
          const measure = model.selectedIngredientForReplace.measures[0];
          const portion = round((model.proportion / 100) * dishToUpdate.portion, 1);

          const preferableMeasure = findPreferableMeasure(model.selectedIngredientForReplace) || measure;

          const ingredientInDish: IngredientWithPortion = {
            ...model.selectedIngredientForReplace,
            ingredientId: model.selectedIngredientForReplace.id,
            id: dishToUpdate.id,
            portion,
            measureId: preferableMeasure?.id || -1,
            multiplier: 1,
          };

          const measureOptions = uniqBy(
            model.selectedIngredientForReplace.measures.map((measure) => ({
              label: measure.id === 1 ? measuresMap[measure.id]?.unit ?? "grams" : measuresMap[measure.id]?.name ?? "",
              value: measure.id,
              id: measure.id,
              name: measure.name,
              unit: measuresMap[measure.id]?.unit ?? "grams",
              multiplier: 1,
              energyPerUnit:
                measure.id === 1 && measure.weightPerUnit === 100
                  ? round(measure.energyPerUnit / 100, 1)
                  : measure.energyPerUnit,
              weightPerUnit:
                measure.id === 1 && measure.weightPerUnit === 100
                  ? round(measure.weightPerUnit / 100, 1)
                  : measure.weightPerUnit,
            })),
            "value",
          );

          const bestMeasure = getInitialMeasure(ingredientInDish, measureOptions);

          if (bestMeasure) {
            ingredientInDish.measureId = bestMeasure.measure.id;
            ingredientInDish.multiplier = bestMeasure.multiplier;
          }

          dishesForUpdate.push(ingredientInDish);

          return;
        }

        dishToUpdate.preparationSteps =
          model.dishes.find((d) => d.id === dishToUpdate.id)?.preparationSteps || dishToUpdate.preparationSteps;

        dishToUpdate.ingredients = dishToUpdate.ingredients.map((ingredient) => {
          if (ingredient.ingredientId === selectedIngredient.ingredientId && model.selectedIngredientForReplace) {
            const measure = model.selectedIngredientForReplace.measures[0];
            const portion = round((model.proportion / 100) * ingredient.portion, 1);

            const preferableMeasure = findPreferableMeasure(model.selectedIngredientForReplace) || measure;

            const ingredientInDish: DishIngredient & { multiplier: number } = {
              ...(omit(model.selectedIngredientForReplace, ["kcalmar", "_formatted"]) as Ingredient),
              ingredientId: model.selectedIngredientForReplace.id,
              calories: preferableMeasure?.energyPerUnit || 0,
              id: ingredient.id,
              portion,
              hint: null,
              measure: preferableMeasure?.name || "",
              measureId: preferableMeasure?.id || -1,
              measureWeight: preferableMeasure?.weightPerUnit || 0,
              measureCapacity: null,
              multiplier: 1,
              portionCalories: round((model.selectedIngredientForReplace.nutrients.calories * portion) / 100, 1),
            };

            const measureOptions = uniqBy(
              model.selectedIngredientForReplace.measures.map((measure) => ({
                label:
                  measure.id === 1 ? measuresMap[measure.id]?.unit ?? "grams" : measuresMap[measure.id]?.name ?? "",
                value: measure.id,
                id: measure.id,
                name: measure.name,
                unit: measuresMap[measure.id]?.unit ?? "grams",
                multiplier: 1,
                energyPerUnit:
                  measure.id === 1 && measure.weightPerUnit === 100
                    ? round(measure.energyPerUnit / 100, 1)
                    : measure.energyPerUnit,
                weightPerUnit:
                  measure.id === 1 && measure.weightPerUnit === 100
                    ? round(measure.weightPerUnit / 100, 1)
                    : measure.weightPerUnit,
              })),
              "value",
            );

            const bestMeasure = getInitialMeasure(ingredientInDish, measureOptions);

            if (bestMeasure) {
              ingredientInDish.measureId = bestMeasure.measure.id;
              ingredientInDish.measure = bestMeasure.measure.name;
              ingredientInDish.measureWeight = bestMeasure.measure.weightPerUnit;
              ingredientInDish.multiplier = bestMeasure.multiplier;
            }

            return ingredientInDish;
          }
          return ingredient;
        });

        dishesForUpdate.push({
          ...dishToUpdate,
          calories: round(
            countCaloriesByIngredients(dishToUpdate.ingredients) *
              ((dishToUpdate.portionCount ?? 1) / (dishToUpdate.portions ?? 1)),
            1,
          ),
          portionSize: calculateForPortion(
            dishToUpdate.ingredients.reduce((prev, current) => {
              return current.portion + prev;
            }, 0),
            { portions: dishToUpdate.portions ?? 1, portionCount: dishToUpdate.portionCount ?? 1 },
          ),
        });
      });
    });

    copyOfPlanDays = copyOfPlanDays.map((day) => {
      return {
        ...day,
        meals: day.meals.map((meal) => {
          return {
            ...meal,
            dishes: meal.dishes.map((dish) => {
              const updatedDish = dishesForUpdate.find((d) => d.id === dish.id);
              return updatedDish || dish;
            }),
          };
        }),
      };
    });

    await updateMealsPlanDays({ days: copyOfPlanDays });
    handleCloseForm();
    analyticsService.track("replace_ingredient_on_shopping_list_in_dishes");
    setSaving(false);
  };

  return {
    showForm,
    handleCloseForm,
    selectedIngredient,
    selectIngredientToReplace,
    handleFormSubmit,
    saving,
  };
};
