import React, { useMemo, useState } from "react";
import { CopyOutlined } from "@ant-design/icons";
import { Button, Input, message, Popover, Select, Switch, Typography } from "antd";
import keyBy from "lodash.keyby";
import { useTranslation } from "react-i18next";

import { type ProductPrice } from "@fitness-app/data-models/entities/Product";
import { getProductLinkForMultiplePrices } from "@fitness-app/utils/src/products/getProductLink";

import { useProductPricesOptions } from "~/modules/Products/hooks/useProductPricesOptions";

interface CreateCheckoutVariantLinkProps {
  prices: ProductPrice[];
  productId: string;
  productSlug?: string;
}

export const CreateCheckoutVariantLink = ({
  prices,
  productSlug,
  productId,
}: CreateCheckoutVariantLinkProps): React.ReactElement => {
  const { t } = useTranslation(["products", "common"]);
  const options = useProductPricesOptions();
  const [selectedPrices, setSelectedPrices] = useState<string[]>([]);
  const [renewal, setRenewal] = useState(false);
  const [clientEmail, setClientEmail] = useState<string>("");

  const priceById = useMemo(() => keyBy(prices, "id"), [prices]);

  const link = useMemo(() => {
    if (selectedPrices.length === 0 && !renewal) {
      return "";
    }

    return getProductLinkForMultiplePrices({
      productId,
      domain: import.meta.env.VITE_ECOMMERCE_DOMAIN,
      slug: productSlug,
      checkoutId: selectedPrices.length
        ? selectedPrices.map((priceId) =>
            priceById[priceId]?.hidden ? priceById[priceId].checkoutId || priceId : priceId,
          )
        : undefined,
      renewal,
      email: clientEmail,
    });
  }, [selectedPrices, renewal, clientEmail]);

  return (
    <Popover
      trigger="click"
      placement="leftTop"
      onVisibleChange={(visible) => {
        if (!visible) {
          setSelectedPrices([]);
          setRenewal(false);
          setClientEmail("");
        }
      }}
      overlayStyle={{
        width: 500,
      }}
      content={
        <div className="flex flex-col gap-3">
          <Select
            value={selectedPrices}
            onChange={setSelectedPrices}
            mode="multiple"
            placeholder="Wybierz ceny, które mają być dostępne w koszyku"
            options={options}
          />
          <Input
            value={link}
            disabled={!link && !renewal}
            onChange={(_value) => {}}
            placeholder="Wybierz ceny, aby otrzymać link do skopiowania"
            addonAfter={
              <CopyOutlined
                onClick={() => {
                  if (link) {
                    void navigator.clipboard.writeText(link);
                    void message.success(t("common:copied"));
                  }
                }}
              />
            }
          />

          <Input
            value={clientEmail}
            onChange={(value) => setClientEmail(value.target.value)}
            placeholder={"Opcjonalny email klienta"}
          />

          <div className="flex items-center gap-3">
            <Typography.Text strong>Czy link ma być z opcją odnowienia?</Typography.Text>
            <Switch checked={renewal} onChange={setRenewal} />
          </div>
        </div>
      }
      title="Utwórz link do koszyka z wybranymi wariantami"
    >
      <Button>Utwórz link do koszyka</Button>
    </Popover>
  );
};
