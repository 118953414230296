import React from "react";
import { Divider, Timeline } from "antd";
import dayjs from "dayjs";

import { type ProductClient, type ScheduledAccess } from "@fitness-app/data-models/entities/ProductClient";

interface ProductClientScheduledAccessProps {
  scheduledAccess: ScheduledAccess[];
  productClient: ProductClient;
}

export const ProductClientScheduledAccess = ({ scheduledAccess, productClient }: ProductClientScheduledAccessProps) => {
  if (productClient.accessPeriod?.type === "one_time") {
    const scheduledAccessCycle = scheduledAccess
      .map((access, index) => {
        if (access.metadata?.prepaid) {
          return {
            id: index,
            startAt: dayjs.unix(access.start_date),
            start: true,
            installment: 1,
            totalInstallments: Number(access.metadata.totalIterations ?? 1),
            paid: true,
            current: false,
            end: false,
            paidDate: access.metadata?.prepaidDate || null,
          };
        }
        if (
          access.metadata?.extensionType === "installments_after_one_time_without_payment" &&
          access.metadata.iterations
        ) {
          const iterations = Number(access.metadata.iterations);
          return Array.from({ length: iterations }).map((_, i) => ({
            id: index + i,
            startAt: dayjs.unix(access.start_date),
            start: i === 0,
            installment: i + 1,
            totalInstallments: iterations,
            paid: false,
            current: false,
            end: i === iterations - 1,
            paidDate: null,
          }));
        } else if (access.metadata?.iterations) {
          const restIterations = Number(access.metadata.iterations);
          return Array.from({ length: restIterations }).map((_, i) => ({
            id: index + i,
            startAt: dayjs.unix(access.start_date).add(i, "month"),
            start: false,
            installment: i + 1 + 1,
            totalInstallments: restIterations + 1,
            paid: false,
            current: false,
            end: i === restIterations - 1,
            paidDate: null,
          }));
        }
        return null;
      })
      .filter((item) => !!item)
      .flat();

    return (
      <div className="px-0 py-4">
        <Divider orientation="left">Zaplanowane płatności</Divider>

        <div className="py-6">
          <Timeline
            mode="left"
            items={[
              {
                label: "Koniec obecnego cyklu",
                children: <span>{dayjs.unix(productClient.accessPeriod.currentPeriodEnd).format("DD.MM.YYYY")}</span>,
                color: "green",
              },
              ...scheduledAccessCycle.map((cycle) => ({
                label: cycle.start ? "Początek kolejnego cyklu" : cycle.end ? "Koniec cyklu płatności" : undefined,
                children: (
                  <span>
                    Rata {cycle.installment} / {cycle.totalInstallments} - płatność {cycle.startAt.format("DD.MM.YYYY")}
                    {cycle.paid && cycle.paidDate
                      ? ` (opłacone ${dayjs(cycle.paidDate).format("DD.MM.YYYY HH:mm")})`
                      : ""}
                  </span>
                ),
                color: cycle.paid ? "green" : "#13c2c2",
              })),
            ]}
          />
        </div>
      </div>
    );
  }

  if (
    productClient.accessPeriod &&
    "installments" in productClient.accessPeriod &&
    productClient.accessPeriod.installments
  ) {
    const { installments, startAt, recurring, cycleNumber } = productClient.accessPeriod;

    const currentCycles = Array.from({ length: productClient.accessPeriod.installments ?? 1 }).map((_, index) => ({
      startAt: index === 0 ? dayjs.unix(startAt) : dayjs.unix(startAt).add(index, recurring.interval),
      id: index,
      installment: index + 1,
      totalInstallments: installments,
      paid: cycleNumber > index,
      current: true,
      start: index === 0,
      end: index === installments - 1,
    }));

    const scheduledAccessCycle = scheduledAccess
      .map((access, index) => {
        if (access.metadata?.prepaid) {
          return {
            id: index + installments,
            startAt: dayjs.unix(access.start_date),
            start: true,
            installment: 1,
            totalInstallments: Number(access.metadata.totalIterations ?? 1),
            paid: true,
            current: false,
            end: false,
            paidDate: access.metadata?.prepaidDate || null,
          };
        }
        if (
          access.metadata?.extensionType === "installments_after_installments_without_payment" &&
          access.metadata.iterations
        ) {
          const iterations = Number(access.metadata.iterations);
          return Array.from({ length: iterations }).map((_, i) => ({
            id: index + i,
            startAt: dayjs.unix(access.start_date).add(i, recurring.interval),
            start: i === 0,
            installment: i + 1,
            totalInstallments: iterations,
            paid: false,
            current: false,
            end: i === iterations - 1,
            paidDate: null,
          }));
        } else if (access.metadata?.iterations) {
          const restIterations = Number(access.metadata.iterations);
          return Array.from({ length: restIterations }).map((_, i) => ({
            id: index + i,
            startAt: dayjs.unix(access.start_date).add(i, recurring.interval),
            start: false,
            installment: i + 1 + 1,
            totalInstallments: restIterations + 1,
            paid: false,
            current: false,
            end: i === restIterations - 1,
            paidDate: null,
          }));
        }
        return null;
      })
      .filter((item) => !!item)
      .flat();

    return (
      <div className="px-0 py-4">
        <Divider orientation="left">Zaplanowane płatności</Divider>

        <div className="py-6">
          <Timeline
            mode="left"
            items={[
              ...currentCycles.map((cycle) => ({
                label: cycle.start ? "Początek obecnego cyklu" : cycle.end ? "Koniec obecnego cyklu" : undefined,
                children: (
                  <span>
                    Rata {cycle.installment} / {cycle.totalInstallments} - płatność {cycle.startAt.format("DD.MM.YYYY")}
                  </span>
                ),
                color: cycle.paid ? "green" : "#1677ff",
              })),
              ...scheduledAccessCycle.map((cycle) => ({
                label: cycle.start ? "Początek kolejnego cyklu" : cycle.end ? "Koniec cyklu" : undefined,
                children: (
                  <span>
                    Rata {cycle.installment} / {cycle.totalInstallments} - płatność {cycle.startAt.format("DD.MM.YYYY")}
                    {cycle.paid && cycle.paidDate
                      ? ` (opłacone ${dayjs(cycle.paidDate).format("DD.MM.YYYY HH:mm")})`
                      : ""}
                  </span>
                ),
                color: cycle.paid ? "green" : "#13c2c2",
              })),
            ]}
          />
        </div>
      </div>
    );
  }

  return null;
};
