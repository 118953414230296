import { type Database } from "@fitness-app/supabase";

import { type UserRoleEnum } from "..";

export type ChatChannelEntity = Database["public"]["Tables"]["chat_channel"]["Row"];
export type ChatMemberEntity = Database["public"]["Tables"]["chat_member"]["Row"];
export type ChatMessageEntity = Database["public"]["Tables"]["chat_message"]["Row"];
export type ChatMessageMetadataEntity = Database["public"]["Tables"]["chat_message_metadata"]["Row"];
export type UnreadMessagesEntity = Database["public"]["Tables"]["unread_messages"]["Row"];

export enum MessageType {
  Standard = "standard",
  WithContext = "withContext",
  System = "system",
}

export enum ChatType {
  TwoWay = "two_way",
  OneWay = "one_way",
  TwoWayMarketing = "two_way_marketing",
  OneWayMarketing = "one_way_marketing",
}

export enum ChatStatus {
  Inactive = "inactive",
  Active = "active",
  Archived = "archived",
}

export interface ChatMember {
  uid: string;
  clientId?: string | null;
  email: string;
  avatarUrl: string | null;
  role: UserRoleEnum;
  name: string;
  disabled?: true;
}

export type MessageReaction = {
  icon: string;
  authorId: string;
  iconCode: string | null;
  addedAt: number;
  iconName: string | null;
};

export type Reactions = Array<MessageReaction>;
export enum MessageInContext {
  NewExerciseComment = "NEW_EXERCISE_COMMENT",
  UpdateExerciseComment = "UPDATE_EXERCISE_COMMENT",
  AddDishComment = "ADD_DISH_COMMENT",
  UpdateDishComment = "UPDATE_DISH_COMMENT",
  AddRecordingFeedback = "ADD_EXERCISE_VIDEO_RECORDING_FEEDBACK",
}

export interface MessageContext {
  type: MessageInContext;
  metadata: Record<string, string | number | null | undefined>;
}

export interface FileErrorMessage {
  label: string;
  interpolation?: Record<string, string | number | undefined>;
}

export interface FileMessageContent {
  uid: string;
  originalName: string;
  shortName: string;
  size: number;
  contentType: string;
  duration?: number | null;
  extension: string;
  url: string;
  thumbUrl?: string | null;
  status?: never;
  errorMessage?: never;
  progress?: never;
  metering?: number[] | null;
}

type UUID = string;

export type MessageVisibleBy = "all" | UUID | UserRoleEnum;

export interface BaseMessage
  extends Omit<
    ChatMessageEntity,
    "context" | "reactions" | "files" | "visibleBy" | "authorRole" | "metadata" | "extra"
  > {
  authorRole: UserRoleEnum;
  reactions?: Reactions | null;
  context?: null | MessageContext;
  sendBy: string;
  files?: FileMessageContent[];
  visibleBy: MessageVisibleBy[];
  metadata?: {
    transcription?: string;
    context?: MessageContentContext | null;
    summary?: string | null;
    sentiment?: MessageSentiment | null;
  };
  extra?: null | {
    version: 1 | 2;
  };
}

export interface StandardMessage extends BaseMessage {
  type: MessageType.Standard;
}

export interface ContextMessage extends BaseMessage {
  type: MessageType.WithContext;
  context: MessageContext;
}

export interface SystemMessage extends BaseMessage {
  type: MessageType.System;
}

export type ChatMessage = StandardMessage | ContextMessage | SystemMessage;

export interface ChatChannel extends Omit<ChatChannelEntity, "members" | "metadata"> {
  assignedUsers: string[];
  members: Record<string, ChatMember>;
  status: ChatStatus;
  type: ChatType;
  metadata?: null | {
    disabledAudioRecording?: boolean;
  };
}

export type ChatChannelWithLastMessage = ChatChannel & {
  lastMessage: ChatMessage | null;
  profiles: {
    email: string;
    id: string;
    avatarUrl: string;
    firstName: string;
    lastName: string;
  }[];
};

export type ChatMessageWithMetadata = ChatMessage & {
  status: "sending" | "sent" | "failed";
};

export interface ChatChannelWithMeta extends ChatChannelWithLastMessage {
  minimized: boolean;
}

export interface UnreadMessage {
  id: string;
  createdAt: string | undefined;
}

export interface EmailNotificationAboutUnread {
  timestamp: string;
  includedMessageIds: string[];
}

export interface UnreadMessages
  extends Omit<UnreadMessagesEntity, "perChannel" | "lastEmailNotificationAboutUnread" | "unreadMessages"> {
  total: number;
  perChannel: Record<string, string[]>;
  unreadMessages: UnreadMessage[];
  lastEmailNotificationAboutUnread?: EmailNotificationAboutUnread | null;
}

export interface UnreadMessagesWithPendingEmailNotification extends UnreadMessages {
  messagesWithoutNotification: UnreadMessage[];
}

export interface ChannelWithMessages {
  groupedByDay: GroupedMessage[];
  channelId: string;
}

export interface GroupedMessage {
  date: string;
  messages: ChatMessage[];
}

export interface AddMessageEvent {
  type: "message";
  operation: "add";
  data: ChatMessage;
  senderId: string;
}

export interface UpdateMessageEvent {
  type: "message";
  operation: "update";
  data: Partial<ChatMessage> & { id: string };
  senderId: string;
}

export interface DeleteMessageEvent {
  type: "message";
  operation: "delete";
  data: Partial<ChatMessage> & { id: string };
  senderId: string;
}

export type ChatMessageEvent = UpdateMessageEvent | DeleteMessageEvent | AddMessageEvent;
export type IncomingHttpMessageUpdate = UpdateMessageEvent;
export type IncomingHttpMessageAdd = AddMessageEvent;

export interface InitialChatStateEvent {
  type: "initial";
  messages: ChatMessage[];
  room: RoomInfo;
}

export type RoomInfo = {
  id: string;
  users: {
    username: string;
    joinedAt: string;
    leftAt?: string;
    present: boolean;
    id: string;
  }[];
};

export type ChatIncomingRealtimeEvents = ChatMessageEvent;
export type ChatOutgoingRealtimeEvents = ChatMessageEvent | InitialChatStateEvent;
export type MessageSentiment = "neutral" | "positive" | "negative" | "highly_negative";
export type MessageContentContext =
  | "general"
  | "private_life"
  | "workouts"
  | "nutrition"
  | "consultation"
  | "churn"
  | "subscription_renewal"
  | "workouts_and_nutrition"
  | string;

export type ChatMessageMetadata = Omit<ChatMessageMetadataEntity, "metadata" | "context"> & {
  metadata: Record<string, string | null | number | boolean>;
  context: null | MessageContentContext;
};

export type ChatMessageMetadataWithTrainee = ChatMessageMetadata & {
  trainee: null | {
    email: string;
    firstName: string | null;
    lastName: string | null;
    assignedTrainerId: string | null;
  };
  message: {
    authorId: string;
  };
};
