import { createAsyncThunk } from "@reduxjs/toolkit";

import { type AssignedTrainerWithId } from "@fitness-app/data-models/entities/TeamMember";

import { type AsyncThunkCreator } from "../../../index";
import { TEAM_REDUCER_NAME } from "../types";

type Payload = string;

export const fetchAssignedTrainers = createAsyncThunk<AssignedTrainerWithId[], Payload, AsyncThunkCreator<string>>(
  `${TEAM_REDUCER_NAME}/fetchAssignedTrainers`,
  async (memberId, { rejectWithValue, extra: { db } }) => {
    const { error, data } = await db
      .from("team_member_assigned")
      .select(
        "createdAt, assignedTrainerId, trainer:assignedTrainerId(firstName, lastName, email, status, id, user:userId(avatarUrl, id))",
      )
      .order("createdAt", { ascending: true })
      .eq("headCoachId", memberId)
      .returns<AssignedTrainerWithId[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
