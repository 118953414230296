import { type Database } from "@fitness-app/supabase";

import { type TraineeStatus } from "./Trainee";

export type CampaignEntity = Database["public"]["Tables"]["campaign"]["Row"];

export enum CampaignType {
  PopUp = "pop-up",
}

export enum CampaignStatus {
  Scheduled = "scheduled",
  Running = "running",
  Finished = "finished",
  Cancelled = "cancelled",
  Archived = "archived",
}

export type BaseCampaign = Omit<CampaignEntity, "metadata"> & {
  metadata:
    | (Record<string, string | number | null | boolean | string[] | number[]> & {
        dates?: number[];
        condition?: "hasTag" | "notHasTag" | null | false;
        tags?: string[] | null;
        products?: string[] | "all" | null;
        statuses?: TraineeStatus[] | null;
      })
    | null;
  type: CampaignType;
  status: CampaignStatus;
};

export enum MediaContentType {
  None = "none",
  Image = "image",
  Youtube = "youtube",
  Video = "video",
}

export interface Campaign extends BaseCampaign {
  type: CampaignType.PopUp;
  data:
    | {
        contentType: MediaContentType.None;
        visualType?: "sheet" | "modal";
        message: {
          title: string;
          description: string;
          ctaLink: string;
          ctaText: string;
          pushTitle: string | null;
          pushDescription: string | null;
          image?: never;
          videoId?: never;
          videoLink?: never;
        };
      }
    | {
        contentType: MediaContentType.Image;
        visualType?: "sheet" | "modal";
        message: {
          title: string;
          description: string;
          ctaLink: string;
          ctaText: string;
          pushTitle: string | null;
          pushDescription: string | null;
          image: string;
        };
      }
    | {
        contentType: MediaContentType.Video;
        visualType?: "sheet" | "modal";
        message: {
          title: string;
          description: string;
          ctaLink: string;
          ctaText: string;
          pushTitle: string | null;
          pushDescription: string | null;
          videoId: string;
          hlsUrl: string | null;
          mp4Url: string | null;
          thumbnailUrl: string | null;
          guid: string;
          previewUrl: string | null;
        };
      }
    | {
        contentType: MediaContentType.Youtube;
        visualType?: "sheet" | "modal";
        message: {
          title: string;
          description: string;
          ctaLink: string;
          ctaText: string;
          pushTitle: string | null;
          pushDescription: string | null;
          videoLink: string;
        };
      };
}

export type CampaignWithStats = Campaign & {
  stats: { type: "open" | "click" }[];
};
