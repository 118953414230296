import { createAsyncThunk } from "@reduxjs/toolkit";

import { ChatStatus, ChatType, type ChatChannelWithLastMessage } from "@fitness-app/data-models/entities/Chat";

import { getLoggedUser } from "../../../helpers/getLoggedUser";
import { type AsyncThunkCreator } from "../../../index";
import { getParentTrainerId } from "../../user/selectors";
import { addChannelToList } from "../reducer";
import { CHAT_REDUCER_NAME } from "../types";

type Payload = {
  userId: string;
  channelId?: string;
  type?: ChatStatus;
  isHeadCoach?: boolean;
};

export const findAndAddChannelToList = createAsyncThunk<ChatChannelWithLastMessage, Payload, AsyncThunkCreator<string>>(
  `${CHAT_REDUCER_NAME}/findAndAddChannelToList`,
  async ({ userId, channelId, type, isHeadCoach }, { rejectWithValue, getState, dispatch, extra: { db, auth } }) => {
    const { channels } = getState().chat;
    const parentTrainerId = getParentTrainerId(getState());

    const foundedChannel = channels.find((channel) =>
      channelId
        ? channel.id === channelId && [ChatType.OneWay, ChatType.TwoWay].includes(channel.type)
        : channel.assignedUsers.includes(userId) && [ChatType.OneWay, ChatType.TwoWay].includes(channel.type),
    );

    if (foundedChannel) {
      dispatch(addChannelToList(foundedChannel));
      return foundedChannel;
    }

    const currentUser = await getLoggedUser(auth);

    const { error, data } = await db
      .from("chat_channel")
      .select(
        "*, lastMessage:lastMessageId(id, content, createdAt), profiles:user_profile(avatarUrl, firstName, lastName, id, email))",
      )
      .order("updatedAt", { ascending: false })
      .contains("assignedUsers", [userId, isHeadCoach ? parentTrainerId : currentUser.id])
      .eq("status", type || ChatStatus.Active)
      .in("type", [ChatType.OneWay, ChatType.TwoWay])
      .single<ChatChannelWithLastMessage>();

    if (error) {
      return rejectWithValue(error.message);
    }

    dispatch(addChannelToList(data));

    return data;
  },
);
