import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Skeleton, message as toast } from "antd";

import { type ChatChannel, type ChatMessage } from "@fitness-app/data-models/entities/Chat";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import { UploadFilesProvider } from "~/components/Upload/UploadProvider";
import ChatInput, { type ChatInputRef } from "~/modules/Chat/components/ChatInput";
import { useTraineeChats } from "~/modules/Chat/components/ChatNotificationContext/useTraineeChats";
import { getUploadConfig } from "~/modules/Chat/uploadConfig";
import { useAppSelector } from "~/store/initializeStore";

interface ChatNotificationMessageProps {
  visible: boolean;
  closeModal: () => void;
  initialMessage?: string;
  context?: ChatMessage["context"];
  onSuccess?: (messageId: string, context?: ChatMessage["context"]) => void | Promise<void>;
}

const ChatNotificationMessage = ({
  visible,
  closeModal,
  initialMessage,
  context,
  onSuccess,
}: ChatNotificationMessageProps) => {
  const [sending, toggleSending] = useState(false);
  const trainee = useAppSelector((state) => state.trainee.profile);
  const { data, isLoading } = useTraineeChats(trainee?.userId);
  const processingFileId = useAppSelector((store) => store.mediaLibrary.processingFileId);
  const [selectedChannel, setChannel] = useState<null | ChatChannel>(null);
  const inputRef = useRef<ChatInputRef>(null);
  const [shoudlDisableInput, setShouldDisableInput] = useState(true);

  useEffect(() => {
    if (data?.length) {
      setChannel(data[0] || null);
    }
  }, [data]);

  useEffect(() => {
    if (visible && initialMessage) {
      inputRef.current?.setInitialMessage(initialMessage);
    }
  }, [visible, initialMessage]);

  const handleFormSubmit = () => {
    if (!trainee || !trainee.userId) {
      return;
    }
    toggleSending(true);
    const messageId = generateUniqId();

    inputRef.current?.sendMessage(
      messageId,
      async () => {
        await onSuccess?.(messageId, context);
        toggleSending(false);
        void toast.info(`Wiadomość została wysłana`);

        closeModal();
      },
      context,
    );
  };

  const disabled = shoudlDisableInput || !!processingFileId;

  return (
    <Modal
      destroyOnClose
      open={visible}
      title="Wyślij wiadomość do klienta"
      onCancel={closeModal}
      footer={[
        <Button onClick={closeModal} key="close-button">
          Zamknij
        </Button>,
        <Button type="primary" disabled={disabled} onClick={handleFormSubmit} key="submit-button" loading={sending}>
          Wyślij
        </Button>,
      ]}
    >
      <div>
        {selectedChannel && !isLoading ? (
          <UploadFilesProvider key={selectedChannel.id} {...getUploadConfig(selectedChannel.id)}>
            <ChatInput
              setDisabled={setShouldDisableInput}
              ref={inputRef}
              hiddeSendButton
              rows={8}
              channelId={selectedChannel.id}
              channel={selectedChannel}
              initialValue={initialMessage}
            />
          </UploadFilesProvider>
        ) : (
          <Skeleton className="py-4" />
        )}
      </div>
    </Modal>
  );
};

export default ChatNotificationMessage;
