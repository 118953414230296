import React, { useCallback, useState, type FunctionComponent } from "react";
import { ClockCircleOutlined, CreditCardOutlined } from "@ant-design/icons";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  Alert,
  Badge,
  Button,
  DatePicker,
  Descriptions,
  Form,
  Grid,
  message,
  Modal,
  Popconfirm,
  Radio,
  Space,
  Switch,
  Tag,
  Typography,
} from "antd";
import dayjs, { type Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

import { productClientActions } from "@fitness-app/app-store";
import { ClientArchiveReason, type SubscriptionProductClient } from "@fitness-app/data-models/entities/ProductClient";

import { useEntityChange } from "~/hooks/useEntityChange";
import { SUBSCRIPTION_STATUS_MAPPER } from "~/modules/Products/constants/subscriptionStatusMapper";
import InfoAboutExtendedAccessPeriod from "~/modules/Products/Product/ProductClientsList/components/InfoAboutExtendedAccessPeriod/InfoAboutExtendedAccessPeriod";
import { PaymentTypeTag } from "~/modules/Products/Product/ProductClientsList/components/PaymentTypeTag";
import { useAppDispatch } from "~/store/initializeStore";

interface OwnProps {
  productClient: SubscriptionProductClient;
  updateInvoiceRequest: (value: boolean) => void;
  ownerView?: boolean;
}

type Props = OwnProps;

const DATE_FORMAT = "DD.MM.YYYY (HH:mm)";

interface FormModel {
  original_access_date: null | Dayjs;
  resumes_at: null | Dayjs;
}

const { useBreakpoint } = Grid;

const ProductClientSubscriptionSettings: FunctionComponent<Props> = ({
  productClient,
  updateInvoiceRequest,
  ownerView,
}) => {
  const { t } = useTranslation(["client", "products", "common"]);
  const elements = useElements();
  const stripe = useStripe();
  const { lg } = useBreakpoint();
  const [showPaymentMethodModal, togglePaymentMethodModal] = useState(false);
  const [loader, onSuccess, onFailure, onStart] = useEntityChange(() => {
    togglePaymentMethodModal(false);
  });
  const dispatch = useAppDispatch();
  const [showModalToUpdateAccessPeriod, toggleModalForAccessPeriod] = useState(false);
  const [formController] = Form.useForm<FormModel>();
  const [showModalLoader, toggleModalLoader] = useState(false);
  const accessEnd = productClient.accessPeriod ? dayjs.unix(productClient.accessPeriod?.currentPeriodEnd) : null;

  const defaultValue = productClient.accessPeriod
    ? dayjs
        .unix(productClient.accessPeriod?.pauseCollection?.resumes_at || productClient.accessPeriod?.currentPeriodEnd)
        .add(productClient.accessPeriod?.pauseCollection?.resumes_at ? 0 : 1, "day")
    : null;

  const getDisabledDate = useCallback(
    (current: dayjs.Dayjs) => (current && accessEnd ? current < accessEnd?.endOf("day") : true),
    [accessEnd],
  );

  const handleFormFinish = async (formData: FormModel) => {
    toggleModalLoader(true);
    try {
      await dispatch(
        productClientActions.setExtendAccessPeriod({
          productId: productClient.productId,
          clientEmail: productClient.email,
          resume_at: formData.resumes_at ? formData.resumes_at.unix() : null,
        }),
      ).unwrap();
      toggleModalForAccessPeriod(false);
      toggleModalLoader(false);
      void message.success(t("pauseCollection.waitToSuccess"));
    } catch (e) {
      void message.error(t("pauseCollection.cannotChangePeriod"));
      toggleModalLoader(false);
    }
  };

  const handleCancelSubscription = async () => {
    void message.loading(t("processingRequest"));
    await dispatch(
      productClientActions.cancelSubscriptionAtCurrentPeriodEnd({
        payload: {
          authorId: productClient.author.id,
          productId: productClient.productId,
          isOwner: ownerView,
          clientEmail: productClient.email,
        },
        onSuccess: () => {
          message.destroy();
          void message.success(t("subscription.canceledAtPeriodEnd"));
        },
        onFailure: () => void message.error(t("errors.contactSupport")),
      }),
    );
  };

  const updatePaymentMethod = async () => {
    const cardElement = elements?.getElement(CardElement);
    onStart();
    const res = await stripe?.createPaymentMethod({
      type: "card",
      card: cardElement!,
    });

    if (!res?.paymentMethod) {
      onFailure();
      return;
    }
    void dispatch(
      productClientActions.updatePaymentMethodForSubscription({
        payload: {
          paymentMethodId: res.paymentMethod?.id,
          productId: productClient.productId,
          authorId: productClient.author.id,
          paymentGatewayCustomerId: productClient.paymentGatewayCustomerId,
        },
        onFailure,
        onSuccess,
      }),
    );
  };

  const handleSubscriptionResume = async () => {
    void message.loading(t("processingRequest"));
    await dispatch(
      productClientActions.resumeSubscriptionAtCurrentPeriodEnd({
        payload: {
          authorId: productClient.author.id,
          productId: productClient.productId,
        },
        onSuccess: () => {
          message.destroy();
          void message.success(t("subscription.resumedAtPeriodEnd"));
        },
        onFailure: () => void message.error(t("errors.contactSupport")),
      }),
    );
  };

  const openPaymentMethodModal = () => {
    togglePaymentMethodModal(true);
    setTimeout(() => {
      const cardElement = elements?.getElement(CardElement);
      cardElement?.focus();
    }, 300);
  };

  const closePaymentMethodModal = () => {
    const cardElement = elements?.getElement(CardElement);
    cardElement?.clear();
    togglePaymentMethodModal(false);
  };

  const subscriptionEnd = dayjs
    .unix(
      productClient.archivedInfo?.archivedAt ||
        productClient.accessPeriod.pauseCollection?.resumes_at ||
        productClient.accessPeriod.currentPeriodEnd,
    )
    .format(DATE_FORMAT);

  return (
    <>
      <Descriptions bordered labelStyle={{ width: 250 }} layout={lg ? "horizontal" : "vertical"}>
        <Descriptions.Item label={t("list.columns.paymentType")} span={3}>
          <PaymentTypeTag productClient={productClient} />
        </Descriptions.Item>
        <Descriptions.Item label={t("list.columns.subscriptionType")} span={3}>
          <Space>
            {t(`products:recurring.${productClient.accessPeriod.recurring?.interval}`, {
              count: productClient.accessPeriod.recurring?.interval_count,
            })}
          </Space>
        </Descriptions.Item>
        {productClient.accessPeriod.installments ? (
          <Descriptions.Item label={t("client:installments.cycle")} span={3}>
            {productClient.accessPeriod.cycleNumber} / {productClient.accessPeriod.installments}
          </Descriptions.Item>
        ) : null}
        <Descriptions.Item label={t("list.columns.subscriptionStatus")} span={3}>
          <Space>
            <Badge
              status={
                productClient.accessPeriod.cancelAtPeriodEnd
                  ? SUBSCRIPTION_STATUS_MAPPER.canceled
                  : SUBSCRIPTION_STATUS_MAPPER[productClient.accessPeriod.status]
              }
              text={`${t(`common:subscriptionStatus.${productClient.accessPeriod.status}`)} ${
                productClient.archivedInfo?.reason === ClientArchiveReason.Refund ? t("subscription.refund") : ""
              }`}
            />
            {productClient.accessPeriod.cancelAtPeriodEnd ? (
              <Tag icon={<ClockCircleOutlined />}>
                {t("subscription.endAt")} {dayjs.unix(productClient.accessPeriod.currentPeriodEnd).format("DD.MM")}
              </Tag>
            ) : null}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label={t("list.columns.currentPaymentPeriod")} span={3}>
          <Space size={4} direction="horizontal">
            <span style={{ marginRight: 8 }}>
              {dayjs.unix(productClient.accessPeriod.currentPeriodStart).format(DATE_FORMAT)} - {subscriptionEnd}
            </span>

            <InfoAboutExtendedAccessPeriod accessPeriod={productClient.accessPeriod} />
            {!productClient.archivedInfo && ownerView && !productClient.accessPeriod.cancelAtPeriodEnd && (
              <Button type="link" onClick={() => toggleModalForAccessPeriod(true)}>
                {t("pauseCollection.pausePaymentCollection")}
              </Button>
            )}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label={t("list.columns.nextPaymentDate")} span={3}>
          <Space>
            {productClient.accessPeriod.cancelAtPeriodEnd ? (
              <span>
                {t("subscription.canceled")}
                <Button className="ml-0" type="link" onClick={handleSubscriptionResume}>
                  {t("subscription.resume")}
                </Button>
              </span>
            ) : (
              <span>
                {productClient.archivedInfo ? "-" : subscriptionEnd}
                {productClient.downgradedToFromNextPeriod ? (
                  <Typography.Text strong style={{ margin: "0 5px" }}>
                    - {(productClient.downgradedToFromNextPeriod.amount / 100).toFixed(2)}{" "}
                    {productClient.downgradedToFromNextPeriod.currency.toUpperCase()}
                  </Typography.Text>
                ) : (
                  ""
                )}
                {productClient.accessPeriod.status !== "imported" && (
                  <Popconfirm title={t("subscription.sureToCancel")} onConfirm={handleCancelSubscription}>
                    <Button className="ml-0" type="link" danger>
                      {t("subscription.cancel")}
                    </Button>
                  </Popconfirm>
                )}
              </span>
            )}
          </Space>
        </Descriptions.Item>
        {productClient.accessPeriod.installmentEndsAt && (
          <Descriptions.Item label={t("list.columns.installmentEndsAt")} span={3}>
            <span>{dayjs.unix(productClient.accessPeriod.installmentEndsAt).format(DATE_FORMAT)}</span>
          </Descriptions.Item>
        )}
        <Descriptions.Item label={t("list.columns.paymentMethod")} span={3}>
          {productClient.paymentMethod ? (
            <Space>
              <div className="flex flex-col md:flex-row md:items-center">
                <div>
                  <CreditCardOutlined style={{ marginRight: 5 }} /> {productClient.paymentMethod.brand.toUpperCase()}{" "}
                  <span className="hidden md:inline">**** **** **** </span>
                  {productClient.paymentMethod.last4}
                </div>
                {!ownerView && (
                  <Button type="link" onClick={openPaymentMethodModal} loading={!!loader}>
                    {t("subscription.updatePaymentMethod")}
                  </Button>
                )}
              </div>
            </Space>
          ) : (
            "-"
          )}
        </Descriptions.Item>
        {typeof productClient.invoiceRequested === "boolean" ? (
          <Descriptions.Item label={t("list.columns.requestedInvoice")} span={3}>
            <Switch checked={productClient.invoiceRequested} onChange={updateInvoiceRequest} />
          </Descriptions.Item>
        ) : null}
      </Descriptions>
      <Modal
        visible={showPaymentMethodModal}
        title={t("subscription.updatePaymentMethodTitle")}
        onOk={updatePaymentMethod}
        onCancel={closePaymentMethodModal}
        okButtonProps={{
          loading: !!loader,
        }}
      >
        <div style={{ padding: "16px 8px", border: "1px solid #f0f0f0" }}>
          <CardElement
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "rgba(0, 0, 0, 0.85)",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#f64e60",
                },
              },
            }}
          />
        </div>
      </Modal>
      {ownerView && (
        <Modal
          visible={showModalToUpdateAccessPeriod}
          onCancel={() => toggleModalForAccessPeriod(false)}
          title={t("pauseCollection.pausePaymentCollection")}
          width={600}
          okText={t("common:button.save")}
          onOk={() => formController?.submit()}
          okButtonProps={{
            loading: showModalLoader,
          }}
        >
          <Alert showIcon message={t("pauseCollection.info")} style={{ marginBottom: 16 }} />
          <Form<FormModel>
            form={formController}
            labelCol={{ xxl: { span: 8, offset: 2 }, span: 24, offset: 0 }}
            wrapperCol={{ xxl: { span: 16, offset: 2 }, span: 24, offset: 0 }}
            onFinish={handleFormFinish}
            initialValues={{
              original_access_date: accessEnd,
              resumes_at: productClient.accessPeriod?.pauseCollection?.resumes_at
                ? dayjs.unix(productClient.accessPeriod?.pauseCollection?.resumes_at)
                : null,
            }}
          >
            <Form.Item name="original_access_date" label={t("pauseCollection.accessTo")}>
              <DatePicker disabled style={{ minWidth: 250 }} />
            </Form.Item>
            <Form.Item name="resumes_at" label={t("pauseCollection.pauseTo")}>
              <DatePicker
                style={{ minWidth: 250 }}
                disabledDate={getDisabledDate}
                defaultPickerValue={defaultValue ?? undefined}
                showToday={false}
              />
            </Form.Item>
          </Form>
          <div style={{ textAlign: "center", marginTop: 10 }}>
            <Radio.Group
              value={null}
              onChange={(e) =>
                formController.setFieldsValue({
                  resumes_at: accessEnd?.add(Number(e.target.value), "month"),
                })
              }
            >
              <Radio.Button value={1}>{t("pauseCollection.extendAbout1Month")}</Radio.Button>
              <Radio.Button value={3}>{t("pauseCollection.extendAbout3Month")}</Radio.Button>
              <Radio.Button value={12}>{t("pauseCollection.extendAbout12Month")}</Radio.Button>
            </Radio.Group>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ProductClientSubscriptionSettings;
