import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { createSelector } from "reselect";

import {
  type ClientNutritionDay,
  type ClientNutritionDayWithSource,
} from "@fitness-app/data-models/entities/ClientNutritionDay";
import { type WeekDay } from "@fitness-app/data-models/entities/TrainingProgram";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import { type AppStore } from "../../../index";

export const weekdays: WeekDay[] = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

dayjs.extend(isoWeek);
export const getCurrentMealsPlan = (store: AppStore) => store.traineeMealsPlan.selectedMealsPlanDetails;
export const getSelectedDay = (_store: AppStore, props: { day: string; userId: string }) => props.day;
export const getUserId = (_store: AppStore, props: { day: string; userId: string; days?: ClientNutritionDay[] }) =>
  props.userId;
export const getDaysFromQuery = (
  _store: AppStore,
  props: { day: string; userId: string; days?: ClientNutritionDay[] },
) => props.days;

export const getNutritionTrackedWeek = (store: AppStore) => store.traineeNutritionTracker.fetchedWeekDays;
export const getNutritionDay = createSelector(
  [getCurrentMealsPlan, getSelectedDay, getNutritionTrackedWeek, getUserId, getDaysFromQuery],
  (clientNutrition, day, trackedDays, userId, days): ClientNutritionDayWithSource | null => {
    if (!clientNutrition?.[0]) {
      return null;
    }

    const selectedDay = dayjs(day);

    const trackedDay = (days || trackedDays).find((day) => day.eventDate === selectedDay.format("YYYY-MM-DD"));

    if (trackedDay) {
      return {
        ...trackedDay,
        source: "tracked",
      };
    }

    const selectedDayIndex = selectedDay.locale("pl").isoWeekday();

    const foundDay = clientNutrition[0].weeks[0]?.days.find((day) => day.name === weekdays[selectedDayIndex]);

    return foundDay
      ? {
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          id: generateUniqId(),
          eventDate: selectedDay.format("YYYY-MM-DD"),
          eventWeek: `${selectedDay.format("YYYY")}-${selectedDay.isoWeek()}`,
          day: foundDay,
          traineeId: clientNutrition[0].traineeId,
          clientMealsPlanId: clientNutrition[0].planId,
          source: "mealsPlan",
          userId: userId || null,
        }
      : null;
  },
);
