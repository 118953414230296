import { type Database } from "@fitness-app/supabase";

import { type EmailClientAutomationTask } from "./Automation";
import { type Campaign } from "./Campaign";
import { type FileMessageContent } from "./Chat";
import { type RecurringSurveyInterval, type SurveyAnswer, type SurveyType } from "./ClientSurvey";
import { type SurveyQuestion, type SurveyQuestionAutomation, type SurveyQuestionId } from "./SurveyTemplate";
import { type TraineeStatus } from "./Trainee";
import { type UserRoleEnum } from "./UserProfile";

export type AutomationTemplateEntity = Database["public"]["Tables"]["automation_template"]["Row"];

export enum TemplateType {
  ScheduledMessages = "scheduled_messages",
  ScheduledEmails = "scheduled_emails",
  ScheduledSurvey = "scheduled_survey",
  ScheduledPopUp = "scheduled_pop_up",
}

export interface CreateTemplateDTO {
  name: string;
  comment: string;
  type: TemplateType;
  messages: string[];
  authorId: string;
  authorRole: UserRoleEnum;
  authorName: string;
  sentTime: {
    hours: number;
    minutes: number;
  };
}

export interface BaseAutomationTemplate<Type extends TemplateType, Data extends object>
  extends Omit<AutomationTemplateEntity, "type" | "data"> {
  id: string;
  name: string;
  comment: string;
  createdAt: string;
  updatedAt: string;
  type: Type;
  data: Data;
}

export interface ScheduledMessagesTemplateData {
  messages: string[];
  attachments: FileMessageContent[];
  authorId: string;
  authorRole: UserRoleEnum;
  authorName: string;
  sentTime: {
    hours: number;
    minutes: number;
  };
}

export type ScheduledMessagesTemplate = BaseAutomationTemplate<
  TemplateType.ScheduledMessages,
  ScheduledMessagesTemplateData
>;

export interface ScheduledMessagesAutomation {
  type: TemplateType.ScheduledMessages;
  id: string;
  name: string;
  templateId: string | null;
  comment?: string;
  attachments: FileMessageContent[];
  messages: string[];
  authorId: string;
  authorRole: UserRoleEnum;
  authorName: string;
  authorAvatar: string | null;
  sentTime: {
    hours: number;
    minutes: number;
  };
  variables?: string[];
  sendToDefaultChannel?: boolean;
}

export interface ScheduledPopUpMessagesAutomation {
  type: TemplateType.ScheduledPopUp;
  id: string;
  name: string;
  templateId: string | null;
  comment?: string;
  attachments: FileMessageContent[];
  data: Campaign["data"];
  authorId: string;
  authorRole: UserRoleEnum;
  authorName: string;
  authorAvatar: string | null;
  sentTime: {
    hours: number;
    minutes: number;
  };
  variables?: string[];
  metadata: {
    count: number;
    total: number;
    condition?: "hasTag" | "notHasTag" | null | false;
    tags?: string[] | null;
    products?: string[] | "all" | null;
    statuses?: TraineeStatus[] | null;
  };
}

export interface ScheduledEmailIntegrationAutomation extends Omit<EmailClientAutomationTask, "triggers"> {
  id: string;
  sentTime: {
    hours: number;
    minutes: number;
  };
}

export interface BaseSurveyTemplate {
  id: string;
  name: string;
  description: string | null;
  templateId: string | null;
  title: string;
  editable: boolean;
  comment?: string;
  answers: Record<string, SurveyAnswer> | null;
  type: SurveyType;
  variables: Record<string, string | number> | null;
  automation: Record<SurveyQuestionId, SurveyQuestionAutomation[]> | null;
  questions: Record<string, SurveyQuestion>;
}

export interface OneTimeSurveyTemplate extends BaseSurveyTemplate {
  type: SurveyType.OneTime;
  interval: null;
}

export interface RecurringSurveyTemplate extends BaseSurveyTemplate {
  type: SurveyType.Recurring;
  interval: RecurringSurveyInterval;
  cycleNumber: number;
}

export interface ScheduledSurveyAutomation {
  type: TemplateType.ScheduledSurvey;
  id: string;
  name: string;
  templateId: string | null;
  comment?: string;
  survey: OneTimeSurveyTemplate | RecurringSurveyTemplate;
  sentTime: {
    hours: number;
    minutes: number;
    offset: number;
    timezone: string;
  };
  cycleNumber?: number;
  source?: never;
}

export interface ScheduledSurveyAutomationPlanned {
  type: TemplateType.ScheduledSurvey;
  id: string;
  name: string;
  templateId: string | null;
  comment?: string;
  survey: OneTimeSurveyTemplate | RecurringSurveyTemplate;
  sentTime: {
    hours: number;
    minutes: number;
    offset: number;
  };
  cycleNumber: number;
  source: string;
}

export type AutomationTemplate = ScheduledMessagesTemplate;
