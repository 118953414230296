import React, { useEffect, type FunctionComponent } from "react";
import { Divider, Empty, Table, Tag } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { productClientActions, RequestStatus } from "@fitness-app/app-store";
import { OrderStatusOption, type Order } from "@fitness-app/data-models/entities/Order";
import { OrderPaymentTypeOption, PaymentTypeOption } from "@fitness-app/data-models/entities/Product";
import { type ProductClient } from "@fitness-app/data-models/entities/ProductClient";

import InvoiceDataInfo from "~/modules/Products/components/InvoiceDataInfo/InvoiceDataInfo";
import InvoiceNumberInfo from "~/modules/Products/components/InvoiceNumberInfo/InvoiceNumberInfo";
import CustomConsentData from "~/modules/Products/ProductClientDetails/ProductClientActionsHistory/ClientCustomConsents";
import { tagColorMapper } from "~/modules/Products/utils/tagColorMapper";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface OwnProps {
  productClient: ProductClient;
  ownerView?: boolean;
}

type Props = OwnProps;

const ProductClientActionsHistory: FunctionComponent<Props> = ({ productClient, ownerView }) => {
  const { t } = useTranslation(["products", "client", "common"]);
  const dispatch = useAppDispatch();
  const userDetails = useAppSelector((store) => store.user.profile);
  const { clientOrders, ordersStatus, ordersPage } = useAppSelector((store) => store.productClient);
  const data = useAppSelector((store) => store.productsSettings.data);

  const clientEmail = ownerView ? productClient.email : userDetails?.email;
  const customConsents =
    data?.checkoutSettings?.cart?.pl?.customConsents?.filter(
      (consent) => consent.applyToProducts === true || consent.applyToProducts?.includes(productClient.productId),
    ) || null;

  useEffect(() => {
    if (clientEmail) {
      void dispatch(
        productClientActions.fetchProductClientOrders({
          productId: productClient.productId,
          clientEmail,
        }),
      );
    }
  }, [productClient, clientEmail]);

  if (!productClient) {
    return null;
  }

  if (!clientOrders.length) {
    return (
      <div>
        <Divider orientation="left">{t("client:actions.title")}</Divider>

        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<div>{t<string>("common:emptyList")}</div>} />
      </div>
    );
  }

  const getTransactionType = (order: Order) => {
    if (order.orderForImport) {
      return t("client:operationType.clientImport");
    }
    if (order.paymentCycle === 1 && order.paymentType === PaymentTypeOption.Recurring && order.installments) {
      if (order.price?.paidPhase) {
        return `${t("client:operationType.firstInstallmentInAdvance")} - (Początek: ${dayjs
          .unix(order.price.paidPhase.start_date)
          .format("DD.MM.YYYY")} - ${order.price.paidPhase.metadata?.totalIterations} rat)`;
      }

      return t("client:operationType.firstInstallment");
    }
    if (
      order.paymentType === OrderPaymentTypeOption.Free ||
      (order.paymentCycle === 1 && order.paymentType === PaymentTypeOption.Recurring)
    ) {
      return t("client:operationType.startOfSubscription");
    }
    if (order.paymentCycle && order.paymentCycle > 1 && order.paymentType === PaymentTypeOption.Recurring) {
      if (
        order.installments ||
        (productClient.accessPeriod?.type === "recurring" &&
          order.connectedSubscription === productClient.accessPeriod.subscriptionId &&
          productClient.accessPeriod.installments)
      ) {
        if (order.metadata?.renewal && order.status === OrderStatusOption.Scheduled) {
          return t("client:operationType.futureAccessRenewal");
        }

        return t("client:operationType.nextInstallment");
      }
      if (order.price && "baseAmount" in order.price) {
        return t("client:operationType.updateSubscriptionCycle");
      }
      return t("client:operationType.nextSubscriptionCycle");
    }

    if (order.paymentType === PaymentTypeOption.OneTime) {
      return t("client:operationType.oneTimePayment");
    }

    return "-";
  };

  const onPageChange = (nextPage: number) => {
    if (clientEmail) {
      void dispatch(
        productClientActions.fetchProductClientOrdersNextPage({
          productId: productClient.productId,
          clientEmail,
          page: nextPage,
        }),
      );
    }
  };

  return (
    <div>
      <Divider orientation="left">{t("client:actions.title")}</Divider>
      <Table<Order>
        loading={ordersStatus === RequestStatus.FETCHING || ordersStatus === RequestStatus.UPDATING}
        rowKey="id"
        dataSource={clientOrders ?? []}
        pagination={{
          current: ordersPage,
          pageSize: 50,
          total: productClient.connectedOrders.length,
          onChange: onPageChange,
        }}
        scroll={{
          x: true,
        }}
        expandedRowRender={(order) => (
          <div className="space-y-4">
            <InvoiceDataInfo orderClient={order.client} />
            {customConsents && customConsents.length > 0 && (!order.paymentCycle || order.paymentCycle == 1) ? (
              <CustomConsentData customConsents={customConsents} metadata={order.metadata} />
            ) : null}
          </div>
        )}
      >
        <Table.Column<Order>
          title={t("client:actions.table.type")}
          dataIndex="type"
          key="type"
          render={(_, row) => getTransactionType(row)}
        />
        <Table.Column<Order>
          title={t("client:actions.table.date")}
          dataIndex="createdAt"
          key="createdAt"
          render={(createdAt: string) => dayjs(createdAt).format("DD.MM.YYYY HH:mm")}
        />
        <Table.Column<Order>
          title={t("client:actions.table.price")}
          dataIndex="price"
          key="price"
          render={(price: Order["price"]) =>
            price && price.amount ? (
              <b>
                {(price.amount / 100).toFixed(2)} {price.currency?.toUpperCase()}
              </b>
            ) : (
              "-"
            )
          }
        />
        <Table.Column<Order>
          title={t("client:actions.table.status")}
          dataIndex="status"
          key="status"
          render={(type: Order["status"]) => (
            <Tag color={tagColorMapper[type || OrderStatusOption.Created]}>
              {t(`productTransactions.type.${type?.toLowerCase() || "paid"}`)}
            </Tag>
          )}
        />
        <Table.Column<Order>
          title={t("client:actions.table.invoice")}
          dataIndex="connectedInvoice"
          key="connectedInvoice"
          render={(connectedInvoice: Order["connectedInvoice"]) => (
            <InvoiceNumberInfo invoice={connectedInvoice} hideError />
          )}
        />
      </Table>
    </div>
  );
};

export default ProductClientActionsHistory;
