import React from "react";
import { Divider, Grid, Image, Skeleton, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { type ClientPhoto } from "@fitness-app/data-models/entities/Measurement";

interface TraineeBodyPhotoRowProps {
  date: string;
  photos: ClientPhoto[];
}

const TraineeBodyPhotoRow = ({ date, photos }: TraineeBodyPhotoRowProps) => {
  const { md } = Grid.useBreakpoint();
  const { t } = useTranslation("trainees");

  return (
    <div>
      <Divider>{date}</Divider>
      <Skeleton active loading={false} paragraph={{ rows: 4 }} style={{ width: "100%" }}>
        <Image.PreviewGroup preview={{ className: "ph-no-capture" }}>
          <Space size={12} direction={md ? "horizontal" : "vertical"} style={{ width: "100%", flexWrap: "wrap" }}>
            {photos.map((collection) =>
              collection.data.content.map((photo) => (
                <div key={photo.photoId} className="flex flex-col gap-y-2">
                  <div>
                    <Image
                      className="ph-no-capture rounded-md"
                      height={200}
                      src={photo.thumbnailUrl || photo.url}
                      preview={{ src: photo.url }}
                    />
                  </div>
                  <Typography.Text className="text-center" type="secondary">
                    {t(`photos.type.${photo.type}`)}
                  </Typography.Text>
                </div>
              )),
            )}
          </Space>
        </Image.PreviewGroup>
      </Skeleton>
    </div>
  );
};

export default TraineeBodyPhotoRow;
