import { useEffect } from "react";
import { DatePicker, Form, Input, Select, Switch } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { CheckoutVariantTarget } from "@fitness-app/data-models/entities/CheckoutVariant";

import { useProductPricesOptions } from "~/modules/Products/hooks/useProductPricesOptions";
import { type ProductCheckoutVariantFormModel } from "./types";

interface ProductCheckoutVariantFormProps {
  formController?: FormInstance<ProductCheckoutVariantFormModel>;
  onSubmit: (formData: ProductCheckoutVariantFormModel) => void;
  model?: Partial<ProductCheckoutVariantFormModel> | null;
  domain?: string;
}

const ProductCheckoutVariantForm = ({ model, onSubmit, formController, domain }: ProductCheckoutVariantFormProps) => {
  const { t } = useTranslation(["products", "common"]);
  const options = useProductPricesOptions(undefined, true);
  const target = Form.useWatch("target", formController);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  const pathPrefix = target === CheckoutVariantTarget.Renewal ? "przedluzenie" : "step";

  return (
    <Form<ProductCheckoutVariantFormModel>
      name="form"
      layout="vertical"
      form={formController}
      initialValues={{
        target: CheckoutVariantTarget.New,
        slug: "",
        isActive: true,
        prices: [],
        default: false,
        activeUntil: null,
      }}
      onFinish={onSubmit}
    >
      <Form.Item
        label="Slug"
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
          {
            min: 1,
            message: t<string>("common:validationErrors.minLength", {
              number: 1,
            }),
          },
        ]}
        name="slug"
      >
        <Input
          autoFocus
          className="max-w-md"
          addonBefore={
            domain
              ? `${domain}.${import.meta.env.VITE_ECOMMERCE_DOMAIN}/${pathPrefix}/`
              : `${import.meta.env.VITE_ECOMMERCE_DOMAIN}/${pathPrefix}/`
          }
        />
      </Form.Item>

      <Form.Item
        label="Typ koszyka"
        name="target"
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Select
          className="max-w-xs"
          options={[
            {
              label: "Tylko nowi klienci",
              value: CheckoutVariantTarget.New,
            },
            {
              label: "Tylko aktualni klienci",
              value: CheckoutVariantTarget.Renewal,
            },
          ]}
        />
      </Form.Item>

      <Form.Item name="isActive" label="Aktywny" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item name="default" label="Domyślny wariant" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item
        label={t("prices.priceTime")}
        name="activeUntil"
        tooltip={t("prices.willBeShowsTimerInCart")}
        rules={[
          {
            required: false,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
          {
            validator: (_, value) =>
              !value || value > Date.now()
                ? Promise.resolve()
                : Promise.reject(new Error(t<string>("prices.cannotBeEarlierThanNow"))),
          },
        ]}
      >
        <DatePicker
          showTime
          format="YYYY-MM-DD | HH:mm"
          disabledDate={(current) => current && current.valueOf() < Date.now() - 60 * 60 * 24}
        />
      </Form.Item>

      <Form.Item
        label="Dostępne ceny"
        name="prices"
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Select
          className="max-w-lg"
          mode="multiple"
          placeholder="Wybierz ceny, które mają być dostępne w koszyku"
          popupMatchSelectWidth={false}
          options={options}
        />
      </Form.Item>
    </Form>
  );
};

export default ProductCheckoutVariantForm;
