import React, { useEffect } from "react";
import { Form, Input } from "antd";
import { type FormInstance } from "antd/lib/form";
import { useTranslation } from "react-i18next";

import { type UpdateProductSlugFormModel } from "./types";

interface UpdateProductSlugFormProps {
  formController?: FormInstance<UpdateProductSlugFormModel>;
  onSubmit: (formData: UpdateProductSlugFormModel) => void;
  model?: Partial<UpdateProductSlugFormModel> | null;
  domain?: string;
}

const UpdateProductSlugForm = ({ model, onSubmit, formController, domain }: UpdateProductSlugFormProps) => {
  const { t } = useTranslation(["common", "products"]);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model, formController]);

  return (
    <Form<UpdateProductSlugFormModel>
      name="form"
      layout="vertical"
      form={formController}
      initialValues={{
        slug: "",
      }}
      onFinish={onSubmit}
    >
      <Form.Item
        preserve
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
          {
            min: 5,
            message: t<string>("common:validationErrors.minLength", {
              number: 5,
            }),
          },
        ]}
        name="slug"
      >
        <Input
          autoFocus
          addonBefore={
            domain
              ? `${domain}.${import.meta.env.VITE_ECOMMERCE_DOMAIN}/p/`
              : `${import.meta.env.VITE_ECOMMERCE_DOMAIN}/p/`
          }
        />
      </Form.Item>
    </Form>
  );
};

export default UpdateProductSlugForm;
