import React, { useMemo } from "react";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Alert, Grid, Image, List, Rate, Space, Typography } from "antd";
import dayjs from "dayjs";
import i18n from "i18next";
import {
  Archive,
  CalendarX,
  Camera,
  ClipboardList,
  GlassWater,
  ListChecks,
  LogIn,
  Salad,
  Scale,
  Sliders,
  Star,
  TrendingUp,
  Unlock,
  UserCheck,
  UserMinus,
  UserPlus,
  VideoIcon,
} from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { TraineeEventType } from "@fitness-app/data-models/businessEvents/domain/trainee/TraineeEvents";
import { TraineeActivityEventType } from "@fitness-app/data-models/businessEvents/domain/traineeActivity/TraineeActivityEvents";
import { type ClientActivityEvent } from "@fitness-app/data-models/entities/ClientActivity";

import IconText from "~/components/IconText/IconText";

interface ActivityEventItemProps {
  event: ClientActivityEvent;
}

const iconMapper: Partial<Record<TraineeActivityEventType | TraineeEventType, typeof Camera>> = {
  [TraineeActivityEventType.TraineeAddedBodyPhotos]: Camera,
  [TraineeActivityEventType.TraineeAddedWeightMeasurement]: Scale,
  [TraineeActivityEventType.TraineeAddedBodyMeasurements]: TrendingUp,
  [TraineeActivityEventType.TraineeAddedWorkoutRating]: Star,
  [TraineeActivityEventType.TraineeTrackedWaterConsumption]: GlassWater,
  [TraineeActivityEventType.TraineeSentSurveyAnswers]: ListChecks,
  [TraineeActivityEventType.TraineeSentExerciseRecording]: VideoIcon,
  [TraineeEventType.TraineeEndingProgramIn1Day]: CalendarX,
  [TraineeEventType.TraineeEndingProgramIn1Week]: CalendarX,
  [TraineeEventType.TraineeAssignedToTrainer]: UserPlus,
  [TraineeEventType.TraineeRemovedFromTrainer]: UserMinus,
  [TraineeEventType.TraineeActivatedAccount]: UserCheck,
  [TraineeActivityEventType.TraineeSignedInToApp]: LogIn,
  [TraineeEventType.TraineeStartedWorkoutProgram]: ClipboardList,
  [TraineeEventType.TraineeStartedNutritionPlan]: Salad,
  [TraineeEventType.TraineeEndedWorkoutProgram]: ClipboardList,
  [TraineeEventType.TraineeEndedNutritionPlan]: Salad,
  [TraineeEventType.TraineeGotAccessToWorkoutProgram]: Unlock,
  [TraineeEventType.TraineeGotAccessToNutritionPlan]: Unlock,
  [TraineeEventType.TraineeAccountDeactivated]: Archive,
};

const ItemContent = ({ event }: { event: ClientActivityEvent }) => {
  const { t } = useTranslation("trainees");
  const { md } = Grid.useBreakpoint();
  const navigate = useNavigate();

  if (
    event.eventName === TraineeEventType.TraineeEndingProgramIn1Day ||
    event.eventName === TraineeEventType.TraineeEndingProgramIn1Week
  ) {
    return (
      <Alert
        type={event.payload.data.nextPlan ? "info" : "warning"}
        message={
          event.payload.data.nextPlan?.startDate
            ? `Klient ma zaplanowany kolejny plan treningowy ${event.payload.data.nextPlan.name} od ${dayjs(
                event.payload.data.nextPlan.startDate,
                "YYYY-MM-DD",
              ).format("DD.MM.YYYY")}`
            : "Brak zaplanowanego kolejnego planu treningowego"
        }
      />
    );
  }

  if (event.eventName === TraineeActivityEventType.TraineeAddedWeightMeasurement) {
    return (
      <Alert
        message={`Nowy pomiar: ${event.payload.data.weight} kg (${dayjs
          .unix(event.payload.date)
          .format("DD.MM.YYYY")})`}
      />
    );
  }

  if (event.eventName === TraineeActivityEventType.TraineeSentExerciseRecording) {
    return (
      <Typography.Link
        className="ml-14"
        onClick={() => navigate(`/trainee/details/${event.payload.client.id}?tab=measurements&subTab=videos`)}
      >
        Przejdź do zapisanego filmu
      </Typography.Link>
    );
  }

  if (event.eventName === TraineeActivityEventType.TraineeTrackedWaterConsumption) {
    return <Alert message={`Nowa porcja: ${event.payload.data.portion} ml`} />;
  }

  if (event.eventName === TraineeActivityEventType.TraineeAddedWorkoutRating) {
    return (
      <Alert
        message={
          <div className="flex flex-col">
            <div className="flex items-center gap-x-2">
              <span>Trening zaplanowany na dzień:</span>
              <div className="flex items-center gap-x-2">
                {dayjs.unix(event.payload.date).locale("pl").format("dddd, LL")}
              </div>
            </div>
            <div className="flex items-center gap-x-2">
              <span>Ocena treningu:</span>
              <div className="flex items-center gap-x-2">
                <Rate disabled defaultValue={event.payload.data.rate ?? 0} />
              </div>
            </div>
            <div className="flex items-center gap-x-2">
              <span>Komentarz:</span>
              <span>{event.payload.data.comment || "-"}</span>
            </div>
          </div>
        }
      />
    );
  }

  if (event.eventName === TraineeActivityEventType.TraineeAddedBodyPhotos) {
    return (
      <Image.PreviewGroup preview={{ className: "ph-no-capture" }}>
        <Space size={12} direction={md ? "horizontal" : "vertical"} style={{ width: "100%", flexWrap: "wrap" }}>
          {event.payload.data.content.map((photo) => (
            <div key={photo.photoId} className="flex flex-col gap-y-2">
              <div>
                <Image
                  rootClassName="ph-no-capture"
                  className="ph-no-capture rounded-md"
                  height={60}
                  src={photo.thumbnailUrl || photo.url}
                  preview={{ src: photo.url }}
                />
              </div>
              <Typography.Text className="text-center" type="secondary">
                {t(`photos.type.${photo.type}`)}
              </Typography.Text>
            </div>
          ))}
        </Space>
      </Image.PreviewGroup>
    );
  }

  return null;
};

const getName = (event: ClientActivityEvent) => {
  if (event.eventName === TraineeActivityEventType.TraineeSentSurveyAnswers) {
    return event.payload.surveyName;
  }

  if (
    event.eventName === TraineeEventType.TraineeEndingProgramIn1Day ||
    event.eventName === TraineeEventType.TraineeEndingProgramIn1Week
  ) {
    return event.payload.data.programName;
  }

  if (event.eventName === TraineeEventType.TraineeAssignedToTrainer) {
    return `${event.payload.assignedTrainer.firstName} ${event.payload.assignedTrainer.lastName}`;
  }

  if (event.eventName === TraineeActivityEventType.TraineeSentExerciseRecording) {
    return event.payload.exerciseName;
  }

  if (event.eventName === TraineeEventType.TraineeRemovedFromTrainer) {
    return `${event.payload.previousTrainer?.firstName} ${event.payload.previousTrainer?.lastName}`;
  }

  if (
    event.eventName === TraineeEventType.TraineeStartedWorkoutProgram ||
    event.eventName === TraineeEventType.TraineeGotAccessToWorkoutProgram ||
    event.eventName === TraineeEventType.TraineeEndedWorkoutProgram ||
    event.eventName === TraineeEventType.TraineeGotAccessToNutritionPlan ||
    event.eventName === TraineeEventType.TraineeEndedNutritionPlan ||
    event.eventName === TraineeEventType.TraineeStartedNutritionPlan
  ) {
    return event.payload.program.name;
  }

  if (event.eventName === TraineeActivityEventType.TraineeAddedWorkoutRating) {
    return i18n.t(`workouts:clientRatingOptionsNumber.${event.payload.data.rate}`);
  }
};

const getDateString = (event: ClientActivityEvent) => {
  if (
    event.eventName === TraineeEventType.TraineeEndingProgramIn1Day ||
    event.eventName === TraineeEventType.TraineeEndingProgramIn1Week
  ) {
    return dayjs(event.payload.data.endDate).format("DD.MM.YYYY");
  }
  return;
};

const ActivityEventItem = ({ event }: ActivityEventItemProps) => {
  const { t } = useTranslation(["trainee", "common"]);

  const [activityName, eventType] = event.eventName.split(".").slice().reverse();

  const Icon = iconMapper[event.eventName] || Sliders;

  const colors = useMemo(() => {
    if (event.eventName === TraineeEventType.TraineeAccountDeactivated) {
      return {
        bg: "bg-red-500",
        icon: "text-red-100",
      };
    }

    if (
      event.eventName === TraineeEventType.TraineeRemovedFromTrainer ||
      event.eventName === TraineeEventType.TraineeEndedWorkoutProgram ||
      event.eventName === TraineeEventType.TraineeEndedNutritionPlan ||
      event.eventName === TraineeEventType.TraineeEndingProgramIn1Week ||
      event.eventName === TraineeEventType.TraineeEndingProgramIn1Day
    ) {
      return {
        bg: "bg-amber-500",
        icon: "text-amber-100",
      };
    }

    if (
      event.eventName === TraineeEventType.TraineeStartedWorkoutProgram ||
      event.eventName === TraineeEventType.TraineeStartedNutritionPlan
    ) {
      return {
        bg: "bg-gray-400",
        icon: "text-gray-100",
      };
    }

    if (
      event.eventName === TraineeEventType.TraineeAssignedToTrainer ||
      event.eventName === TraineeEventType.TraineeActivatedAccount ||
      event.eventName === TraineeEventType.TraineeGotAccessToWorkoutProgram ||
      event.eventName === TraineeEventType.TraineeGotAccessToNutritionPlan
    ) {
      return {
        bg: "bg-green-500",
        icon: "text-green-100",
      };
    }

    return {
      bg: "bg-sky-500",
      icon: "text-sky-100",
    };
  }, [event.eventName]);

  const eventDay = dayjs.unix(event.timestamp);

  return (
    <List.Item>
      <List.Item.Meta
        avatar={
          <div className={`flex items-center justify-center rounded-full p-2.5 ${colors.bg}`}>
            <Icon size={20} className={colors.icon} />
          </div>
        }
        title={t(`common:events.${eventType}.${activityName}`, { name: getName(event), date: getDateString(event) })}
        description={
          <IconText
            icon={ClockCircleOutlined}
            text={
              eventDay.isBefore(dayjs("2024-01-15", "YYYY-MM-DD"))
                ? eventDay.format("DD.MM.YYYY")
                : eventDay.format("DD.MM.YYYY HH:mm")
            }
            key="list-vertical-star-o"
          />
        }
      />
      <ItemContent event={event} />
    </List.Item>
  );
};

export default ActivityEventItem;
