import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { type NutritionDaySummary } from "@fitness-app/data-models/entities/ClientNutritionWeek";

import { RequestStatus } from "../../../enums/requestStatus";
import { deleteMeasurement } from "./actions/deleteMeasurement";
import { fetchBodyAndWeightMeasurements } from "./actions/fetchBodyAndWeightMeasurements";
import { fetchLatestWeightMeasurement } from "./actions/fetchLatestWeightMeasurement";
import { fetchMeasurementsForRange } from "./actions/fetchMeasurementsForRange";
import { fetchTraineeBodyPhotos } from "./actions/fetchTraineeBodyPhotos";
import { upsertMeasurement } from "./actions/upsertMeasurement";
import { TRAINEE_MEASUREMENTS_REDUCER_NAME, type TraineeMeasurementsReducer } from "./types";

const initialState: TraineeMeasurementsReducer = {
  measurements: [],
  measurementsStatus: null,
  measurementsPageSize: 25,
  measurementsCurrentPage: 0,
  measurementsTotalPages: 0,

  photos: [],
  photosStatus: null,
  photosTotalPages: 0,
  photosCurrentPage: 0,

  hydrationMeasurement: [],
  hydrationMeasurementStatus: null,

  measurementsForDateRange: [],
  latestFetchedWeight: null,

  selectedNutritionDay: null,
  addingNewMeasurement: false,
};

const reducerSlice = createSlice({
  initialState,
  name: TRAINEE_MEASUREMENTS_REDUCER_NAME,
  reducers: {
    clearMeasurements: () => {
      return initialState;
    },
    selectNutritionDay: (state, action: PayloadAction<NutritionDaySummary | null>) => {
      state.selectedNutritionDay = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBodyAndWeightMeasurements.pending, (state, { meta }) => {
      if (!meta.arg.page) {
        state.measurements = [];
        state.measurementsStatus = RequestStatus.FETCHING;
      }
    });
    builder.addCase(fetchBodyAndWeightMeasurements.fulfilled, (state, { meta, payload }) => {
      const isInitialFetch = !meta.arg.page;

      if (isInitialFetch) {
        state.measurements = payload.data;
        state.measurementsStatus = RequestStatus.SUCCESS;
      } else {
        state.measurements = [...state.measurements, ...payload.data];
        state.measurementsStatus = RequestStatus.SUCCESS;
      }

      state.measurementsCurrentPage = payload.currentPage;
      state.measurementsTotalPages = payload.totalPages;
    });
    builder.addCase(fetchBodyAndWeightMeasurements.rejected, (state) => {
      state.measurementsStatus = RequestStatus.FAILED;
    });
    builder.addCase(deleteMeasurement.fulfilled, (state, { meta }) => {
      state.measurements = state.measurements.filter((measurement) => !meta.arg.ids.includes(measurement.id));
    });
    builder.addCase(upsertMeasurement.pending, (state) => {
      state.addingNewMeasurement = true;
    });
    builder.addCase(upsertMeasurement.rejected, (state) => {
      state.addingNewMeasurement = false;
    });
    builder.addCase(upsertMeasurement.fulfilled, (state, { payload, meta }) => {
      state.addingNewMeasurement = false;
      if (meta.arg.previousMeasurement) {
        state.measurements = state.measurements.map((measurement) => {
          if (measurement.id === payload.id) {
            return payload;
          }

          return measurement;
        });
      } else {
        state.measurements = [payload, ...state.measurements];
      }
    });
    builder.addCase(fetchTraineeBodyPhotos.pending, (state, { meta }) => {
      if (!meta.arg.page) {
        state.photos = [];
        state.photosStatus = RequestStatus.FETCHING;
      }
    });
    builder.addCase(fetchTraineeBodyPhotos.fulfilled, (state, { meta, payload }) => {
      const isInitialFetch = !meta.arg.page;

      if (isInitialFetch) {
        state.photos = payload.data;
        state.photosStatus = RequestStatus.SUCCESS;
      } else {
        state.photos = [...state.photos, ...payload.data];
        state.photosStatus = RequestStatus.SUCCESS;
      }

      state.measurementsCurrentPage = payload.currentPage;
      state.measurementsTotalPages = payload.totalPages;
    });
    builder.addCase(fetchTraineeBodyPhotos.rejected, (state) => {
      state.photosStatus = RequestStatus.FAILED;
    });
    builder.addCase(fetchMeasurementsForRange.fulfilled, (state, { payload }) => {
      state.measurementsForDateRange = payload;
    });
    builder.addCase(fetchLatestWeightMeasurement.fulfilled, (state, { payload }) => {
      state.latestFetchedWeight = payload;
    });
  },
});

export default reducerSlice.reducer;
export const { clearMeasurements, selectNutritionDay } = reducerSlice.actions;
