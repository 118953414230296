import React, {
  forwardRef,
  lazy,
  memo,
  Suspense,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  type KeyboardEvent,
} from "react";
import {
  AudioOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  PaperClipOutlined,
  SendOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import data from "@emoji-mart/data";
import i18n from "@emoji-mart/data/i18n/pl.json";
import * as Sentry from "@sentry/react";
import { Dropdown, Input, message, Popover, Spin, Tooltip, type MenuProps } from "antd";
import { type TextAreaRef } from "antd/es/input/TextArea";
import dayjs, { type Dayjs } from "dayjs";
import { type BaseEmoji } from "emoji-mart/dist-es/utils/emoji-index/nimble-emoji-index";
import { CalendarClock } from "lucide-react";

import { chatActions } from "@fitness-app/app-store";
import { UserRole } from "@fitness-app/data-models";
import {
  ChatStatus,
  type ChatChannel,
  type ChatMember,
  type ChatMessage,
  type FileMessageContent,
} from "@fitness-app/data-models/entities/Chat";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";
import { cn } from "@fitness-app/utils/src/styles/cn";

import AudioRecorder from "~/components/AudioRecorder/AudioRecorder";
import useAudioRecorder from "~/components/AudioRecorder/useAudioRecorder";
import FilesList from "~/components/Upload/FilesList";
import { UploadFileStatus, type FileData } from "~/components/Upload/types";
import UploadInput from "~/components/Upload/UploadInput";
import { useUploadFilesContext } from "~/components/Upload/UploadProvider";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import AuthorSelector from "~/modules/Chat/components/AuthorSelector/AuthorSelector";
import { useAllowAuthorSelector } from "~/modules/Chat/components/AuthorSelector/useAllowAuthorSelector";
import { useChatMessageEditContext } from "~/modules/Chat/components/ChatMessageEditContext";
import {
  ScheduleMessageForm,
  type ScheduleMessageFormRef,
} from "~/modules/Chat/components/ScheduledMessages/ScheduleMessageForm";
import { useScheduleChatMessage } from "~/modules/Chat/hooks/useScheduleChatMessage";
import { useChatUtilitiesContext } from "~/modules/Chat/providers/ChatUtilitiesProvider";
import { useAppDispatch } from "~/store/initializeStore";

const Picker = lazy(() => import("@emoji-mart/react"));

interface OwnProps {
  channelId: string;
  minimized?: boolean;
  channel: ChatChannel;
  onResize?: ({ width, height }: { width: number; height: number }) => void;
  rows?: number;
  initialValue?: string;
  hiddeSendButton?: boolean;
  setDisabled?: (value: boolean) => void;
}

type Props = OwnProps;

export interface ChatInputRef {
  setInitialMessage: (message: string) => void;
  sendMessage: (messageId?: string, onSuccess?: () => void, context?: ChatMessage["context"]) => void;
}

const ChatInput = forwardRef<ChatInputRef, Props>(
  ({ initialValue, channelId, minimized, channel, onResize, rows, hiddeSendButton, setDisabled }, ref) => {
    const [inputValue, setInputValue] = useState(initialValue || "");
    const { openScheduledMessages } = useChatUtilitiesContext();
    const { messageToEdit, setMessageToEdit, updateMessageContent } = useChatMessageEditContext();
    const inputRef = useRef<TextAreaRef | null>(null);
    const dispatch = useAppDispatch();
    const { files, resetFilesList, onUploadFiles } = useUploadFilesContext();
    const [selectedAuthor, selectAuthor] = useState<null | ChatMember>(null);
    const allowAuthorSelector = useAllowAuthorSelector();
    const [openEmojiPicker, toggleEmojiPicker] = useState(false);
    const [audioRecordMode, setAudioRecordMode] = useState(false);
    const userRole = useUserRole();
    const recorderControls = useAudioRecorder(
      {
        noiseSuppression: true,
        echoCancellation: true,
      },
      (err) => console.table(err), // onNotAllowedOrFound
    );
    const { sendScheduledMessage, numberOfScheduledMessages, scheduledMessages, loading } =
      useScheduleChatMessage(channel);
    const scheduleFormRef = useRef<ScheduleMessageFormRef>(null);

    useImperativeHandle(ref, () => ({
      setInitialMessage: (message: string) => {
        setInputValue(message);
        inputRef.current?.focus({ cursor: "end" });
      },
      sendMessage: (messageId?: string, onSuccess?: () => void, context?: ChatMessage["context"]) => {
        handleSubmit(undefined, messageId, onSuccess, context);
      },
    }));

    const addAudioElement = (blob: Blob, duration: number) => {
      const file = new File([blob], `audio-${generateUniqId()}.webm`, {
        type: blob.type,
        lastModified: new Date().getTime(),
      });

      // @ts-expect-error ignore
      file.duration = duration;
      onUploadFiles([file], false, true);
    };

    useEffect(() => {
      if (messageToEdit) {
        setInputValue(messageToEdit.content || "");
        inputRef.current?.focus();
      } else {
        setInputValue(initialValue || "");
        inputRef.current?.focus({ cursor: "end" });
      }
    }, [messageToEdit]);

    const disabled =
      (!inputValue.trim().length &&
        !files?.filter((file): file is FileData & { url: string } =>
          Boolean(file.status === UploadFileStatus.SUCCESS && file.url),
        )?.length) ||
      channel.status === ChatStatus.Archived ||
      !!loading;

    useEffect(() => {
      if (setDisabled) {
        setDisabled(disabled);
      }
    }, [disabled]);

    useLayoutEffect(() => {
      setTimeout(() => {
        inputRef.current?.focus({ cursor: "end" });
      }, 100);
    }, []);

    useEffect(() => {
      if (!minimized) {
        inputRef.current?.focus();
      }
    }, [minimized]);

    const handleSubmit = (
      event?: KeyboardEvent<HTMLTextAreaElement>,
      messageId?: string,
      onSuccess?: () => void,
      context?: ChatMessage["context"],
    ) => {
      const pressSendButton = event?.keyCode === 13 && !event.shiftKey;
      if (!event || pressSendButton) {
        event?.preventDefault();

        const uploadedFiles: FileMessageContent[] =
          files
            ?.filter((file): file is FileData & { url: string } =>
              Boolean(file.status === UploadFileStatus.SUCCESS && file.url),
            )
            .map((file) => ({
              uid: file.uid,
              originalName: file.originalName,
              size: file.size,
              shortName: file.shortName,
              url: file.url,
              contentType: file.contentType,
              extension: file.extension,
              duration: file.duration || null,
              thumbUrl: file.thumbUrl || null,
            })) || [];

        if (inputValue.trim().length > 0 || uploadedFiles?.length > 0) {
          if (messageToEdit) {
            updateMessageContent(inputValue);
          } else {
            void dispatch(
              chatActions.sendNewMessage({
                messageId,
                channelId,
                context,
                content: inputValue || "",
                files: uploadedFiles,
                selectedAuthor,
                onSuccess,
                onRejected: (error) => {
                  Sentry.captureException(new Error(error), { data: { action: "send chat message" } });
                  void message.error("Nie udało się wysłać wiadomości.Spróbuj ponownie.");
                  setInputValue(inputValue || "");
                },
              }),
            );
            setInputValue("");
            resetFilesList();
            setAudioRecordMode(false);
            recorderControls.setRecordingBlob(undefined);
            inputRef.current?.focus();
          }
        }
      }
    };

    const onEmojiSelect = (emoji: BaseEmoji) => {
      toggleEmojiPicker(false);
      setInputValue((prev) => prev + emoji.native);
      inputRef.current?.focus();
    };

    const scheduleMessage = async (date: Dayjs) => {
      const client = Object.values(channel.members).find((member) => member.role === UserRole.CLIENT);

      if (!client) {
        message.error("Ten kanał nie ma przypisanego klienta");
        return "failure";
      }

      const author = selectedAuthor
        ? {
            authorId: selectedAuthor.uid,
            authorRole: selectedAuthor.role,
            authorName: selectedAuthor.name,
            authorAvatar: selectedAuthor.avatarUrl || null,
          }
        : {
            authorId: userRole.userId,
            authorRole: userRole.role || UserRole.TRAINER,
            authorName: userRole.userName,
            authorAvatar: userRole.userAvatar || null,
          };

      if (!inputValue.trim()) {
        return "failure";
      }

      const onSentSuccess = () => {
        setInputValue("");
        resetFilesList();
        setAudioRecordMode(false);
        recorderControls.setRecordingBlob(undefined);
        inputRef.current?.focus();
      };

      const [firstName, ...lastName] = client.name.split(" ");

      const selectedClient = {
        id: client.clientId!,
        userId: client.uid,
        firstName: firstName || "",
        lastName: lastName.join(" ") || "",
        email: client.email,
      };

      await sendScheduledMessage({
        messages: [inputValue],
        client: selectedClient,
        author,
        days: [date],
        onSentSuccess,
      });

      return "success";
    };

    const handleMenuClick: MenuProps["onClick"] = (e) => {
      if (e.key === "on_hour") {
        scheduleMessage(dayjs().add(1, "hour"));
      }

      if (e.key === "tomorrow") {
        scheduleMessage(dayjs().add(1, "day").set("hour", 8).set("minute", 0));
      }

      if (e.key === "set_time") {
        scheduleFormRef.current?.openForm();
      }
    };

    const items = useMemo(() => {
      return [
        {
          label: "Wyślij za godzinę",
          key: "on_hour",
          disabled: Boolean(files.length),
        },
        {
          label: "Wyślij jutro o 8:00",
          key: "tomorrow",
          disabled: Boolean(files.length),
        },
        {
          type: "divider",
        },
        {
          label: <ScheduleMessageForm ref={scheduleFormRef} onSubmit={scheduleMessage} />,
          key: "set_time",
          icon: <ClockCircleOutlined />,
          disabled: Boolean(files.length),
        },
      ] satisfies MenuProps["items"];
    }, [files, scheduleMessage]);

    const menuProps = {
      items,
      onClick: handleMenuClick,
    };

    return (
      <div id="chat-input" className="flex-0 relative flex w-full rounded border border-gray-200 bg-gray-50/80 p-1">
        <div className="flex w-full flex-col">
          <div className="flex items-center gap-x-2 py-1">
            {allowAuthorSelector && (
              <AuthorSelector selectedAuthor={selectedAuthor} channel={channel} onSelectChatMember={selectAuthor} />
            )}
            <Popover
              overlayInnerStyle={{ boxShadow: "none", padding: 0 }}
              trigger="click"
              open={openEmojiPicker}
              onOpenChange={toggleEmojiPicker}
              content={
                <Suspense fallback={<Spin />}>
                  <Picker data={data} onEmojiSelect={onEmojiSelect} i18n={i18n} />
                </Suspense>
              }
            >
              <SmileOutlined className="cursor-pointer text-lg text-gray-600 hover:text-gray-700" />
            </Popover>

            <UploadInput>
              <PaperClipOutlined className="mt-1 cursor-pointer text-lg text-gray-600 hover:text-gray-700" />
            </UploadInput>
            <AudioOutlined
              className={cn(
                "cursor-pointer text-lg text-gray-600 hover:text-gray-700",
                audioRecordMode && "text-blue-500",
              )}
              onClick={() => {
                if (audioRecordMode) {
                  setAudioRecordMode(!audioRecordMode);
                  recorderControls.stopRecording();
                  recorderControls.setRecordingBlob(undefined);
                  resetFilesList(true);
                  setTimeout(() => {
                    inputRef.current?.focus();
                  }, 100);
                } else {
                  setAudioRecordMode(!audioRecordMode);
                  recorderControls.startRecording();
                }
              }}
            />

            {numberOfScheduledMessages > 0 ? (
              <div className="flex flex-1 justify-end px-2" style={{ color: "rgb(38, 153, 251)" }}>
                <Tooltip
                  title={
                    <div>
                      <span>Zaplanowano {numberOfScheduledMessages} wiadomości:</span>

                      <ul>
                        {scheduledMessages.map((task) => (
                          <li key={task.id}>{dayjs(task.sendDate).format("DD.MM.YYYY HH:mm")}</li>
                        ))}
                      </ul>
                    </div>
                  }
                >
                  <div
                    className="flex cursor-pointer gap-1"
                    role="button"
                    onClick={() => openScheduledMessages(scheduledMessages[0]?.traineeId || "")}
                  >
                    <CalendarClock size={18} className="align-text-top" color="rgb(38, 153, 251)" />
                    <span>{numberOfScheduledMessages}</span>
                  </div>
                </Tooltip>
              </div>
            ) : null}
          </div>

          <div className="flex">
            {audioRecordMode ? (
              <AudioRecorder
                onRecordingComplete={(blob, duration) => addAudioElement(blob, duration)}
                recorderControls={recorderControls}
              />
            ) : (
              <Input.TextArea
                ref={inputRef}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Wyślij wiadomość.."
                value={inputValue}
                disabled={channel.status === ChatStatus.Archived}
                onPressEnter={hiddeSendButton ? undefined : handleSubmit}
                autoSize={{ minRows: rows || 1, maxRows: rows || 8 }}
                onResize={onResize}
              />
            )}

            {!hiddeSendButton && (
              <div
                style={{
                  margin: "0 7px",
                  position: "relative",
                  width: messageToEdit ? 48 : 64,
                  height: "100%",
                  alignSelf: "flex-end",
                  alignItems: "center",
                }}
              >
                <div style={{ position: "absolute", bottom: 4 }}>
                  {messageToEdit ? (
                    <div className="flex flex-row items-center gap-x-2">
                      <CloseOutlined
                        style={{ fontSize: 18 }}
                        className="pointer -mt-3 text-red-500"
                        onClick={() => setMessageToEdit(null)}
                      />
                      <CheckOutlined
                        className="pointer -mt-3"
                        disabled={disabled}
                        style={{
                          color: disabled ? "gray" : "#1890ff",
                          fontSize: 18,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          updateMessageContent(inputValue);
                        }}
                      />
                    </div>
                  ) : (
                    <Dropdown.Button disabled={disabled} size="small" menu={menuProps} onClick={() => handleSubmit()}>
                      <SendOutlined
                        className="pointer"
                        style={{
                          color: disabled ? "gray" : "#1890ff",
                          fontSize: 18,
                        }}
                      />
                    </Dropdown.Button>
                  )}
                </div>
              </div>
            )}
          </div>

          {!audioRecordMode && <FilesList />}
        </div>
      </div>
    );
  },
);

ChatInput.displayName = "ChatInput";

export default memo(ChatInput);
