import React, { useEffect, useState, type FunctionComponent } from "react";
import { MinusCircleOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Checkbox, Form, Input, Radio, Select, Space, Switch, Tabs, Typography } from "antd";
import round from "lodash.round";
import { Trans, useTranslation } from "react-i18next";

import { productAutomationActions } from "@fitness-app/app-store";
import { type ProductInvoiceConfig, type ProductTaxSettings } from "@fitness-app/data-models/entities/Automation";
import { ProductChargeType, ProductType } from "@fitness-app/data-models/entities/Product";
import { DEFAULT_INCOME_FLAT_TAX_RATES, DEFAULT_VAT_RATES } from "@fitness-app/utils/src/taxes/taxRates";

import { useEntityChange } from "~/hooks/useEntityChange";
import i18next from "~/i18nextConfig";
import { useMetaConversion } from "~/modules/Products/Product/ProductSettings/hoooks/useMetaConversion";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";

interface OwnProps {
  productId: string;
}

type Props = OwnProps;

interface AutomationSettingsForm {
  successUrl: string | null;
  showPromotionCodesField: CouponCodeField;
  invoiceAsOption: boolean;
  disableEmailNotificationAboutPurchase: boolean;
  disableEmailNotificationAboutPurchaseToOwner: boolean;
  invoiceConfig: ProductInvoiceConfig;
  facebookPixelId: string | null;
  googleAnalyticsId: string | null;
  googleAnalyticsGtmId: string | null;
  hideCountryField: boolean;
  showTermsPP: boolean;
  termsUrl: string | null;
  privacyPolicyUrl: string | null;
  claim: string | null;
  showAdditionalInfoField: AdditionalInfoField;
  showPhoneField: PhoneNumberFieldValues;
  metaConversionIntegrationId: string | null;
}

enum CouponCodeField {
  Hidden = "hidden",
  ShowForAll = "showForAll",
  ShowOnlyForSubscription = "showOnlyForSubscription",
}

enum AdditionalInfoField {
  Hidden = "hidden",
  ShowOptional = "showOptional",
  ShowRequired = "showRequired",
}

enum PhoneNumberFieldValues {
  Hidden = "hidden",
  ShowOptional = "showOptional",
  ShowRequired = "showRequired",
}

export const flatTaxRates = DEFAULT_INCOME_FLAT_TAX_RATES.map((rate) => ({
  value: rate,
  label: `${round(rate * 100, 1)}%`,
}));

export const VAT_RATES = [
  {
    value: DEFAULT_VAT_RATES[0],
    label: i18next.t("common:vatRate.zw"),
  },
  {
    value: DEFAULT_VAT_RATES[1],
    label: i18next.t("common:vatRate.np"),
  },
  ...DEFAULT_VAT_RATES.slice(2).map((rate) => ({
    value: rate,
    label: `${rate}%`,
  })),
];

const ProductSettings: FunctionComponent<Props> = ({ productId }) => {
  const { t } = useTranslation(["products", "common"]);
  const [formController] = Form.useForm<AutomationSettingsForm>();
  const dispatch = useAppDispatch();
  const productDetails = useAppSelector((store) => store.product.details);
  const productAutomation = useAppSelector((store) => store.productAutomation.data);
  const { data } = useAppSelector((state) => state.productsSettings);
  const [showFacebookPixelRemovedWarning, setShowFacebookPixelRemovedWarning] = useState<boolean>(false);
  const [loading, onSuccess, onFailure, onStart] = useEntityChange();
  const metaConversionIntegrationId = Form.useWatch("metaConversionIntegrationId", formController);
  const invoiceAsOption = Form.useWatch(["invoiceAsOption"], formController);
  const vatRate = Form.useWatch(
    ["invoiceConfig", "taxSettings", "vatRate"],
    formController,
  ) as ProductTaxSettings["vatRate"];
  const showTermsPP = Form.useWatch("showTermsPP", formController);
  const { conversionsListOptions } = useMetaConversion();

  const cartData = data?.checkoutSettings?.cart;

  useEffect(() => {
    if (showTermsPP) {
      formController?.setFieldsValue({
        termsUrl: cartData?.pl?.termsUrl ?? null,
        privacyPolicyUrl: cartData?.pl?.privacyPolicyUrl ?? null,
        claim: cartData?.pl?.claim ?? null,
      });
    }
  }, [showTermsPP, formController, cartData?.pl?.termsUrl, cartData?.pl?.privacyPolicyUrl, cartData?.pl?.claim]);

  const onSubmit = (data: AutomationSettingsForm) => {
    onStart();
    if (data.invoiceConfig) {
      data.invoiceConfig = {
        flatTaxRate: data.invoiceConfig.flatTaxRate || null,
        itemName: data.invoiceConfig.itemName || null,
      };
    }

    const { showPromotionCodesField, showAdditionalInfoField, showPhoneField, ...rest } = data;

    void dispatch(
      productAutomationActions.updateProductAutomationSettings({
        payload: {
          productId,
          settings: {
            ...rest,
            showPromotionCodesField: showPromotionCodesField === CouponCodeField.ShowForAll,
            showPromotionCodesFieldOnlyForSubscription:
              showPromotionCodesField === CouponCodeField.ShowOnlyForSubscription,
            showAdditionalInfoOptionalField: showAdditionalInfoField === AdditionalInfoField.ShowOptional,
            showAdditionalInfoRequiredField: showAdditionalInfoField === AdditionalInfoField.ShowRequired,
            termsUrl: showTermsPP ? data.termsUrl ?? null : null,
            privacyPolicyUrl: showTermsPP ? data.privacyPolicyUrl ?? null : null,
            claim: showTermsPP ? data.claim ?? null : null,
            showPhoneOptionalField: showPhoneField === PhoneNumberFieldValues.ShowOptional,
            showPhoneField: null, // it's added to distinguish old saved settings from new one
            showPhoneRequiredField: showPhoneField === PhoneNumberFieldValues.ShowRequired,
          },
        },
        onSuccess,
        onFailure,
      }),
    );
  };

  useEffect(() => {
    if (productAutomation?.settings) {
      const showPromotionCodesField = productAutomation.settings?.showPromotionCodesFieldOnlyForSubscription
        ? CouponCodeField.ShowOnlyForSubscription
        : productAutomation.settings?.showPromotionCodesField
        ? CouponCodeField.ShowForAll
        : CouponCodeField.Hidden;

      const showAdditionalInfoField = productAutomation.settings?.showAdditionalInfoRequiredField
        ? AdditionalInfoField.ShowRequired
        : productAutomation.settings?.showAdditionalInfoOptionalField
        ? AdditionalInfoField.ShowOptional
        : AdditionalInfoField.Hidden;
      const showTerms =
        Boolean(productAutomation?.settings?.termsUrl) ||
        Boolean(productAutomation?.settings?.privacyPolicyUrl) ||
        Boolean(productAutomation?.settings?.claim);

      const showPhoneField =
        productAutomation?.settings?.showPhoneOptionalField || productAutomation?.settings?.showPhoneField === true
          ? PhoneNumberFieldValues.ShowOptional
          : productAutomation?.settings?.showPhoneRequiredField
          ? PhoneNumberFieldValues.ShowRequired
          : PhoneNumberFieldValues.Hidden;

      formController?.setFieldsValue({
        ...productAutomation.settings,
        showPromotionCodesField,
        showAdditionalInfoField,
        showPhoneField,
        showTermsPP: showTerms,
      });
    }
  }, [productAutomation]);

  const handleChangeMetaConversionIntegrationId = (newValue: string) => {
    if (newValue && newValue !== metaConversionIntegrationId) {
      formController?.setFieldValue("facebookPixelId", null);
      setShowFacebookPixelRemovedWarning(true);
    }
  };

  if (!productDetails) {
    return null;
  }

  const hiddenField = [ProductType.ONLY_CONTENT, ProductType.ADDON].includes(productDetails.type);

  return (
    <Card
      extra={
        <Space>
          <Button loading={!!loading} icon={<SaveOutlined />} type="primary" onClick={() => formController?.submit()}>
            {t<string>("common:button.save")}
          </Button>
        </Space>
      }
    >
      <Form<AutomationSettingsForm>
        name="active-campaign-form"
        layout="vertical"
        form={formController}
        onFinish={onSubmit}
        validateTrigger={["onSubmit"]}
        initialValues={{
          successUrl: "",
          showPromotionCodesField: CouponCodeField.Hidden,
          showPhoneField: PhoneNumberFieldValues.Hidden,
          showAdditionalInfoField: AdditionalInfoField.Hidden,
          showTermsPP: false,
          termsUrl: null,
          privacyPolicyUrl: null,
          claim: null,
          allowDowngradeSubscriptionPlan: false,
          invoiceAsOption: false,
          disableEmailNotificationAboutPurchase: false,
          allowUpgradeSubscriptionPlan: false,
          issueReceiptWhenInvoiceIsOptIn: false,
          allowDowngradeSubscriptionPlanDesc: false,
          disableEmailNotificationAboutPurchaseToOwner: false,
          googleAnalyticsId: null,
          facebookPixelId: null,
          metaConversionIntegrationId: null,
          invoiceConfig: {
            flatTaxRate: null,
            itemName: null,
          },
          cart: {
            features: [],
            preSaleUrl: "",
            customButtonLabel: "",
            hidePriceWhenPreSaleLinkExists: false,
            useTPayBlik: false,
          },
        }}
      >
        <Tabs tabPosition="left">
          <Tabs.TabPane key="afterPurchase" forceRender tab={t<string>("productAutomation.afterPurchase")}>
            <Card bordered={false}>
              <Form.Item
                name="successUrl"
                label={t(
                  productDetails?.chargeType === ProductChargeType.FREE
                    ? "productAutomation.successUrlFree"
                    : "productAutomation.successUrl",
                )}
                tooltip={t("productAutomation.successUrlTooltip")}
                rules={[
                  {
                    type: "url",
                    message: t<string>("common:validationErrors.wrongUrl"),
                  },
                ]}
              >
                <Input autoFocus placeholder={t<string>("productAutomation.successUrlPlaceholder")} />
              </Form.Item>
            </Card>
          </Tabs.TabPane>
          <Tabs.TabPane key="emailNotifications" forceRender tab={t<string>("productAutomation.emailNotifications")}>
            <Card bordered={false}>
              <Form.Item
                name="disableEmailNotificationAboutPurchase"
                valuePropName="checked"
                label={t("productAutomation.emailNotifications")}
              >
                <Checkbox>
                  <Trans t={t} i18nKey="productAutomation.disableEmailNotificationAboutPurchase" />
                </Checkbox>
              </Form.Item>

              {productAutomation?.settings?.disableEmailNotificationAboutPurchase && (
                <Alert
                  type="warning"
                  message={t("productAutomation.disableEmailNotificationAboutPurchaseInfo")}
                  style={{ margin: "-10px 0 20px" }}
                />
              )}

              <Form.Item name="disableEmailNotificationAboutPurchaseToOwner" valuePropName="checked">
                <Checkbox>
                  <Trans t={t} i18nKey="productAutomation.disableEmailNotificationAboutPurchaseToOwner" />
                </Checkbox>
              </Form.Item>
            </Card>
          </Tabs.TabPane>

          {productDetails?.chargeType !== ProductChargeType.FREE && (
            <>
              <Tabs.TabPane key="promotionCodes" forceRender tab={t<string>("productAutomation.promotionCodes")}>
                <Card bordered={false}>
                  <Form.Item
                    name="showPromotionCodesField"
                    label={
                      <Typography.Title level={5} style={{ marginBottom: 0 }}>
                        {t("productAutomation.promotionCodes")}
                      </Typography.Title>
                    }
                    tooltip={t("productAutomation.showPromotionCodesTooltip")}
                  >
                    <Radio.Group
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        marginTop: "0.5rem",
                      }}
                    >
                      <Radio value={CouponCodeField.Hidden}>{t("productAutomation.hidePromotionCodesField")}</Radio>
                      <Radio value={CouponCodeField.ShowForAll}>{t("productAutomation.showPromotionCodesField")}</Radio>
                      <Radio value={CouponCodeField.ShowOnlyForSubscription}>
                        {t("productAutomation.showPromotionCodesOnlyForSubscriptionField")}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Card>
              </Tabs.TabPane>
              <Tabs.TabPane key="invoices" forceRender tab={t<string>("productAutomation.invoices")}>
                <Card bordered={false}>
                  <Form.Item
                    name="invoiceAsOption"
                    valuePropName="checked"
                    label={<Typography.Title level={5}>{t("productAutomation.invoices")}</Typography.Title>}
                  >
                    <Checkbox>{t("productAutomation.invoiceAsOptionField")}</Checkbox>
                  </Form.Item>

                  <Alert message={t("productAutomation.invoiceAsOptionInfo")} style={{ margin: "-10px 0 20px" }} />

                  {invoiceAsOption && (
                    <Form.Item name="issueReceiptWhenInvoiceIsOptIn" valuePropName="checked">
                      <Checkbox>{t("productAutomation.issueReceiptWhenInvoiceIsOptIn")}</Checkbox>
                    </Form.Item>
                  )}

                  <Form.Item name={["invoiceConfig", "itemName"]} label={t("productAutomation.invoiceConfigItemName")}>
                    <Input placeholder={t<string>("productAutomation.invoiceConfigItemNamePlaceholder")} />
                  </Form.Item>

                  <Form.Item name={["invoiceConfig", "flatTaxRate"]} label={t("productAutomation.flatTaxRate")}>
                    <Select
                      options={flatTaxRates}
                      allowClear
                      placeholder={t("productAutomation.flatTaxRatePlaceholder")}
                    />
                  </Form.Item>

                  <Form.Item
                    name={["invoiceConfig", "taxSettings", "vatRate"]}
                    label={t("productAutomation.productVatRate")}
                  >
                    <Select
                      options={VAT_RATES}
                      allowClear
                      placeholder={t("productAutomation.productVatRatePlaceholder")}
                    />
                  </Form.Item>

                  {vatRate && typeof vatRate === "string" ? (
                    <Form.Item
                      name={["invoiceConfig", "taxSettings", "legalVatExemption"]}
                      label={t("productAutomation.legalVatExemption")}
                      rules={[
                        {
                          required: vatRate === "zw",
                          message: t<string>("common:validationErrors.fieldIsRequired"),
                        },
                        {
                          max: 50,
                          message: t<string>("common:validationErrors.maxLength", {
                            number: 50,
                          }),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  ) : null}
                </Card>
              </Tabs.TabPane>
              <Tabs.TabPane
                key="analyticsInCheckout"
                forceRender
                tab={t<string>("productAutomation.analyticsInCheckout")}
              >
                <Card bordered={false}>
                  <Form.Item name="googleAnalyticsGtmId" label={t<string>("productAutomation.googleAnalyticsGtmId")}>
                    <Input placeholder={t<string>("productAutomation.googleAnalyticsGtmIdPlaceholder")} />
                  </Form.Item>

                  <Form.Item name="googleAnalyticsId" label={t("productAutomation.googleAnalyticsId")}>
                    <Input placeholder={t<string>("productAutomation.googleAnalyticsIdPlaceholder")} />
                  </Form.Item>

                  <Form.Item name="facebookPixelId" label={t("productAutomation.facebookPixelId")}>
                    <Input placeholder={t<string>("productAutomation.facebookPixelIdPlaceholder")} />
                  </Form.Item>

                  {showFacebookPixelRemovedWarning && (
                    <Form.Item>
                      <Alert type="warning" message={t<string>("productAutomation.facebookPixelIdRemovedWarning")} />
                    </Form.Item>
                  )}
                  <Form.Item
                    name="metaConversionIntegrationId"
                    label={t<string>("productAutomation.metaConversionIntegration")}
                  >
                    <Select
                      options={conversionsListOptions}
                      placeholder={t<string>("productAutomation.metaConversionIntegrationPlaceholder")}
                      allowClear
                      onChange={handleChangeMetaConversionIntegrationId}
                      onClear={() => setShowFacebookPixelRemovedWarning(false)}
                    />
                  </Form.Item>
                </Card>
              </Tabs.TabPane>
              <Tabs.TabPane key="subscription" forceRender tab={t<string>("productAutomation.subscription")}>
                <Card bordered={false}>
                  <Form.Item name="allowUpgradeSubscriptionPlan" valuePropName="checked" noStyle>
                    <Checkbox>{t("productAutomation.allowUpgradeSubscriptionPlan")}</Checkbox>
                  </Form.Item>

                  <Alert className="my-4" message={t("productAutomation.allowUpgradeSubscriptionPlanDesc")} />

                  <Form.Item name="allowDowngradeSubscriptionPlan" valuePropName="checked" noStyle>
                    <Checkbox>{t("productAutomation.allowDowngradeSubscriptionPlan")}</Checkbox>
                  </Form.Item>
                </Card>
              </Tabs.TabPane>
              <Tabs.TabPane key="checkout" forceRender tab={t<string>("productAutomation.checkout")}>
                <Card bordered={false}>
                  <Typography.Title level={5}>{t("productAutomation.checkoutSettings")}</Typography.Title>

                  <Form.Item label={t("productAutomation.cartFeatures")} hidden={hiddenField}>
                    <Form.List name={["cart", "features"]} initialValue={[""]}>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field) => (
                            <div
                              key={field.key}
                              style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "baseline",
                              }}
                            >
                              <Form.Item
                                {...field}
                                name={[field.name]}
                                key={field.key}
                                rules={[
                                  {
                                    required: true,
                                    message: t<string>("common:validationErrors.fieldIsRequired"),
                                  },
                                ]}
                                style={{ width: "90%" }}
                              >
                                <Input placeholder={t<string>("productAutomation.cartFeaturesPlaceholder")} />
                              </Form.Item>
                              <MinusCircleOutlined onClick={() => remove(field.name)} style={{ marginLeft: 12 }} />
                            </div>
                          ))}
                          {fields.length < 5 && (
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                                style={{ marginTop: 10 }}
                              >
                                {t("common:button.add")}
                              </Button>
                            </Form.Item>
                          )}
                        </>
                      )}
                    </Form.List>
                  </Form.Item>

                  <Form.Item
                    hidden={hiddenField}
                    name={["cart", "preSaleUrl"]}
                    label={t("productAutomation.preSaleUrl")}
                    rules={[
                      {
                        type: "url",
                        message: t<string>("common:validationErrors.wrongUrl"),
                      },
                    ]}
                  >
                    <Input placeholder={t<string>("productAutomation.preSaleUrlPlaceholder")} />
                  </Form.Item>

                  <Form.Item
                    hidden={hiddenField}
                    name={["cart", "hidePriceWhenPreSaleLinkExists"]}
                    label={t("productAutomation.hidePriceWhenPreSaleLinkExists")}
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>

                  <Form.Item
                    hidden={hiddenField}
                    name={["cart", "customButtonLabel"]}
                    label={t("productAutomation.customButtonLabel")}
                  >
                    <Input placeholder={t<string>("productAutomation.customButtonLabelPlaceholder")} />
                  </Form.Item>

                  <Form.Item
                    name="showTermsPP"
                    label={t<string>("productAutomation.customTerms")}
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                  {showTermsPP && (
                    <>
                      <Form.Item
                        name="termsUrl"
                        label={t<string>("settings:checkout.termsUrl")}
                        rules={[
                          {
                            type: "url",
                            message: t<string>("common:validationErrors.wrongUrl"),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="privacyPolicyUrl"
                        label={t<string>("settings:checkout.privacyPolicyUrl")}
                        rules={[
                          {
                            type: "url",
                            message: t<string>("common:validationErrors.wrongUrl"),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item name="claim" label={t<string>("settings:checkout.claim")}>
                        <Input.TextArea rows={8} />
                      </Form.Item>
                    </>
                  )}
                  <Typography.Title level={5}>{t("productAutomation.countries")}</Typography.Title>
                  <Form.Item name="hideCountryField" valuePropName="checked">
                    <Checkbox>{t<string>("productAutomation.hideCountryField")}</Checkbox>
                  </Form.Item>
                  <Alert message={t<string>("productAutomation.hideCountryInfo")} style={{ margin: "-10px 0 20px" }} />
                  <Typography.Title level={5}>{t("productAutomation.phones")}</Typography.Title>
                  <Form.Item name="showPhoneField">
                    <Radio.Group style={{ flexDirection: "column", display: "flex", marginTop: "0.5rem" }}>
                      <Radio value={PhoneNumberFieldValues.Hidden}>{t("productAutomation.hidePhoneField")}</Radio>
                      <Radio value={PhoneNumberFieldValues.ShowOptional}>
                        {t("productAutomation.showPhoneOptionalField")}
                      </Radio>
                      <Radio value={PhoneNumberFieldValues.ShowRequired}>
                        {t("productAutomation.showPhoneRequiredField")}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Alert message={t<string>("productAutomation.showPhoneInfo")} style={{ margin: "-10px 0 20px" }} />
                  <Typography.Title level={5}>{t("productAutomation.additionalInfo")}</Typography.Title>
                  <Form.Item name="showAdditionalInfoField">
                    <Radio.Group style={{ flexDirection: "column", display: "flex", marginTop: "0.5rem" }}>
                      <Radio value={AdditionalInfoField.Hidden}>{t("productAutomation.hideAdditionalInfoField")}</Radio>
                      <Radio value={AdditionalInfoField.ShowOptional}>
                        {t("productAutomation.showAdditionalInfoOptionalField")}
                      </Radio>
                      <Radio value={AdditionalInfoField.ShowRequired}>
                        {t("productAutomation.showAdditionalInfoRequiredField")}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Alert
                    message={t<string>("productAutomation.showAdditionalInfoTooltip")}
                    style={{ margin: "-10px 0 20px" }}
                  />

                  <Typography.Title level={5}>{t("productAutomation.payments")}</Typography.Title>

                  <Form.Item
                    name={["cart", "useTPayBlik"]}
                    label={t("productAutomation.useTPayBlik")}
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Card>
              </Tabs.TabPane>
            </>
          )}
        </Tabs>
      </Form>
    </Card>
  );
};

export default ProductSettings;
