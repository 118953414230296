import React, { useEffect, useState, type FunctionComponent } from "react";
import { PlayCircleOutlined } from "@ant-design/icons";
import { Avatar, Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Switch } from "antd";
import { type FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { automationTemplatesActions, mediaLibraryActions, teamActions } from "@fitness-app/app-store";
import { MediaContentType } from "@fitness-app/data-models/entities/Campaign";
import {
  MediaLibraryResourceType,
  type MediaLibraryItemWithLinkedExercise,
} from "@fitness-app/data-models/entities/MediaLibrary";
import { getUserInitials } from "@fitness-app/utils";
import { createScheduleOptions } from "@fitness-app/utils/src/surveys/createInitialSurvey";

import { DaysSummary } from "~/components/DaysSummary/DaysSummary";
import ImageUploadField from "~/components/ImageUploadField/ImageUploadField";
import VideoPlayer from "~/components/VideoPlayer/VideoPlayer";
import { useAuthorOptions } from "~/hooks/trainer/useAuthorOptions";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { useGeneratedDaysForMessages } from "~/modules/Automation/AutomatedMessagesForm/useGeneratedDaysForMessages";
import { useCampaignsList } from "~/modules/Campaigns/hooks/useCampaignsList";
import { createMediaLibraryVideoOptions } from "~/modules/TrainingPrograms/tabs/ExercisesDatabase/ExerciseForm/types";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import {
  contentTypeOptions,
  visualContentTypeOptions,
  weekDayLabels,
  type CampaignMessageComposeFormModel,
  type WeekdayLabels,
} from "./types";

interface OwnProps {
  formController?: FormInstance<CampaignMessageComposeFormModel>;
  onSubmit: (formData: CampaignMessageComposeFormModel) => void;
  model?: Partial<CampaignMessageComposeFormModel>;
}

type Props = OwnProps;

export const DISABLED_HOURS = [0, 1, 2, 3, 4, 5, 6, 23];

const validatePickerTime = () => DISABLED_HOURS;

export const dayOfWeekAsInteger = (day: WeekdayLabels) => weekDayLabels.indexOf(day);

const ComposeCampaignForm: FunctionComponent<Props> = ({ formController, onSubmit, model }) => {
  const { t } = useTranslation(["dashboard", "common", "products", "campaigns"]);
  const sentDate = Form.useWatch("sentDate", formController);
  const numberOfRepeats = Form.useWatch("numberOfRepeats", formController);
  const daysOfDelivery = Form.useWatch("daysOfDelivery", formController);
  const sentTime = Form.useWatch("sentTime", formController);
  const sendImmediately = Form.useWatch("sendImmediately", formController);
  const dispatch = useAppDispatch();
  const authorOptions = useAuthorOptions(model, true, false);
  const { userId } = useUserRole();
  const selectedContentType = Form.useWatch("contentType", formController);
  const selectedVideo = Form.useWatch(["message", "videoId"], formController);
  const { list } = useAppSelector((store) => store.mediaLibrary);
  const [video, setVideo] = useState<MediaLibraryItemWithLinkedExercise | null>(null);
  const { data } = useCampaignsList();

  const today = dayjs();

  useEffect(() => {
    dispatch(mediaLibraryActions.subscribeToMediaLibrary(MediaLibraryResourceType.Marketing));
  }, [dispatch]);

  useEffect(() => {
    void dispatch(automationTemplatesActions.fetchAutomationTemplates());
    void dispatch(teamActions.fetchTeamMembers());
  }, []);

  useEffect(() => {
    if (model) {
      formController?.setFieldsValue(model);
    }
  }, [model]);

  useEffect(() => {
    if (sentDate) {
      formController?.setFieldsValue({
        daysOfDelivery: [weekDayLabels[sentDate.get("day")] || "monday"],
      });
    }
  }, [sentDate]);

  const generatedDaysOfDelivery = useGeneratedDaysForMessages({
    sentDate,
    daysOfDelivery,
    numberOfRepeats,
  });

  const handleSubmit = (formData: CampaignMessageComposeFormModel) => {
    const foundAuthorData = authorOptions.find((author) => author.value === formData.authorId);
    if (!foundAuthorData) {
      onSubmit(formData);

      return;
    }
    onSubmit({
      ...formData,
      authorRole: foundAuthorData.role,
      authorName: foundAuthorData.label,
      authorAvatar: foundAuthorData.avatarUrl || null,
    });
  };

  const renderVideoField = () => {
    if (selectedContentType === MediaContentType.Youtube) {
      return (
        <Form.Item
          name={["message", "videoLink"]}
          label={t<string>("campaigns:popup.form.videoUrl")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
        >
          <Input />
        </Form.Item>
      );
    }
    if (selectedContentType === MediaContentType.Video) {
      return (
        <Form.Item
          name={["message", "videoId"]}
          label={t<string>("campaigns:popup.form.fileFromLibrary")}
          rules={[
            {
              required: true,
              message: t<string>("common:validationErrors.fieldIsRequired"),
            },
          ]}
          help={
            selectedVideo ? (
              <Button
                icon={<PlayCircleOutlined />}
                onClick={() => setVideo(list.find((item) => item.id === selectedVideo) || null)}
                className="my-2"
              >
                {t("common:button.preview")}
              </Button>
            ) : null
          }
        >
          <Select
            showSearch
            options={createMediaLibraryVideoOptions(list)}
            filterOption={(inputValue, option) => option?.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1}
            placeholder={t("workouts:exerciseForm.selectFromList")}
          />
        </Form.Item>
      );
    }

    if (selectedContentType === MediaContentType.Image) {
      return (
        <Form.Item
          name={["message", "image"]}
          label={t<string>("products:form.images")}
          valuePropName="fileList"
          getValueFromEvent={(e: unknown) => e}
        >
          <ImageUploadField withCrop={false} storageRef={`${userId}/campaigns`} multiple={false} />
        </Form.Item>
      );
    }

    return null;
  };

  const hydrateForm = (templateName: string) => {
    const template = data?.find((template) => template.id === templateName);
    if (template) {
      let fieldsValues: Partial<CampaignMessageComposeFormModel> = {};

      if (template.data.contentType === MediaContentType.Video) {
        fieldsValues = {
          ...fieldsValues,
          contentType: MediaContentType.Video,
          visualType: template.data.visualType || "sheet",
          message: {
            title: template.data.message.title,
            description: template.data.message.description,
            ctaLink: template.data.message.ctaLink,
            ctaText: template.data.message.ctaText,
            videoId: template.data.message.videoId,
            pushDescription: template.data.message.pushDescription || "",
            pushTitle: template.data.message.pushTitle || "",
          },
        };
      }

      if (template.data.contentType === MediaContentType.Image) {
        fieldsValues = {
          ...fieldsValues,
          visualType: template.data.visualType || "sheet",
          contentType: MediaContentType.Image,
          message: {
            title: template.data.message.title,
            description: template.data.message.description,
            ctaLink: template.data.message.ctaLink,
            ctaText: template.data.message.ctaText,
            image: [template.data.message.image],
            pushDescription: template.data.message.pushDescription || "",
            pushTitle: template.data.message.pushTitle || "",
          },
        };
      }

      if (template.data.contentType === MediaContentType.Youtube) {
        fieldsValues = {
          ...fieldsValues,
          visualType: template.data.visualType || "sheet",
          contentType: MediaContentType.Youtube,
          message: {
            title: template.data.message.title,
            description: template.data.message.description,
            ctaLink: template.data.message.ctaLink,
            ctaText: template.data.message.ctaText,
            videoLink: template.data.message.videoLink,
            pushDescription: template.data.message.pushDescription || "",
            pushTitle: template.data.message.pushTitle || "",
          },
        };
      }

      if (template.data.contentType === MediaContentType.None) {
        fieldsValues = {
          ...fieldsValues,
          visualType: template.data.visualType || "sheet",
          contentType: MediaContentType.None,
          message: {
            title: template.data.message.title,
            description: template.data.message.description,
            ctaLink: template.data.message.ctaLink,
            ctaText: template.data.message.ctaText,
            pushDescription: template.data.message.pushDescription || "",
            pushTitle: template.data.message.pushTitle || "",
          },
        };
      }

      formController?.setFieldsValue(fieldsValues);
    }
  };

  return (
    <Form<CampaignMessageComposeFormModel>
      name="message-compose-form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      form={formController}
      onFinish={handleSubmit}
      initialValues={{
        saveAsTemplate: false,
        name: "",
        numberOfRepeats: 1,
        sendImmediately: true,
        contentType: MediaContentType.None,
        authorId: userId,
        visualType: "sheet",
      }}
    >
      {data?.length ? (
        <Form.Item name="template" label={t("form.searchInTemplates")}>
          <Select
            showSearch
            allowClear
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={(inputValue, option) => {
              if (option?.children) {
                // @ts-expect-error ignore
                return (option.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
              }
              return false;
            }}
            onSelect={(value: string) => hydrateForm(value)}
          >
            {data.map((template) => (
              <Select.Option key={template.id} value={template.id}>
                {template.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ) : null}

      <Form.Item
        name="authorId"
        label={t("automatedMessageForm.messageAuthor")}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Select disabled placeholder={t("automatedMessageForm.messageAuthorPlaceholder")} style={{ maxWidth: 250 }}>
          {authorOptions.map((author) => (
            <Select.Option value={author.value} label={author.label} key={author.value}>
              <Space direction="horizontal">
                <Avatar src={author.avatarUrl} size={26}>
                  {getUserInitials(author.label)}
                </Avatar>{" "}
                {author.label}
              </Space>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="sendImmediately" label={t<string>("form.isSendRightNow")} valuePropName="checked">
        <Switch />
      </Form.Item>

      {!sendImmediately && (
        <>
          <Form.Item
            name="sentTime"
            label={t("automatedMessageForm.sentTime")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <DatePicker.TimePicker
              minuteStep={15}
              allowClear={false}
              format="HH:mm"
              disabledHours={validatePickerTime}
            />
          </Form.Item>

          <Form.Item
            name="sentDate"
            label={t("automatedMessageForm.sentDate")}
            rules={[
              {
                required: true,
                message: t<string>("common:validationErrors.fieldIsRequired"),
              },
            ]}
          >
            <DatePicker format="DD.MM.YYYY" disabledDate={(current) => dayjs(current).isBefore(today, "day")} />
          </Form.Item>
          <Form.Item name="daysOfDelivery" label={t("automatedMessageForm.dayOfDelivery")}>
            <Checkbox.Group>
              <Row>
                {createScheduleOptions((item) => t(`${item}`)).map((item) => (
                  <Col span={8} key={item.value}>
                    <Checkbox
                      value={item.value}
                      style={{ lineHeight: "32px" }}
                      disabled={sentDate?.get("day") === dayOfWeekAsInteger(item.value)}
                    >
                      {item.label}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item name="numberOfRepeats" label={t("automatedMessageForm.numberOfRepeats")}>
            <InputNumber min={1} max={10} />
          </Form.Item>
        </>
      )}

      <Form.Item
        label={t("campaigns:popup.form.name")}
        name="name"
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input placeholder={t<string>("campaigns:popup.form.namePlaceholder")} style={{ maxWidth: 700 }} />
      </Form.Item>

      <Form.Item
        label={t("campaigns:popup.form.title")}
        tooltip={t("automatedMessageForm.dynamicValue")}
        name={["message", "title"]}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input placeholder={t<string>("campaigns:popup.form.titlePlaceholder")} style={{ maxWidth: 700 }} />
      </Form.Item>

      <Form.Item
        label={t("campaigns:popup.form.description")}
        tooltip={t("automatedMessageForm.dynamicValue")}
        name={["message", "description"]}
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
      >
        <Input.TextArea rows={2} placeholder={t<string>("campaigns:popup.form.descriptionPlaceholder")} />
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
          // { type: "url", message: t<string>("common:validationErrors.wrongUrlFormat") },
        ]}
        label={t("campaigns:popup.form.ctaLink")}
        name={["message", "ctaLink"]}
      >
        <Input placeholder={t<string>("campaigns:popup.form.ctaLinkPlaceholder")} style={{ maxWidth: 700 }} />
      </Form.Item>

      <Form.Item
        rules={[
          {
            required: true,
            message: t<string>("common:validationErrors.fieldIsRequired"),
          },
        ]}
        label={t("campaigns:popup.form.ctaText")}
        name={["message", "ctaText"]}
      >
        <Input placeholder={t<string>("campaigns:popup.form.ctaTextPlaceholder")} style={{ maxWidth: 700 }} />
      </Form.Item>

      <Form.Item
        label={t("campaigns:popup.form.pushTitle")}
        tooltip={t("automatedMessageForm.dynamicValue")}
        name={["message", "pushTitle"]}
      >
        <Input
          placeholder={t<string>("campaigns:popup.form.pushTitlePlaceholder")}
          style={{ maxWidth: 700 }}
          count={{
            max: 30,
            show: true,
          }}
        />
      </Form.Item>

      <Form.Item
        label={t("campaigns:popup.form.pushDescription")}
        tooltip={t("automatedMessageForm.dynamicValue")}
        name={["message", "pushDescription"]}
      >
        <Input
          count={{
            max: 130,
            show: true,
          }}
          placeholder={t<string>("campaigns:popup.form.pushDescriptionPlaceholder")}
          style={{ maxWidth: 700 }}
        />
      </Form.Item>

      <Form.Item name="contentType" label={t<string>("campaigns:popup.form.contentType")}>
        <Select options={contentTypeOptions} placeholder={t("workouts:exerciseForm.selectFromList")} />
      </Form.Item>

      {renderVideoField()}

      <Form.Item name={["visualType"]} label={t<string>("campaigns:popup.form.visualType")}>
        <Select options={visualContentTypeOptions} placeholder={t("workouts:exerciseForm.selectFromList")} />
      </Form.Item>

      {generatedDaysOfDelivery.length ? (
        <DaysSummary generatedDates={generatedDaysOfDelivery} selectedTime={sentTime} />
      ) : null}

      <VideoPlayer
        hideControl
        videoUrl={video?.data.hlsUrl || video?.data.mp4Url || null}
        visible={!!video}
        onClose={() => setVideo(null)}
      />
    </Form>
  );
};

export default ComposeCampaignForm;
