import React, { useMemo } from "react";
import { CheckOutlined } from "@ant-design/icons";
import { Badge, Button, Space, Table, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { type AssignedTrainerWithId } from "@fitness-app/data-models/entities/TeamMember";
import {
  type TraineeStatus,
  type TraineeWithAssignedTrainer,
  type TraineeWithAssignedTrainerAndProduct,
} from "@fitness-app/data-models/entities/Trainee";
import { getAuthorName } from "@fitness-app/data-models/utils/getAuthorName";

import TraineeStatusBadge from "~/modules/Trainee/components/TraineeStatusBadge/TraineeStatusBadge";

interface TrainerTaskProps {
  clients: TraineeWithAssignedTrainer[];
  trainersById?: Record<string, AssignedTrainerWithId>;
}

export const TrainerTasks = ({ clients, trainersById }: TrainerTaskProps): React.ReactElement => {
  const { t } = useTranslation("trainees");
  const navigate = useNavigate();

  const trainerOptions = useMemo(
    () =>
      trainersById
        ? Object.values(trainersById).map((assignedTrainer) => ({
            value: assignedTrainer.assignedTrainerId,
            text: `${assignedTrainer.trainer.firstName} ${assignedTrainer.trainer.lastName}`,
          }))
        : [],
    [trainersById, t],
  );

  return (
    <Table<TraineeWithAssignedTrainer>
      dataSource={clients}
      rowKey="id"
      size="middle"
      scroll={{ x: true }}
      pagination={false}
      locale={{
        emptyText: t("emptyState"),
      }}
    >
      <Table.Column<TraineeWithAssignedTrainer>
        title={t("tableHeader.index")}
        width={50}
        dataIndex="index"
        key="index"
        render={(_name, _row, i) => i + 1}
      />
      <Table.Column<TraineeWithAssignedTrainer>
        title={t("tableHeader.name")}
        dataIndex="name"
        key="name"
        render={(_, row) => (
          <Button type="link" className="px-0" onClick={() => navigate(`/trainee/details/${row.id}`)}>
            {row.firstName} {row.lastName}
          </Button>
        )}
      />
      <Table.Column<TraineeWithAssignedTrainer>
        title={t("tableHeader.email")}
        dataIndex="email"
        ellipsis
        render={(email) => (
          <Typography.Link href={`mailto:${email}`} target="_blank" copyable>
            {email}
          </Typography.Link>
        )}
      />
      <Table.Column<TraineeWithAssignedTrainer>
        title={t("tableHeader.status")}
        dataIndex="status"
        key="status"
        render={(status: TraineeStatus) => <TraineeStatusBadge status={status} />}
      />
      <Table.Column<TraineeWithAssignedTrainerAndProduct>
        title={t("tableHeader.workouts")}
        dataIndex="activeTrainingProgramId"
        key="activeTrainingProgramId"
        render={(activeTrainingProgramId, row) => {
          if (activeTrainingProgramId && row.hasActiveTrainingProgram) {
            return <CheckOutlined style={{ color: "#25b10f" }} />;
          }

          if (!activeTrainingProgramId) {
            return <Badge status="error" text="Brak dodanego planu" />;
          }

          return <Badge status="error" text="Plan nieudostępniony" />;
        }}
      />
      <Table.Column<TraineeWithAssignedTrainerAndProduct>
        title={t("tableHeader.diet")}
        dataIndex="activeMealsPlanId"
        key="activeMealsPlanId"
        render={(activeMealsPlanId, row) => {
          if (activeMealsPlanId && row.hasActiveNutrition) {
            return <CheckOutlined style={{ color: "#25b10f" }} />;
          }

          if (!activeMealsPlanId) {
            return <Badge status="error" text="Brak dodanej diety" />;
          }

          return <Badge status="error" text="Dieta nieudostępniona" />;
        }}
      />
      {trainersById && (
        <Table.Column<TraineeWithAssignedTrainerAndProduct>
          title={t("tableHeader.trainer")}
          dataIndex="assignedTrainerId"
          key="assignedTrainerId"
          onFilter={(value, record) => {
            if (typeof value === "string") {
              return record.assignedTrainerId === value;
            }

            return true;
          }}
          filters={trainerOptions}
          filterSearch
          filterMultiple={false}
          render={(assignedTrainerId: string) => {
            const assignedTrainer = trainersById[assignedTrainerId];
            return (
              <Space>
                {assignedTrainer ? <Tag color="magenta">{getAuthorName(assignedTrainer.trainer)}</Tag> : "-"}
              </Space>
            );
          }}
        />
      )}
    </Table>
  );
};
