import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { type MediaLibraryItem } from "@fitness-app/data-models/entities/MediaLibrary";

import { RequestStatus } from "../../../enums/requestStatus";
import { convertFileToMp3 } from "./actions";
import { fetchMediaLibraryFiles } from "./actions/fetchMediaLibraryFiles";
import { MEDIA_LIBRARY_REDUCER_NAME, type MediaLibraryReducer } from "./types";

const initialState: MediaLibraryReducer = {
  listStatus: null,
  list: [],
  processingFileId: null,
  fileStatus: null,
};

const reducerSlice = createSlice({
  initialState,
  name: MEDIA_LIBRARY_REDUCER_NAME,
  reducers: {
    subscribeToMediaLibraryPending(state) {
      state.listStatus = RequestStatus.SUBSCRIBING;
    },
    subscribeToMediaLibrarySuccess(state) {
      state.listStatus = RequestStatus.SUBSCRIBED;
    },
    subscribeToMediaLibraryFailed(state) {
      state.listStatus = RequestStatus.FAILED;
    },
    unsubscribeFromMediaLibrary(state) {
      state.list = [];
      state.listStatus = null;
    },
    updateFileOnList(state, { payload }: PayloadAction<Partial<MediaLibraryItem>>) {
      state.list = state.list.map((file) => {
        if (file.id === payload.id) {
          return {
            ...file,
            ...payload,
          };
        }

        return file;
      });
    },
    deleteFileFromList(state, { payload }: PayloadAction<string>) {
      state.list = state.list.filter((file) => file.id !== payload);
    },
    addFileToList(state, { payload }: PayloadAction<MediaLibraryItem>) {
      state.list = [{ ...payload, exercises: [] }, ...state.list];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMediaLibraryFiles.fulfilled, (state, { payload }) => {
      state.list = payload;
    });
    builder.addCase(convertFileToMp3.pending, (state, { meta }) => {
      state.processingFileId = meta.arg.id;
    });
    builder.addCase(convertFileToMp3.rejected, (state) => {
      state.processingFileId = null;
    });
    builder.addCase(convertFileToMp3.fulfilled, (state) => {
      state.processingFileId = null;
    });
  },
});

export const {
  subscribeToMediaLibraryFailed,
  subscribeToMediaLibrarySuccess,
  unsubscribeFromMediaLibrary,
  subscribeToMediaLibraryPending,
  updateFileOnList,
  addFileToList,
  deleteFileFromList,
} = reducerSlice.actions;
export default reducerSlice.reducer;
