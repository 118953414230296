import React, { forwardRef, useImperativeHandle, useState } from "react";
import { DatePicker, Form, Popconfirm } from "antd";
import dayjs, { type Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

interface FormModel {
  sentDate: Dayjs;
  sentTime: Dayjs;
}

export const DISABLED_HOURS = [0, 1, 2, 3, 4, 5, 6, 23];

const validatePickerTime = () => DISABLED_HOURS;

export interface ScheduleMessageFormRef {
  openForm: () => void;
}

interface Props {
  onSubmit: (day: Dayjs) => Promise<"success" | "failure">;
}

export const ScheduleMessageForm = forwardRef<ScheduleMessageFormRef, Props>(
  ({ onSubmit }, ref): React.ReactElement => {
    const today = dayjs();
    const { t } = useTranslation(["dashboard", "common", "products"]);
    const [formController] = Form.useForm<FormModel>();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      openForm: () => {
        if (!open) {
          setOpen(true);
        }
      },
    }));

    const handleSubmit = async (values: FormModel) => {
      const date = values.sentDate
        .set("hour", values.sentTime.get("hour"))
        .set("minute", values.sentTime.get("minute"));
      setConfirmLoading(true);
      const result = await onSubmit(date);
      setConfirmLoading(false);
      if (result === "success") {
        setOpen(false);
      }
    };

    const onCancel = (e?: React.MouseEvent<HTMLElement>) => {
      e?.stopPropagation();
      setOpen(false);
      formController.resetFields();
    };

    return (
      <Popconfirm
        destroyTooltipOnHide
        trigger="click"
        placement="leftTop"
        open={open}
        showArrow={false}
        overlayStyle={{
          width: 500,
        }}
        okButtonProps={{ loading: confirmLoading }}
        onConfirm={() => formController.submit()}
        onCancel={onCancel}
        okText="Zaplanuj wiadomość"
        description={
          <div className="flex flex-col gap-3">
            <Form
              name="message-compose-form"
              layout="horizontal"
              onFinish={handleSubmit}
              form={formController}
              initialValues={{
                sentDate: today,
                sentTime: today.add(1, "hour").set("minute", 0),
              }}
            >
              <Form.Item
                name="sentTime"
                label={t("automatedMessageForm.sentTime")}
                rules={[
                  {
                    required: true,
                    message: t<string>("common:validationErrors.fieldIsRequired"),
                  },
                ]}
              >
                <DatePicker.TimePicker
                  minuteStep={15}
                  allowClear={false}
                  format="HH:mm"
                  disabledHours={validatePickerTime}
                  style={{ minWidth: 200 }}
                />
              </Form.Item>

              <Form.Item
                name="sentDate"
                label={t("automatedMessageForm.sentDate")}
                rules={[
                  {
                    required: true,
                    message: t<string>("common:validationErrors.fieldIsRequired"),
                  },
                ]}
              >
                <DatePicker format="DD.MM.YYYY" disabledDate={(current) => dayjs(current).isBefore(today, "day")} />
              </Form.Item>
            </Form>
          </div>
        }
        title="Zaplanuj czas wysyłki wiadomości"
      >
        <span>Ustaw czas wysyłki</span>
      </Popconfirm>
    );
  },
);

ScheduleMessageForm.displayName = "ScheduleMessageForm";
