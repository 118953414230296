import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { type ClientNutritionDay } from "@fitness-app/data-models/entities/ClientNutritionDay";

import { type AsyncThunkCreator } from "../../../index";
import { TRAINEE_NUTRITION_TRACKER_REDUCER_NAME } from "../types";

type Payload = {
  traineeId: string;
  date: string;
};

export const fetchNutritionTrackedWeek = createAsyncThunk<ClientNutritionDay[], Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_NUTRITION_TRACKER_REDUCER_NAME}/fetchNutritionTrackedWeek`,
  async ({ traineeId, date }, { rejectWithValue, extra: { db } }) => {
    const selectedDay = dayjs(date);
    let week = `${selectedDay.format("YYYY")}-${selectedDay.isoWeek()}`;

    const lastMonthIndex = 11;

    if (selectedDay.get("month") === lastMonthIndex && selectedDay.isoWeek() === 1) {
      // end of year edge case
      week = `${selectedDay.add(1, "year").format("YYYY")}-${selectedDay.isoWeek()}`;
    }

    const { error, data } = await db
      .from("client_nutrition_day")
      .select("*")
      .order("eventDate")
      .eq("traineeId", traineeId)
      .eq("eventWeek", week)
      .returns<ClientNutritionDay[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
