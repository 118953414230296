import React, { useEffect, useMemo, useState } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
  type DragOverEvent,
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { Button, Checkbox, Col, Form, Input, InputNumber, List, Radio, Row, Select, Spin, Switch } from "antd";
import { type FormInstance } from "antd/lib/form";
import omit from "lodash.omit";
import round from "lodash.round";
import uniqBy from "lodash.uniqby";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import { v4 as uuid } from "uuid";

import { nutritionActions } from "@fitness-app/app-store";
import { getParentTrainerId } from "@fitness-app/app-store/src/store/reducers/user/selectors";
import {
  dishSizeTypes,
  dishTags,
  DishType,
  KitchenType,
  MealType,
  SensitiveIngredient,
  type DishIngredient,
} from "@fitness-app/data-models/entities/Dish";
import { type Ingredient } from "@fitness-app/data-models/entities/Ingredient";
import { calculateMeasureMultiplier } from "@fitness-app/utils/src/nutrition/calculateMeasureMultiplier";
import { calculateForPortion, countCaloriesByIngredients } from "@fitness-app/utils/src/nutrition/countNutrients";
import { findPreferableMeasure } from "@fitness-app/utils/src/nutrition/findPreferableMeasure";
import { sumNutrients } from "@fitness-app/utils/src/nutrition/sumNutrients";

import { coordinateGetter } from "~/components/Dnd/multipleContainersKeyboardCoordinates";
import ImageUploadField from "~/components/ImageUploadField/ImageUploadField";
import DishIngredientItem from "~/modules/Nutrition/DishForm/DishIngredientItem";
import { useNutritionMeasures } from "~/modules/Nutrition/hooks/useNutritionMeasures";
import { type DishDetailsForm } from "~/modules/Nutrition/MealsPlans/MealsPlanDetails/tabs/MealsPlanSchedule/MealsProductProvider";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import { type DishFormModel } from "./types";

interface DishFormProps {
  formController?: FormInstance<DishFormModel>;
  onSubmit: (formData: DishFormModel, rest: { portionSize: number; calories: number }) => void;
  model?: Partial<DishFormModel> | null;
}

const DishForm = ({ model, onSubmit, formController }: DishFormProps) => {
  if (!formController) {
    throw new Error("FormController is required");
  }

  const { t } = useTranslation(["nutrition", "common"]);
  const { measuresMap } = useNutritionMeasures();
  const parentId = useAppSelector(getParentTrainerId);
  const ingredients = Form.useWatch("ingredients", formController) || model?.ingredients || [];
  const portions = Form.useWatch("portions", formController) || 1;
  const portionCount = 1;
  const needCooking = Form.useWatch("needsCooking", formController) ?? false;
  const [fetching, setFetching] = useState(false);
  const dispatch = useAppDispatch();
  const [options, setOptions] = useState<{ value: string; label: string; product: Ingredient }[]>([]);
  const [selectValue, setSelectValue] = useState<string | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    }),
  );

  useEffect(() => {
    if (model) {
      // @ts-expect-error ignore
      formController.setFieldsValue(model);
    }
  }, [model]);

  const onIngredientSearch = useDebouncedCallback(async (value: string) => {
    setOptions([]);
    setFetching(true);

    const result = await dispatch(nutritionActions.searchIngredient({ searchTerm: value })).unwrap();

    setFetching(false);
    setOptions(
      result.data.map((product) => ({
        label: `${product.name} ${product.brand ? `- ${product.brand}` : ""} - ${product.nutrients.calories} kcal`,
        value: product.id,
        product,
      })),
    );
  }, 300);

  const onAddIngredient = (
    selected: { value: string },
    add: (defaultValue?: any, insertIndex?: number | undefined) => void,
  ) => {
    setSelectValue(null);
    const ingredient = options.find((option) => option.value === selected.value);

    if (ingredient) {
      const measure = ingredient.product.measures[0];

      const preferableMeasure = findPreferableMeasure(ingredient.product) || measure;
      const portion = ingredient.product.packageSize || preferableMeasure?.weightPerUnit || 100;
      const ingredientInDish: DishIngredient & { multiplier: number } = {
        ...(omit(ingredient.product, ["kcalmar", "_formatted"]) as Ingredient),
        ingredientId: ingredient.product.id,
        calories: preferableMeasure?.energyPerUnit || 0,
        id: uuid(),
        portion,
        hint: null,
        measure: preferableMeasure?.name || "",
        measureId: preferableMeasure?.id || -1,
        measureWeight: preferableMeasure?.weightPerUnit || 0,
        measureCapacity: null,
        multiplier: 1,
        measures: uniqBy(ingredient.product.measures, "id"),
        portionCalories: round((ingredient.product.nutrients.calories * portion) / 100, 1),
      };

      add(ingredientInDish);
    }
  };

  const protein = calculateForPortion(sumNutrients(ingredients, "protein"), {
    portionCount,
    portions,
  });
  const carbs = calculateForPortion(sumNutrients(ingredients, "carbohydrates"), {
    portionCount,
    portions,
  });
  const fat = calculateForPortion(sumNutrients(ingredients, "fat"), {
    portionCount,
    portions,
  });

  const portionSize = ingredients.reduce((prev, current) => prev + current.portion, 0);

  const calories = useMemo(() => {
    return countCaloriesByIngredients(ingredients);
  }, [ingredients]);

  const handleDragEnd = (event: DragOverEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = ingredients.findIndex(({ id }) => id === active.id);
      const newIndex = ingredients.findIndex(({ id }) => id === over.id);
      const newFields = arrayMove(ingredients, oldIndex, newIndex);

      formController.setFieldValue("ingredients", newFields);
    }
  };

  const onMeasureSelect = (ingredient: DishIngredient, measureId: number, index: number) => {
    const result = calculateMeasureMultiplier(ingredient, measuresMap, measureId);
    if (result.measureId === 1) {
      formController.setFieldValue(["ingredients", index, "multiplier"], 1);
    } else {
      formController.setFieldValue(["ingredients", index, "multiplier"], 1);
      formController.setFieldValue(["ingredients", index, "portion"], result.weightPerUnit);
    }
  };

  const handleSubmit = (formData: DishFormModel) => {
    onSubmit(formData, { portionSize, calories });
  };

  return (
    <Form<DishFormModel>
      name="form"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      form={formController}
      initialValues={{
        preparationSteps: [""],
        needsCooking: false,
        needsReheating: false,
        prepTime: 15,
        size: 2,
        kitchenTypes: [],
        dishTypes: [],
        tags: [],
        mealTypes: [],
        image: null,
        portions: 1,
      }}
      onFinish={handleSubmit}
    >
      <Form.Item
        tooltip={t<string>("ingredientForm.imageAdditionalInfo")}
        name="image"
        label={t<string>("ingredientForm.image")}
        valuePropName="fileList"
        getValueFromEvent={(e: unknown) => e}
      >
        <ImageUploadField
          storageRef={`${parentId}/dishes`}
          multiple={false}
          transformOptions={{
            width: 300,
            height: 300,
            resize: "cover",
          }}
        />
      </Form.Item>

      <Form.Item
        name="name"
        label={t<string>("dish.name")}
        rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
      >
        <Input autoFocus />
      </Form.Item>

      <Form.Item
        name="prepTime"
        label={t<string>("dish.prepTime")}
        rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
      >
        <InputNumber min={0} max={1000} step={1} addonAfter="minut" className="max-w-[150px]" />
      </Form.Item>

      <Form.Item name="needsCooking" label={t<string>("dish.needsCooking")} valuePropName="checked">
        <Switch />
      </Form.Item>

      {needCooking && (
        <Form.Item name="cookTime" label={t<string>("dish.cookTime")}>
          <InputNumber min={0} max={1000} step={1} addonAfter="minut" className="max-w-[150px]" />
        </Form.Item>
      )}

      <Form.Item name="needsReheating" label={t<string>("dish.needsReheating")} valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item
        name="portions"
        label={t<string>("dish.portions")}
        rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
      >
        <InputNumber min={1} max={100} step={1} addonAfter="porcji" className="max-w-[150px]" />
      </Form.Item>

      <Form.Item label={t<string>("dish.ingredients")}>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragOver={handleDragEnd}>
          <SortableContext items={ingredients.map(({ id }) => id)}>
            <Form.List
              name="ingredients"
              rules={[
                {
                  validator: async (_, levels: string[]) => {
                    if (!levels || levels.length < 1) {
                      return Promise.reject(new Error("At least 1 level"));
                    }
                  },
                },
              ]}
            >
              {(fields, { remove, add }) => {
                return (
                  <div>
                    {fields.map((field, index) => {
                      const ingredient = ingredients[index];

                      if (!ingredient) {
                        return null;
                      }

                      return (
                        <DishIngredientItem
                          key={field.key}
                          ingredient={ingredient}
                          fieldKey={field.key}
                          index={index}
                          fieldName={field.name}
                          onRemove={remove}
                          onMeasureSelect={(value) => onMeasureSelect(ingredient, value, index)}
                          formController={formController as FormInstance<DishFormModel | DishDetailsForm>}
                        />
                      );
                    })}
                    <Form.Item label="Dodaj składnik" labelCol={{ span: 24 }}>
                      <Select
                        labelInValue
                        filterOption={false}
                        placeholder="Wyszukaj składnik..."
                        showSearch
                        onSearch={onIngredientSearch}
                        notFoundContent={fetching ? <Spin size="small" /> : null}
                        className="mb-4"
                        style={{ maxWidth: 390 }}
                        optionLabelProp="label"
                        value={selectValue}
                        onSelect={(value) => onAddIngredient(value as unknown as { value: string }, add)}
                      >
                        {options.map((option) => (
                          <Select.Option value={option.value} label={option.label} key={option.value}>
                            <List.Item className="group relative cursor-pointer">
                              <List.Item.Meta
                                title={`${option.product.name} ${
                                  option.product.brand ? `- ${option.product.brand}` : ""
                                }`}
                                description={
                                  <div className="flex justify-between pt-2 font-light">
                                    <dd className="text-xs text-gray-700">
                                      B: {option.product.nutrients.protein} g | W:{" "}
                                      {option.product.nutrients.carbohydrates} g | T: {option.product.nutrients.fat} g
                                    </dd>
                                    <dd className="text-xs text-gray-700">
                                      {option.product.nutrients.calories} kcal ({option.product.packageSize || "100"}g)
                                    </dd>
                                  </div>
                                }
                              />
                            </List.Item>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>
          </SortableContext>
        </DndContext>
        <div className="flex flex-col justify-between gap-4 ">
          <dd className="flex gap-4 text-lg font-medium">
            <span>{round(calories ?? 0, 1)} kcal</span>
            <span>/</span>
            <span>{portionSize} g</span>
          </dd>
          <dd className="flex gap-2 text-sm text-gray-700">
            <span>
              {t("nutrients.protein")}: {protein} g
            </span>
            <span>|</span>
            <span>
              {t("nutrients.carbohydrates")}: {carbs} g
            </span>
            <span>|</span>
            <span>
              {t("nutrients.fat")}: {fat} g
            </span>
          </dd>
        </div>
      </Form.Item>

      <Form.Item label={t<string>("dish.instruction")}>
        <Form.List name="preparationSteps">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field) => (
                  <Form.Item
                    {...field}
                    key={field.key}
                    rules={[
                      {
                        required: true,
                        message: t<string>("common:validationErrors.fieldIsRequired"),
                      },
                    ]}
                  >
                    <Input
                      addonAfter={
                        <Button
                          size="small"
                          type="text"
                          icon={<DeleteOutlined className="text-red-500" />}
                          onClick={() => remove(field.key)}
                        />
                      }
                    />
                  </Form.Item>
                ))}
                <Button shape="circle" size="small" icon={<PlusOutlined />} onClick={() => add("")}></Button>
              </div>
            );
          }}
        </Form.List>
      </Form.Item>

      <Form.Item name="size" label={t("dish.size")}>
        <Radio.Group>
          <Row>
            {dishSizeTypes.map((sizeNumber) => (
              <Col span={8} key={sizeNumber}>
                <Radio value={sizeNumber} style={{ lineHeight: "32px" }}>
                  {t(`dishSizeType.${sizeNumber}`)}
                </Radio>
              </Col>
            ))}
          </Row>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="mealTypes"
        label={t("dish.mealType")}
        rules={[{ required: true, message: t<string>("common:validationErrors.fieldIsRequired") }]}
      >
        <Checkbox.Group>
          <Row>
            {Object.values(MealType).map((mealType) => (
              <Col span={8} key={mealType}>
                <Checkbox value={mealType} style={{ lineHeight: "32px" }}>
                  {t(`mealType.${mealType}`)}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item name="dishTypes" label={t("dish.dishType")}>
        <Select
          mode="multiple"
          options={Object.values(DishType).map((dishType) => ({ value: dishType, label: t(`dishType.${dishType}`) }))}
        />
      </Form.Item>

      <Form.Item name="kitchenTypes" label={t("dish.kitchenType")}>
        <Select
          mode="multiple"
          options={Object.values(KitchenType).map((kitchenType) => ({
            value: kitchenType,
            label: t(`kitchenType.${kitchenType}`),
          }))}
        />
      </Form.Item>

      <Form.Item name="includes" label={t("dish.sensitiveIngredients")}>
        <Select
          mode="multiple"
          options={Object.values(SensitiveIngredient).map((tag) => ({
            value: tag,
            label: t(`sensitiveIngredient.${tag}`),
          }))}
        />
      </Form.Item>

      <Form.Item name="tags" label={t("dish.tags")}>
        <Select
          mode="multiple"
          options={dishTags.map((tag) => ({
            value: tag,
            label: t(`dishTags.${tag}`),
          }))}
        />
      </Form.Item>
    </Form>
  );
};

export default DishForm;
