import { useEffect, type FunctionComponent } from "react";
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  ExportOutlined,
  FlagOutlined,
  InfoCircleOutlined,
  MessageOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Grid,
  Popover,
  Progress,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  type TableProps,
} from "antd";
import dayjs from "dayjs";
import isEqual from "lodash.isequal";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { chatActions, RequestStatus, traineesActions } from "@fitness-app/app-store";
import { getTrainersTeam } from "@fitness-app/app-store/src/store/reducers/team/selectors";
import {
  ClientActivityFlag,
  type ClientActivityWithScoreAndTrainer,
  type WeekActivities,
} from "@fitness-app/data-models/entities/ClientActivity";
import { getAuthorName } from "@fitness-app/data-models/utils/getAuthorName";

import { useUserClaims } from "~/hooks/trainer/useUserClaims";
import { useUserRole } from "~/hooks/trainer/useUserRole";
import { DATE_TIME_FORMAT } from "~/modules/Trainee/constants";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import { createTrainerOptions } from "./helpers/createTrainerOptions";

interface OwnProps {
  isSearchMode: boolean;
}

type Props = OwnProps;

export const flagColor = {
  [ClientActivityFlag.Idle]: "rgba(224, 224, 226, 0.8)",
  [ClientActivityFlag.Danger]: "#ED1C24",
  [ClientActivityFlag.Warning]: "#faad14",
  [ClientActivityFlag.Safe]: "#52c41a",
  [ClientActivityFlag.VerySafe]: "#52c41a",
};

export const WorkoutWeekProgress = ({
  weekOfActivities,
  label,
}: {
  weekOfActivities: WeekActivities;
  label: string;
}) => {
  if (!weekOfActivities) {
    return null;
  }

  const total = weekOfActivities.activitiesScheduled ?? weekOfActivities.activitiesCompleted;

  return (
    <Space direction="vertical">
      <Typography.Text type="secondary" strong>
        {label}
      </Typography.Text>
      <Progress
        type="circle"
        percent={Math.round((weekOfActivities.activitiesCompleted / total) * 100)}
        width={60}
        format={() => `${weekOfActivities.activitiesCompleted} / ${total}`}
      />
    </Space>
  );
};

const TraineesActivityTable: FunctionComponent<Props> = ({ isSearchMode }) => {
  const { t } = useTranslation("trainees");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { xl } = Grid.useBreakpoint();

  const { isRegularTrainer, memberId, isOwner } = useUserClaims();
  const {
    activitiesMetadata: {
      page,
      totalPages,
      status: listStatus,
      listSize,
      currentAssignedTrainerFilter,
      fetchingNextPage,
    },
    traineesActivity: list,
  } = useAppSelector((store) => store.trainees);
  const trainers = useAppSelector(getTrainersTeam);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isAdministration } = useUserRole();
  const fetchingExtraChannel = useAppSelector((store) => store.chat.channelsListStatus.fetchingExtraChannel);

  useEffect(() => {
    const page = searchParams.get("page");

    void dispatch(
      traineesActions.fetchTraineesActivity(
        memberId && !isAdministration
          ? { assignedTrainerId: memberId, page: page ? Number(page) : undefined }
          : { page: page ? Number(page) : undefined },
      ),
    );
  }, [dispatch, memberId, isAdministration]);

  const handleChange: TableProps<ClientActivityWithScoreAndTrainer>["onChange"] = (pagination, filters) => {
    if (isRegularTrainer) {
      if (pagination.current && pagination.current !== page) {
        setSearchParams({ page: String(pagination.current || 1) });
        void dispatch(
          traineesActions.fetchTraineesActivity(
            memberId ? { assignedTrainerId: memberId, page: pagination.current } : { page: pagination.current },
          ),
        );
      }
    } else {
      if (!isEqual(currentAssignedTrainerFilter ? [currentAssignedTrainerFilter] : null, filters.assignedTrainer)) {
        const newTrainerFilter = filters.assignedTrainer?.[0] || null;
        void dispatch(
          traineesActions.fetchTraineesActivity({
            assignedTrainerId: (newTrainerFilter as string) || null,
            page: 1,
          }),
        );
      } else {
        if (pagination.current && pagination.current !== page) {
          setSearchParams({ page: String(pagination.current || 1) });
          void dispatch(
            traineesActions.fetchTraineesActivity(
              memberId ? { assignedTrainerId: memberId, page: pagination.current } : { page: pagination.current },
            ),
          );
        }
      }
    }
  };

  return (
    <>
      <Table<ClientActivityWithScoreAndTrainer>
        dataSource={list}
        rowKey="id"
        size="middle"
        scroll={{ x: true }}
        locale={{
          emptyText:
            isSearchMode || currentAssignedTrainerFilter !== undefined ? t("emptyStateForFilter") : t("emptyState"),
        }}
        loading={listStatus === RequestStatus.FETCHING || listStatus === RequestStatus.UPDATING || fetchingNextPage}
        pagination={{
          current: page,
          pageSize: listSize,
          total: totalPages * listSize,
          pageSizeOptions: ["50"],
          showSizeChanger: false,
        }}
        onChange={handleChange}
      >
        <Table.Column<ClientActivityWithScoreAndTrainer>
          title={t("tableHeader.index")}
          width={50}
          dataIndex="index"
          key="index"
          render={(_name, _row, i) => (page > 1 ? (page - 1) * listSize + i + 1 : i + 1)}
        />
        <Table.Column<ClientActivityWithScoreAndTrainer>
          title={t("tableHeader.name")}
          dataIndex="trainee"
          key="trainee"
          render={(_, row) => (
            <Space direction="horizontal" size={0}>
              <Button type="link" className="px-0" onClick={() => navigate(`/trainee/details/${row.trainee.id}`)}>
                {row.trainee?.firstName} {row.trainee?.lastName}
              </Button>
              {row.trainee.userId && (
                <Button
                  icon={<MessageOutlined />}
                  style={{ paddingLeft: 2, paddingRight: 2 }}
                  title="Wyślij wiadomość"
                  onClick={() =>
                    row.trainee.userId
                      ? dispatch(chatActions.findAndAddChannelToList({ userId: row.trainee.userId }))
                      : null
                  }
                  type="link"
                  loading={fetchingExtraChannel}
                />
              )}
            </Space>
          )}
        />
        <Table.Column<ClientActivityWithScoreAndTrainer>
          title={t("tableHeader.email")}
          dataIndex="trainee"
          key="email"
          ellipsis
          render={(trainee: ClientActivityWithScoreAndTrainer["trainee"]) => (
            <Typography.Link href={`mailto:${trainee?.email}`} target="_blank" copyable>
              {trainee?.email}
            </Typography.Link>
          )}
        />
        <Table.Column<ClientActivityWithScoreAndTrainer>
          title={t("tableHeader.workouts")}
          dataIndex="activeTrainingProgramId"
          key="activeTrainingProgramId"
          render={(_, row) =>
            row.trainee.hasActiveTrainingProgram && row.trainee.activeTrainingProgramId ? (
              <CheckOutlined style={{ color: "#25b10f" }} />
            ) : (
              <CloseOutlined type="close" style={{ color: "#ED1C24" }} />
            )
          }
        />

        {xl && (
          <>
            <Table.Column<ClientActivityWithScoreAndTrainer>
              title="Klient dodany"
              dataIndex="clientCreatedAt"
              key="clientCreatedAt"
              render={(_clientCreatedAt, row) =>
                row.trainee?.createdAt ? dayjs(row.trainee?.createdAt).format("DD.MM.YYYY") : "-"
              }
            />
            <Table.Column<ClientActivityWithScoreAndTrainer>
              title={
                <Space size={4}>
                  <span>Ostatnia aktywnosc</span>{" "}
                  <Tooltip title={t("dashboard:clientsActivity.lastActionAt")}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Space>
              }
              dataIndex="lastActionAt"
              key="lastActionAt"
              render={(lastActionAt) => (lastActionAt ? dayjs.unix(lastActionAt).format(DATE_TIME_FORMAT) : "-")}
            />
          </>
        )}

        <Table.Column<ClientActivityWithScoreAndTrainer>
          title={
            <Space size={4}>
              <span>Aktywność treningowa</span>{" "}
              <Tooltip title={t("dashboard:clientsActivity.workoutsStatus")}>
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
          }
          dataIndex="workoutsStatus"
          key="workoutsStatus"
          render={(flag: ClientActivityFlag, row) => (
            <Popover
              content={
                <Space direction="vertical">
                  <Space>
                    <span>Ostatnia aktywność treningowa:</span>
                    <span>
                      {row.lastWorkoutActivityAt && (
                        <>
                          <ClockCircleOutlined />{" "}
                        </>
                      )}
                      <Typography.Text strong>
                        {row.lastWorkoutActivityAt
                          ? dayjs.unix(row.lastWorkoutActivityAt).format(DATE_TIME_FORMAT)
                          : "-"}
                      </Typography.Text>
                    </span>
                  </Space>

                  {row.activities && (
                    <Space wrap size={12}>
                      {typeof row.activities[3].activitiesScheduled === "number" ||
                      row.activities[3].activitiesCompleted ? (
                        <WorkoutWeekProgress label="3 tyg. temu" weekOfActivities={row.activities[3]} />
                      ) : null}
                      {typeof row.activities[2].activitiesScheduled === "number" ||
                      row.activities[2].activitiesCompleted ? (
                        <WorkoutWeekProgress label="2 tyg. temu" weekOfActivities={row.activities[2]} />
                      ) : null}

                      {typeof row.activities[1].activitiesScheduled === "number" ||
                      row.activities[1].activitiesCompleted ? (
                        <WorkoutWeekProgress label="Tydzień temu" weekOfActivities={row.activities[1]} />
                      ) : null}

                      {typeof row.activities[0].activitiesScheduled === "number" ||
                      row.activities[0].activitiesCompleted ? (
                        <WorkoutWeekProgress label="Obecny tydzień" weekOfActivities={row.activities[0]} />
                      ) : null}
                    </Space>
                  )}

                  <Divider style={{ margin: "5px 0" }} />
                  <Space direction="horizontal">
                    <span>Brak dodanego planu lub brak aktywności klienta:</span>
                    <FlagOutlined style={{ color: flagColor[ClientActivityFlag.Danger] }} />
                  </Space>
                  <Space direction="horizontal">
                    <span>W ostatnich 2 tygodniach klient wykonał mniej niż 90% planu treningowego:</span>
                    <FlagOutlined style={{ color: flagColor[ClientActivityFlag.Warning] }} />
                  </Space>
                  <Space direction="horizontal">
                    <span>W ostatnich 2 tygodniach klient wykonał wszystkie treningi</span>
                    <FlagOutlined style={{ color: flagColor[ClientActivityFlag.Safe] }} />
                  </Space>
                  <Space direction="horizontal">
                    <span>W ostatnich 2 tygodniach klient wykonał wszystkie treningi i dodatkowe aktywności</span>
                    <TrophyOutlined style={{ color: flagColor[ClientActivityFlag.VerySafe] }} />
                  </Space>
                </Space>
              }
              title="Status aktywności treningowej"
              trigger="hover"
            >
              {flag === ClientActivityFlag.VerySafe ? (
                <TrophyOutlined style={{ color: flagColor[flag] }} />
              ) : (
                <FlagOutlined style={{ color: flagColor[flag] }} />
              )}
            </Popover>
          )}
        />
        <Table.Column<ClientActivityWithScoreAndTrainer>
          title={
            <Space size={4}>
              <span>Komunikacja</span>{" "}
              <Tooltip title={t("dashboard:clientsActivity.communicationStatus")}>
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
          }
          dataIndex="communicationStatus"
          key="communicationStatus"
          render={(flag: ClientActivityFlag, row) => (
            <Popover
              content={
                <Space direction="vertical">
                  <Space>
                    <span>Ostatni wiadomość wysłana przez klienta:</span>
                    <span>
                      {row.lastMessageAt && (
                        <>
                          <ClockCircleOutlined />{" "}
                        </>
                      )}
                      <Typography.Text strong>
                        {row.lastMessageAt ? dayjs.unix(row.lastMessageAt).format(DATE_TIME_FORMAT) : "-"}
                      </Typography.Text>
                    </span>
                  </Space>

                  <Space>
                    <span>Ostatnie logowanie:</span>
                    <span>
                      {row.lastLoggedAt && (
                        <>
                          <ClockCircleOutlined />{" "}
                        </>
                      )}
                      <Typography.Text strong>
                        {row.lastLoggedAt ? dayjs.unix(row.lastLoggedAt).format(DATE_TIME_FORMAT) : "-"}
                      </Typography.Text>
                    </span>
                  </Space>

                  <Divider style={{ margin: "5px 0" }} />
                  <Space direction="horizontal">
                    <span>Brak logowania w ostatnich 7 dniach i brak wiadomości:</span>
                    <FlagOutlined style={{ color: flagColor[ClientActivityFlag.Danger] }} />
                  </Space>
                  <Space direction="horizontal">
                    <span>Brak wiadomości w ostatnich 7 dniach, ale klient logował się do aplikacji:</span>
                    <FlagOutlined style={{ color: flagColor[ClientActivityFlag.Warning] }} />
                  </Space>
                  <Space direction="horizontal">
                    <span>Wiadomość od klienta w ostatnich 7 dniach</span>
                    <FlagOutlined style={{ color: flagColor[ClientActivityFlag.Safe] }} />
                  </Space>
                  <Space direction="horizontal">
                    <span>Wiadomość od klienta w ostatnich 3 dniach</span>
                    <TrophyOutlined style={{ color: flagColor[ClientActivityFlag.VerySafe] }} />
                  </Space>
                </Space>
              }
              title="Status komunikacji"
              trigger="hover"
            >
              {flag === ClientActivityFlag.VerySafe ? (
                <TrophyOutlined style={{ color: flagColor[flag] }} />
              ) : (
                <FlagOutlined style={{ color: flagColor[flag] }} />
              )}
            </Popover>
          )}
        />
        <Table.Column<ClientActivityWithScoreAndTrainer>
          title={
            <Space size={4}>
              <span>Pomiary</span>{" "}
              <Tooltip title={t("dashboard:clientsActivity.measurementStatus")}>
                <InfoCircleOutlined />
              </Tooltip>
            </Space>
          }
          dataIndex="measurementStatus"
          key="measurementStatus"
          render={(flag: ClientActivityFlag, row) => (
            <Popover
              content={
                <Space direction="vertical">
                  <Space>
                    <span>Ostatni pomiar klienta:</span>
                    <span>
                      {row.lastMeasurementsAt && (
                        <>
                          <ClockCircleOutlined />{" "}
                        </>
                      )}
                      <Typography.Text strong>
                        {row.lastMeasurementsAt ? dayjs.unix(row.lastMeasurementsAt).format(DATE_TIME_FORMAT) : "-"}
                      </Typography.Text>
                    </span>
                  </Space>

                  <Divider style={{ margin: "5px 0" }} />
                  <Space direction="horizontal">
                    <span>Brak pomiarów w ostatnich 21 dniach:</span>
                    <FlagOutlined style={{ color: flagColor[ClientActivityFlag.Danger] }} />
                  </Space>
                  <Space direction="horizontal">
                    <span>Ostatni pomiar ponad 10 dni temu:</span>
                    <FlagOutlined style={{ color: flagColor[ClientActivityFlag.Warning] }} />
                  </Space>
                  <Space direction="horizontal">
                    <span>Ostatni pomiar dodany w ostatnich 10 dniach:</span>
                    <FlagOutlined style={{ color: flagColor[ClientActivityFlag.Safe] }} />
                  </Space>
                  <Space direction="horizontal">
                    <span>Ostatni pomiar dodany w ostatnich 7 dniach oraz dodano zdjęcie:</span>
                    <TrophyOutlined style={{ color: flagColor[ClientActivityFlag.Safe] }} />
                  </Space>
                </Space>
              }
              title="Status pomiarów"
              trigger="hover"
            >
              {flag === ClientActivityFlag.VerySafe ? (
                <TrophyOutlined style={{ color: flagColor[flag] }} />
              ) : (
                <FlagOutlined style={{ color: flagColor[flag] }} />
              )}
            </Popover>
          )}
        />
        <Table.Column<ClientActivityWithScoreAndTrainer>
          title={t("tableHeader.trainer")}
          dataIndex="trainee"
          key="assignedTrainer"
          render={(trainee: ClientActivityWithScoreAndTrainer["trainee"]) =>
            trainee.assignedTrainer ? <Tag color="magenta">{getAuthorName(trainee.assignedTrainer)}</Tag> : "-"
          }
          {...(isOwner || isAdministration
            ? {
                filterMultiple: false,
                filterSearch: true,
                filteredValue: currentAssignedTrainerFilter ? [currentAssignedTrainerFilter] : null,
                filters: createTrainerOptions(trainers, t, false),
              }
            : {})}
        />
        {/*<Table.Column<ClientActivityWithScoreAndTrainer>*/}
        {/*  title={t("tableHeader.tags")}*/}
        {/*  dataIndex="trainee"*/}
        {/*  key="tags"*/}
        {/*  render={(trainee: ClientActivityWithScoreAndTrainer["trainee"]) =>*/}
        {/*    trainee?.tags?.length ? (*/}
        {/*      <Space direction="vertical">*/}
        {/*        {uniq(trainee.tags).map((tag, i) => (*/}
        {/*          <Tag color="blue" key={`${tag}-${i}`}>*/}
        {/*            {tag}*/}
        {/*          </Tag>*/}
        {/*        ))}*/}
        {/*      </Space>*/}
        {/*    ) : (*/}
        {/*      "-"*/}
        {/*    )*/}
        {/*  }*/}
        {/*/>*/}
        <Table.Column<ClientActivityWithScoreAndTrainer>
          title={t("tableHeader.options")}
          dataIndex="action"
          key="action"
          render={(_, row) => (
            <Space size={4}>
              <Button type="link" className="px-0" onClick={() => navigate(`/trainee/details/${row.trainee?.id}`)}>
                {t(`common:details`)}
              </Button>
              <Button
                icon={<ExportOutlined />}
                title="Otwórz w nowy oknie"
                href={`${window.location.origin}/trainee/details/${row.trainee?.id}`}
                target="_blank"
                type="link"
              />
            </Space>
          )}
        />
      </Table>
    </>
  );
};

export default TraineesActivityTable;
