import { orderBy } from "lodash";
import keyBy from "lodash.keyby";
import { createSelector } from "reselect";

import { TeamMemberRole, TeamMemberStatus } from "@fitness-app/data-models";

import { type AppStore } from "../../..";

const getTrainerTeamData = (state: AppStore) => state.team;
const getUserData = (state: AppStore) => state.user.profile;
const getOwnerAssignedClients = (_state: AppStore): string[] => [];

export const getAssignedRegularTrainers = createSelector(
  getTrainerTeamData,
  (trainerTeam): Record<string, string[]> => {
    if (!trainerTeam.trainers?.length) {
      return {};
    }

    return Object.fromEntries(
      trainerTeam.trainers.map((trainer) => [
        trainer.id,
        trainer.assignedTrainers?.map((assigned) => assigned.trainer?.id)?.flat() || [],
      ]),
    );
  },
);

export const getTrainersTeam = createSelector(
  [getTrainerTeamData, getUserData, getOwnerAssignedClients],
  (trainerTeam, user, assignedClients) => {
    if (!user) {
      return [];
    }

    const teamTrainers = [
      ...orderBy(trainerTeam.trainers ?? [], (item) => item.assignedTrainees.length, "desc").sort((a, b) => {
        if (a.status === TeamMemberStatus.INACTIVE && b.status === TeamMemberStatus.ACTIVE) {
          return 1;
        }
        if (a.status === TeamMemberStatus.ACTIVE && b.status === TeamMemberStatus.INACTIVE) {
          return -1;
        }

        if (a.status === TeamMemberStatus.INVITED && b.status === TeamMemberStatus.INACTIVE) {
          return -1;
        }

        return 0;
      }),
    ];

    const { firstName, lastName, email, id } = user;

    teamTrainers.unshift({
      assignedTrainers: [],
      firstName: firstName ?? "",
      lastName: lastName ?? "",
      email: email ?? "",
      status: TeamMemberStatus.ACTIVE,
      selectable: true,
      role: TeamMemberRole.ACCOUNT_OWNER,
      userId: id,
      user: {
        firstName: firstName ?? "",
        lastName: lastName ?? "",
        avatarUrl: user.avatarUrl || "",
      },
      id,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      disabled: false,
      trainer: id,
      specializationTags: [],
      assignedTrainees: assignedClients.map((id) => ({ id })),
      variables: null,
      capabilities: {
        nutritionGenerator: true,
      },
    });

    return teamTrainers;
  },
);

export const getTrainersTeamById = createSelector(
  [getTrainerTeamData, getUserData, getOwnerAssignedClients],
  (trainerTeam, user, assignedClients) => {
    if (!user) {
      return {};
    }

    const teamTrainers = [...(trainerTeam.trainers ?? [])];

    const { firstName, lastName, email, id } = user;

    teamTrainers.unshift({
      assignedTrainers: [],
      firstName: firstName ?? "",
      lastName: lastName ?? "",
      email: email ?? "",
      status: TeamMemberStatus.ACTIVE,
      selectable: true,
      role: TeamMemberRole.ACCOUNT_OWNER,
      assignedTrainees: assignedClients.map((id) => ({ id })),
      userId: id,
      id,
      specializationTags: [],
      user: {
        firstName: firstName ?? "",
        lastName: lastName ?? "",
        avatarUrl: user.avatarUrl || "",
      },
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      disabled: false,
      trainer: id,
      variables: null,
      capabilities: {
        nutritionGenerator: true,
      },
    });

    return keyBy(teamTrainers, "id");
  },
);
