import { userSelectors } from "@fitness-app/app-store";
import { TeamMemberRole, UserRole } from "@fitness-app/data-models";

import { useAppSelector } from "~/store/initializeStore";

export const useUserRole = () => {
  const userRole = useAppSelector(userSelectors.getUserRole);
  const user = useAppSelector((state) => state.user.data);
  const profile = useAppSelector((state) => state.user.profile);
  const selectedMember = useAppSelector((state) => state.team.selectedMember);

  return {
    userId: user?.id || "",
    role: userRole,
    isTrainer: userRole === UserRole.TRAINER,
    isClient: userRole === UserRole.CLIENT,
    isTeamMember: userRole === UserRole.TEAM_MEMBER,
    isHeadCoach: selectedMember?.role === TeamMemberRole.SHARED_TRAINER,
    userName: profile ? `${profile.firstName} ${profile.lastName}` : "",
    userAvatar: profile?.avatarUrl || null,
    capabilities: selectedMember?.capabilities || {},
    isAdministration:
      selectedMember?.role === TeamMemberRole.SHARED_TRAINER && selectedMember?.capabilities?.onlyForVerification,
  };
};
