import React from "react";
import { Card, Space } from "antd";

import { traineesActions } from "@fitness-app/app-store";
import { type TraineeWithAssignedTrainer } from "@fitness-app/data-models/entities/Trainee";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import { ChatAlerts } from "~/modules/Dashboard/AppStats/ChatAlerts";
import { AssignedTrainerTasks } from "~/modules/Dashboard/TeamMemberDashboard/AssignedTrainerTasks";
import { TrainerTasks } from "~/modules/Dashboard/TeamMemberDashboard/TrainerTasks";
import { useAppDispatch } from "~/store/initializeStore";

export const TeamMemberDashboard = (): React.ReactElement => {
  const [clients, setClients] = React.useState<TraineeWithAssignedTrainer[]>([]);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const { isHeadCoach, isAdministration } = useUserRole();

  React.useEffect(() => {
    setLoading(true);
    void dispatch(traineesActions.fetchTraineesWithoutPlan())
      .unwrap()
      .then((trainees) => {
        setClients(trainees.list);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  return (
    <Space direction="vertical" size={24} className="w-full">
      <Card title="Lista zadań" loading={loading}>
        <TrainerTasks clients={clients} />
      </Card>

      {isHeadCoach && (
        <>
          {!isAdministration && <AssignedTrainerTasks />}
          <ChatAlerts isHeadCoach={isHeadCoach} />
        </>
      )}
    </Space>
  );
};
