import React, { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { Card } from "antd";
import keyBy from "lodash.keyby";

import { AppAccessType } from "@fitness-app/data-models/entities/Automation";
import { TraineeStatus, type TraineeWithAssignedTrainer } from "@fitness-app/data-models/entities/Trainee";

import { TrainerTasks } from "~/modules/Dashboard/TeamMemberDashboard/TrainerTasks";
import { supabase, useAppSelector } from "~/store/initializeStore";

const useAssignedTrainersTasks = () => {
  const assignedTrainers = useAppSelector((state) => state.team.assignedTrainers);

  const trainersIds = assignedTrainers.map((trainer) => trainer.assignedTrainerId);

  const query = useQuery({
    queryKey: ["assignedTrainersTask", ...trainersIds],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("trainee")
        .select(
          "id, userId, firstName, lastName, email, createdAt, status, type, hasActiveNutrition, hasActiveTrainingProgram, activeTrainingProgramId, activeMealsPlanId, assignedTrainerId",
        )
        .order("createdAt", { ascending: false })
        .eq("status", TraineeStatus.ACTIVE)
        .in("assignedTrainerId", trainersIds)
        .eq("type", AppAccessType.FullAccess)
        .or("hasActiveTrainingProgram.is.false,hasActiveNutrition.is.false")
        .returns<TraineeWithAssignedTrainer[]>();

      if (error) {
        throw new Error(error.message);
      }

      return data || [];
    },
  });

  const trainersById = useMemo(() => keyBy(assignedTrainers, "assignedTrainerId"), [assignedTrainers]);

  return {
    data: query.data,
    isLoading: query.isLoading,
    trainersById,
  };
};

export const AssignedTrainerTasks = (): React.ReactElement => {
  const { data, isLoading, trainersById } = useAssignedTrainersTasks();

  return (
    <Card title="Zadania twoich trenerów" loading={isLoading}>
      <TrainerTasks clients={data || []} trainersById={trainersById} />
    </Card>
  );
};
