import { createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import utc from "dayjs/plugin/utc";

import { WorkoutSource } from "@fitness-app/data-models/entities/ClientWorkout";
import { ActivityEventType, type WorkoutActivityEvent } from "@fitness-app/data-models/entities/ProgramActivity";
import { type Database } from "@fitness-app/supabase";
import { generateUniqId } from "@fitness-app/utils/src/helpers/generateUniqId";

import { type AsyncThunkCreator } from "../../../index";
import { addActivity, setWorkoutEditModalHidden, updateActivity } from "../reducer";
import { TRAINEE_ACTIVITIES_REDUCER_NAME } from "../types";

type Payload = { isMobile: boolean } | undefined;

dayjs.extend(isSameOrAfter);
dayjs.extend(utc);

type SaveWorkoutFn = Database["public"]["Functions"]["save_workout_to_specified_day"];
type SaveWorkoutArgs = SaveWorkoutFn["Args"];

export const saveWorkoutToSpecifiedDay = createAsyncThunk<string, Payload, AsyncThunkCreator<string>>(
  `${TRAINEE_ACTIVITIES_REDUCER_NAME}/saveWorkoutToSpecifiedDay`,
  async (payload, { getState, dispatch, rejectWithValue, extra: { db, analytics, errorTrackingService } }) => {
    const { workoutSource, editedDocId, isNewWorkout, currentWorkout, currentDay } = getState().traineeActivities.data;
    const { selectedProgram } = getState().traineeProgram;
    const { profile } = getState().trainee;

    if (!currentWorkout) {
      throw new Error("currentWorkout is not defined");
    }

    if (!selectedProgram || !profile) {
      throw new Error("selectedProgram or profile is not defined");
    }

    const date = dayjs(currentDay, "YYYY-MM-DD").hour(12);

    try {
      const args: SaveWorkoutArgs = {
        p_workout: {
          ...currentWorkout,
          replicatedFromSchedule: !isNewWorkout,
          updatedAt: new Date().toISOString(),
        },
        p_event_data: {
          id: workoutSource === WorkoutSource.FromActivity && editedDocId ? editedDocId : generateUniqId(),
          type: ActivityEventType.Workout,
          eventDate: date.format("YYYY-MM-DD"),
          eventMonth: date.format("YYYY-MM"),
          eventWeek: `${date.format("YYYY")}-${date.isoWeek()}`,
          programId: date.isSameOrAfter(selectedProgram.startDate, "day") ? selectedProgram.id : null,
          traineeId: profile.id,
          clientEmail: profile.email,
          userId: profile.userId,
          eventTimestamp: date.toISOString(),
          workoutId: currentWorkout.id,
        },
        p_is_update: workoutSource === WorkoutSource.FromActivity && Boolean(editedDocId),
      };

      const { data, error } = await db.rpc("save_workout_to_specified_day", args);

      if (error) {
        errorTrackingService?.recordError(error, "saveWorkoutToSpecifiedDay");
        return rejectWithValue(error.message);
      }

      if (!data) {
        throw new Error("No data returned from save_workout_to_specified_day");
      }

      const workoutEvent = data as WorkoutActivityEvent;

      if (workoutSource === WorkoutSource.FromActivity && editedDocId) {
        dispatch(updateActivity(workoutEvent));
      } else {
        dispatch(addActivity(workoutEvent));
        analytics.track("tracked_workout_activity");
      }

      if (!payload?.isMobile) {
        dispatch(setWorkoutEditModalHidden());
      }

      return workoutEvent.id;
    } catch (error) {
      errorTrackingService?.recordError(error, "saveWorkoutToSpecifiedDay");
      throw error;
    }
  },
);
