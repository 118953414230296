import cloneDeep from "lodash.clonedeep";
import round from "lodash.round";
import uniqBy from "lodash.uniqby";

import { type DishIngredient } from "@fitness-app/data-models/entities/Dish";
import { type Measure } from "@fitness-app/data-models/entities/Ingredient";

export const recalculateMeasures = (dish: DishIngredient): DishIngredient => {
  const defaultMeasure = {
    id: 1,
    name: "g",
    unit: "grams",
    energyPerUnit: dish.nutrients.calories,
    weightPerUnit: 100,
  };

  const measures: Measure[] =
    dish.measures?.length === 0
      ? [
          {
            id: 1,
            name: "g",
            unit: "grams",
            energyPerUnit: dish.nutrients.calories,
            weightPerUnit: 100,
          },
        ]
      : cloneDeep(dish.measures);

  if (dish.measures.find((measure) => measure.id !== 1)) {
    measures.unshift(defaultMeasure);
  }

  if ("measureId" in dish && dish.measureId && dish.measures.find((measure) => measure.id !== dish.measureId)) {
    const weightPerUnit = dish.measureWeight || 100;
    measures.push({
      id: dish.measureId,
      name: dish.measure,
      weightPerUnit,
      energyPerUnit: round((dish.nutrients.calories * weightPerUnit) / 100, 1),
      unit: "grams",
      fromFitatu: false,
    });
  }

  const uniqMeasures = uniqBy(measures, "id");
  const calories = round((dish.nutrients.calories * dish.portion) / 100, 1);

  return {
    ...dish,
    measures: uniqMeasures,
    portionCalories: calories ?? dish.portionCalories,
  };
};
