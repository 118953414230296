import { type TFunction } from "i18next";
import isEmpty from "lodash.isempty";

import {
  TeamMemberRole,
  TeamMemberStatus,
  type TeamMemberWithUser,
} from "@fitness-app/data-models/entities/TeamMember";

export const EMPTY_TRAINER_FIELD = "none";

export const createTrainerOptions = (
  list: TeamMemberWithUser[],
  t: TFunction<string[]>,
  withOwner = true,
  memberId?: string,
) => {
  if (!list || isEmpty(list)) {
    return undefined;
  }
  let trainerOptions: { value: string; text: string }[] = [];
  list
    .filter((trainer) => (withOwner ? trainer : trainer.role !== TeamMemberRole.ACCOUNT_OWNER))
    .forEach((trainer) => {
      if (trainer.selectable) {
        const text = `${trainer.firstName} ${trainer.lastName}`;
        trainerOptions.push({
          value: trainer.id,
          text: trainer.status === TeamMemberStatus.ACTIVE ? text : `${text} (nieaktywny)`,
        });
      }
    });

  if (memberId) {
    const member = list.find((trainer) => trainer.id === memberId);
    if (member) {
      const text = `${member.firstName} ${member.lastName}`;
      trainerOptions = trainerOptions.filter((member) => member.value !== memberId);
      trainerOptions.unshift({
        value: member.id,
        text: `${text} (Twój profil)`,
      });
    }
  }

  trainerOptions.push({
    value: EMPTY_TRAINER_FIELD,
    text: t("trainerOptions.noTrainer"),
  });
  return trainerOptions;
};
