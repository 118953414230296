import React, { useMemo, type FunctionComponent } from "react";
import { Alert, Button, Checkbox, Divider, Form, Select } from "antd";
import { type FormInstance } from "antd/lib/form";
import CSVReader from "react-csv-reader";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useProductPricesOptions } from "~/modules/Products/hooks/useProductPricesOptions";
import { type File, type HeaderMatcherForm } from "../ImportClientsModal";

interface OwnProps {
  formController: FormInstance<HeaderMatcherForm>;
  file: File;
  setFile: (file: File) => void;
  onSubmit: (model: HeaderMatcherForm) => void;
  skipStep: () => void;
  productId: string;
  isFree: boolean;
}

type Props = OwnProps;

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: (header: string) => header.toLowerCase().replace(/\W/g, "_"),
} as const;

const SelectImportFile: FunctionComponent<Props> = ({
  formController,
  file,
  setFile,
  onSubmit,
  skipStep,
  productId,
  isFree,
}) => {
  const { t } = useTranslation(["products", "common"]);
  const productPricesOptions = useProductPricesOptions();
  const columnOptions = useMemo(() => {
    if (file) {
      const keys = Object.keys(file?.data?.[0] || {});
      return keys.map((key) => ({ label: key, value: key }));
    } else {
      return [];
    }
  }, [file]);

  return (
    <div>
      <CSVReader
        parserOptions={papaparseOptions}
        label={t("import.inputLabel")}
        cssClass="flex flex-col my-2.5 mx-0 items-center"
        cssInputClass="shadow-none bg-white text-gray-900"
        cssLabelClass="text-green-500 text-xl font-medium py-2.5 mx-4"
        onFileLoaded={(data, fileInfo) => setFile({ data: data as Record<string, string>[], fileInfo })}
      />

      {file ? (
        <div className="mt-7.5">
          <Form<HeaderMatcherForm>
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            form={formController}
            onFinish={onSubmit}
          >
            <Form.Item
              name="email"
              label={t("import.headerForm.email")}
              rules={[
                {
                  required: true,
                  message: t<string>("common:validationErrors.fieldIsRequired"),
                },
              ]}
            >
              <Select
                style={{ maxWidth: 400 }}
                placeholder={t("import.headerForm.emailPlaceholder")}
                options={columnOptions}
              />
            </Form.Item>
            <Form.Item
              name="subscribeDate"
              label={t("import.headerForm.subscribeDate")}
              rules={[
                {
                  required: false,
                  message: t<string>("common:validationErrors.fieldIsRequired"),
                },
              ]}
            >
              <Select
                style={{ maxWidth: 400 }}
                placeholder={t("import.headerForm.subscribeDatePlaceholder")}
                options={columnOptions}
              />
            </Form.Item>
            <Form.Item
              name="firstName"
              label={t("import.headerForm.firstName")}
              rules={[
                {
                  required: !isFree,
                  message: t<string>("common:validationErrors.fieldIsRequired"),
                },
              ]}
            >
              <Select
                style={{ maxWidth: 400 }}
                placeholder={t("import.headerForm.firstNamePlaceholder")}
                options={columnOptions}
              />
            </Form.Item>
            <Form.Item
              name="hasLastNameTogether"
              label={t("import.headerForm.hasLastNameTogether")}
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
            <Form.Item name="lastName" label={t("import.headerForm.lastName")}>
              <Select
                style={{ maxWidth: 400 }}
                placeholder={t("import.headerForm.lastNamePlaceholder")}
                options={columnOptions}
              />
            </Form.Item>
            <Form.Item name="phoneNumber" label={t("import.headerForm.phoneNumber")}>
              <Select
                style={{ maxWidth: 400 }}
                placeholder={t("import.headerForm.phoneNumberPlaceholder")}
                options={columnOptions}
              />
            </Form.Item>
            {!isFree && (
              <Form.Item
                name="productPriceId"
                label={t("import.headerForm.productPrice")}
                rules={[
                  {
                    required: true,
                    message: t<string>("common:validationErrors.fieldIsRequired"),
                  },
                ]}
              >
                <Select
                  style={{ maxWidth: 400 }}
                  placeholder={t("import.headerForm.productPricePlaceholder")}
                  options={productPricesOptions}
                  popupMatchSelectWidth={false}
                />
              </Form.Item>
            )}
          </Form>
        </div>
      ) : (
        <div style={{ width: "80%", margin: "30px auto" }}>
          <Divider>{t("import.or")}</Divider>
          <div style={{ textAlign: "center", marginTop: 30 }}>
            <Button type="primary" onClick={skipStep}>
              {t("import.addManually")}
            </Button>
          </div>
          <Alert
            showIcon
            message={
              <span>
                {t("import.warnAboutImportAutomation")}{" "}
                <Link to={`/dashboard/${productId}/automation?forceRedirect=true`}>{t("import.config")}</Link>{" "}
                {t("import.warnAboutImportAutomationEnd")}
              </span>
            }
            style={{ margin: "20px 0" }}
          />
        </div>
      )}
    </div>
  );
};

export default SelectImportFile;
