import { createAsyncThunk } from "@reduxjs/toolkit";

import { TeamMemberRole, type TeamMemberWithUser } from "@fitness-app/data-models/entities/TeamMember";

import { type AsyncThunkCreator } from "../../../index";
import { TEAM_REDUCER_NAME } from "../types";

type Payload = boolean | void;

export const fetchTeamMembers = createAsyncThunk<TeamMemberWithUser[], Payload, AsyncThunkCreator<string>>(
  `${TEAM_REDUCER_NAME}/fetchTeamMembers`,
  async (isHeadCoach, { rejectWithValue, extra: { db } }) => {
    let query = db
      .from("team_member")
      .select(
        "*, user:userId(firstName, lastName, avatarUrl), assignedTrainees:trainee(id), assignedTrainers:team_member_assigned!team_member_assigned_headCoachId_fkey(trainer:assignedTrainerId(firstName, lastName, email, id, status, user:userId(avatarUrl, id)))",
      )
      .order("createdAt", { ascending: false });

    if (isHeadCoach) {
      query = query.in("role", [TeamMemberRole.SHARED_TRAINER, TeamMemberRole.REGULAR_TRAINER]);
    }

    const { data, error } = await query.returns<TeamMemberWithUser[]>();

    if (error) {
      return rejectWithValue(error.message);
    }

    return data;
  },
);
