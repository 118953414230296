import React from "react";
import { CalendarOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Avatar, Button, List, message, Modal, Popconfirm, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { clientScheduledTasksActions } from "@fitness-app/app-store";
import { TemplateType, type ScheduledMessagesAutomation } from "@fitness-app/data-models/entities/AutomationTemplate";
import { ProgramAutomationTaskStatus } from "@fitness-app/data-models/entities/ProgramAutomation";
import { getUserInitials } from "@fitness-app/utils";

import { supabase, useAppDispatch } from "~/store/initializeStore";

interface ScheduledMessagesModalProps {
  traineeId: string;
  onClose: () => void;
}

export const ScheduledMessagesModal = ({ traineeId, onClose }: ScheduledMessagesModalProps): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("automation");
  const queryClient = useQueryClient();

  const scheduledTasksQuery = useQuery(["scheduledMessageTasksModal", traineeId], {
    enabled: !!traineeId,
    queryFn: async () => {
      const { error, data } = await supabase
        .from("program_automation_task")
        .select("*")
        .eq("traineeId", traineeId)
        .contains("task", { type: TemplateType.ScheduledMessages })
        .eq("status", ProgramAutomationTaskStatus.Upcoming)
        .order("sendDate", { ascending: true });

      if (error) {
        throw new Error(error.message);
      }

      if (!data) {
        return [];
      }

      return data;
    },
  });

  const removeScheduledProgramTask = async (taskId: string) => {
    void message.loading({ content: "messages.loading.deleting", duration: 0, key: "remove" });
    await dispatch(clientScheduledTasksActions.removeClientTask(taskId));
    queryClient.invalidateQueries(["scheduledMessageTasksModal", traineeId]);
    queryClient.invalidateQueries(["scheduledMessageTasks"]);
    message.destroy("remove");
  };

  return (
    <Modal
      destroyOnClose
      width={700}
      maskClosable={false}
      open
      onCancel={onClose}
      title="Zaplanowane wiadomości dla klientki"
      footer={[
        <Button key="back" onClick={onClose}>
          Zamknij
        </Button>,
      ]}
    >
      <List
        className="demo-loadmore-list"
        loading={scheduledTasksQuery.isLoading}
        itemLayout="horizontal"
        dataSource={scheduledTasksQuery.data || []}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Popconfirm
                key="remove"
                title={t("automatedMessageForm.deleteEvent")}
                okText={t("common:switch.yes")}
                cancelText={t("common:button.cancel")}
                placement="top"
                onConfirm={() => removeScheduledProgramTask(item.id)}
              >
                <Typography.Link type="danger">{t("common:button.delete")}</Typography.Link>
              </Popconfirm>,
            ]}
          >
            <List.Item.Meta
              avatar={
                <Tooltip title={(item.task as ScheduledMessagesAutomation).authorName}>
                  <Avatar src={(item.task as ScheduledMessagesAutomation).authorAvatar}>
                    {getUserInitials((item.task as ScheduledMessagesAutomation).authorName)}
                  </Avatar>
                </Tooltip>
              }
              title={
                <div>
                  <CalendarOutlined /> {dayjs(item.sendDate).format("DD.MM.YYYY HH:mm")}
                </div>
              }
              description={
                <ul>
                  {(item.task as ScheduledMessagesAutomation).messages.map((message, index) => (
                    <li key={index}>
                      <Typography.Text>- {message}</Typography.Text>
                    </li>
                  ))}
                </ul>
              }
            />
          </List.Item>
        )}
      />
    </Modal>
  );
};
