import React, { useCallback, useState, type FunctionComponent } from "react";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  DatePicker,
  Descriptions,
  Form,
  Grid,
  message,
  Modal,
  Radio,
  Select,
  Space,
  Switch,
  Tag,
  Typography,
} from "antd";
import dayjs, { type Dayjs } from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import { useTranslation } from "react-i18next";

import { productClientActions } from "@fitness-app/app-store";
import { EndAccessStrategy } from "@fitness-app/data-models/entities/Product";
import { ClientArchiveReason, type OneTimeProductClient } from "@fitness-app/data-models/entities/ProductClient";

import { useEntityChange } from "~/hooks/useEntityChange";
import { ONE_TIME_STATUS_MAPPER } from "~/modules/Products/constants/oneTimeStatusMapper";
import { paymentTypeColorMapper } from "~/modules/Products/constants/paymentTypeColorMapper";
import { createEndAccessStrategy } from "~/modules/Products/constants/productForm";
import InfoAboutExtendedAccessPeriod from "~/modules/Products/Product/ProductClientsList/components/InfoAboutExtendedAccessPeriod/InfoAboutExtendedAccessPeriod";
import { useAppDispatch } from "~/store/initializeStore";

dayjs.extend(weekday);
dayjs.extend(localeData);

interface OwnProps {
  productClient: OneTimeProductClient;
  updateInvoiceRequest: (value: boolean) => void;
  ownerView?: boolean;
}

type OneTimeNewsletterSettingsProps = OwnProps;

const DATE_FORMAT = "DD.MM.YYYY";

interface FormModel {
  original_access_date: null | Dayjs;
  resumes_at: null | Dayjs;
}

const ProductClientOneTimeSettings: FunctionComponent<OneTimeNewsletterSettingsProps> = ({
  productClient,
  updateInvoiceRequest,
  ownerView,
}) => {
  const { t } = useTranslation(["client", "products", "common"]);
  const [editMode, setEditMode] = useState(false);
  const [endAccessStrategy, setAccessStrategy] = useState(productClient.accessPeriod?.endAccessStrategy);
  const [showModalToUpdateAccessPeriod, toggleModalForAccessPeriod] = useState(false);
  const dispatch = useAppDispatch();
  const { lg } = Grid.useBreakpoint();
  const [formController] = Form.useForm<FormModel>();
  const [showModalLoader, toggleModalLoader] = useState(false);
  const [updatingStrategyAccess, onStrategyUpdateSuccess, _, startUpdatingStrategy] = useEntityChange(() =>
    setEditMode(false),
  );
  const accessEnd = productClient.accessPeriod?.currentPeriodEnd
    ? dayjs.unix(
        productClient.accessPeriod?.pauseCollection?.resumes_at || productClient.accessPeriod?.currentPeriodEnd,
      )
    : productClient.productDuration?.endAt
    ? dayjs.unix(productClient.productDuration.endAt)
    : null;

  const defaultValue =
    productClient.accessPeriod?.type === "one_time"
      ? dayjs
          .unix(productClient.accessPeriod?.pauseCollection?.resumes_at || productClient.accessPeriod?.currentPeriodEnd)
          .add(productClient.accessPeriod?.pauseCollection?.resumes_at ? 0 : 1, "day")
      : null;

  const getDisabledDate = useCallback(
    (current: dayjs.Dayjs) => (current && accessEnd ? current < accessEnd?.endOf("day") : true),
    [accessEnd],
  );

  const handleFormFinish = async (formData: FormModel) => {
    toggleModalLoader(true);
    try {
      await dispatch(
        productClientActions.setExtendAccessPeriod({
          productId: productClient.productId,
          clientEmail: productClient.email,
          resume_at: formData.resumes_at ? formData.resumes_at.unix() : null,
        }),
      ).unwrap();
      toggleModalForAccessPeriod(false);
      toggleModalLoader(false);
    } catch (e) {
      void message.error(t("pauseCollection.cannotChangePeriod"));
      toggleModalLoader(false);
    }
  };

  const onChangeStrategyEndAccess = async () => {
    if (
      !productClient.accessPeriod ||
      endAccessStrategy === productClient.accessPeriod?.endAccessStrategy ||
      !endAccessStrategy ||
      productClient.accessPeriod.type === "none_limited"
    ) {
      setEditMode(false);
      return;
    }
    startUpdatingStrategy();

    await dispatch(
      productClientActions.updateProductEndAccessStrategy({
        productId: productClient.productId,
        clientEmail: productClient.email,
        updatedAccessStrategy: endAccessStrategy,
        clientAccessPeriod: productClient.accessPeriod,
      }),
    );
    onStrategyUpdateSuccess();
  };

  const status =
    productClient.accessPeriod && "status" in productClient.accessPeriod
      ? productClient.accessPeriod.status ?? "active"
      : productClient.status || "active";

  return (
    <>
      <Descriptions bordered labelStyle={{ width: 250 }} layout={lg ? "horizontal" : "vertical"}>
        <Descriptions.Item label={t("list.columns.paymentType")} span={3}>
          <Tag color={paymentTypeColorMapper[productClient.paymentType]}>{t(`type.${productClient.paymentType}`)}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label={t("subscription.status")} span={3}>
          <Badge
            status={ONE_TIME_STATUS_MAPPER[status]}
            text={
              productClient.archivedInfo?.reason === ClientArchiveReason.Refund
                ? t(`common:subscriptionStatus.archived`)
                : t(`common:subscriptionStatus.${status}`)
            }
          />
        </Descriptions.Item>
        <Descriptions.Item label={t("list.columns.accessPeriod")} span={3}>
          {productClient.accessPeriod ? (
            <Space direction="horizontal" size={8}>
              <span style={{ marginRight: 8 }}>
                {dayjs.unix(productClient.accessPeriod.currentPeriodStart).format(DATE_FORMAT)} -{" "}
                {accessEnd?.format(DATE_FORMAT)}
              </span>

              {productClient.accessPeriod.type === "one_time" && (
                <InfoAboutExtendedAccessPeriod accessPeriod={productClient.accessPeriod} />
              )}

              {ownerView && productClient.status === "active" ? (
                <Button type="link" onClick={() => toggleModalForAccessPeriod(true)}>
                  {t("pauseCollection.changeAccessPeriod")}
                </Button>
              ) : null}
            </Space>
          ) : (
            <span>{t("accessTillEnd")}</span>
          )}
        </Descriptions.Item>
        {typeof productClient.invoiceRequested === "boolean" ? (
          <Descriptions.Item label={t("list.columns.requestedInvoice")} span={3}>
            <Switch checked={productClient.invoiceRequested} onChange={updateInvoiceRequest} />
          </Descriptions.Item>
        ) : null}
        {ownerView && (
          <Descriptions.Item label={t("oneTime.endAccessStrategy")} span={3}>
            <Space size={4}>
              {editMode ? (
                <>
                  <Select
                    style={{ minWidth: 350 }}
                    value={endAccessStrategy}
                    onSelect={(value: EndAccessStrategy) => setAccessStrategy(value)}
                  >
                    {createEndAccessStrategy(t).map((strategy) => (
                      <Select.Option value={strategy.value} key={strategy.value}>
                        {strategy.label}
                      </Select.Option>
                    ))}
                  </Select>

                  <Button
                    type="link"
                    onClick={onChangeStrategyEndAccess}
                    loading={!!updatingStrategyAccess}
                    icon={<CheckOutlined style={{ color: "#87d068" }} />}
                  />
                  <Button
                    type="link"
                    icon={<CloseOutlined style={{ color: "#f64e60" }} />}
                    onClick={() => setEditMode(false)}
                  />
                </>
              ) : (
                <>
                  <Typography.Text>
                    {t(
                      `common:endAccessStrategy.${
                        productClient.accessPeriod?.endAccessStrategy ?? EndAccessStrategy.Cancel
                      }`,
                    )}
                  </Typography.Text>
                  <Button type="link" icon={<EditOutlined />} onClick={() => setEditMode((prevState) => !prevState)} />
                </>
              )}
            </Space>
          </Descriptions.Item>
        )}
      </Descriptions>
      {ownerView && (
        <Modal
          visible={showModalToUpdateAccessPeriod}
          onCancel={() => toggleModalForAccessPeriod(false)}
          title={t("pauseCollection.modalTitle")}
          width={600}
          okText={t("common:button.save")}
          onOk={() => formController?.submit()}
          okButtonProps={{
            loading: showModalLoader,
          }}
        >
          <Form<FormModel>
            form={formController}
            labelCol={{ xxl: { span: 8, offset: 4 }, span: 24, offset: 0 }}
            wrapperCol={{ xxl: { span: 16, offset: 4 }, span: 24, offset: 0 }}
            onFinish={handleFormFinish}
            initialValues={{
              original_access_date: accessEnd,
              resumes_at:
                productClient.accessPeriod?.type === "one_time" &&
                productClient.accessPeriod.pauseCollection?.resumes_at
                  ? dayjs.unix(productClient.accessPeriod.pauseCollection?.resumes_at)
                  : null,
            }}
          >
            <Form.Item name="original_access_date" label={t("pauseCollection.accessTo")}>
              <DatePicker disabled style={{ minWidth: 250 }} />
            </Form.Item>
            <Form.Item name="resumes_at" label={t("pauseCollection.extendTo")}>
              <DatePicker
                style={{ minWidth: 250 }}
                disabledDate={getDisabledDate}
                defaultPickerValue={defaultValue ?? undefined}
                showToday={false}
              />
            </Form.Item>
          </Form>
          <div style={{ textAlign: "center", marginTop: 10 }}>
            <Radio.Group
              value={null}
              onChange={(e) =>
                formController.setFieldsValue({
                  resumes_at: accessEnd?.add(Number(e.target.value), "month"),
                })
              }
            >
              <Radio.Button value={1}>{t("pauseCollection.extendAbout1Month")}</Radio.Button>
              <Radio.Button value={3}>{t("pauseCollection.extendAbout3Month")}</Radio.Button>
              <Radio.Button value={12}>{t("pauseCollection.extendAbout12Month")}</Radio.Button>
            </Radio.Group>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ProductClientOneTimeSettings;
