import React, { useCallback, useState } from "react";

import { type ChatMessage } from "@fitness-app/data-models/entities/Chat";

import ChatNotificationMessage from "~/modules/Chat/components/ChatNotificationContext/ChatNotificationMessage";
import { ChatUtilitiesProvider } from "~/modules/Chat/providers/ChatUtilitiesProvider";

export const ChatMessageFormContext = React.createContext({
  toggleVisibility: () => {},
  close: () => {},
  open: (_context?: ChatMessage["context"]) => {},
});

const ChatMessageFormProvider = ({
  children,
  initialMessage,
  onSuccess,
}: {
  children: React.ReactElement;
  initialMessage?: string;
  onSuccess?: (messageId: string, context?: ChatMessage["context"]) => void;
}) => {
  const [visible, toggleChatMessageModal] = useState(false);
  const [context, setContext] = useState<ChatMessage["context"] | undefined>(undefined);

  const toggle = useCallback(() => {
    toggleChatMessageModal((prev) => !prev);
  }, []);

  const open = useCallback((context?: ChatMessage["context"]) => {
    setContext(context);
    toggleChatMessageModal(true);
  }, []);

  const close = useCallback(() => {
    toggleChatMessageModal(false);
    setContext(undefined);
  }, []);

  return (
    <ChatUtilitiesProvider>
      <ChatMessageFormContext.Provider
        value={{
          toggleVisibility: toggle,
          open,
          close,
        }}
      >
        {children}
      </ChatMessageFormContext.Provider>
      <ChatNotificationMessage
        initialMessage={initialMessage}
        closeModal={close}
        visible={visible}
        context={context}
        onSuccess={onSuccess}
      />
    </ChatUtilitiesProvider>
  );
};

export const useChatMessageForm = () => React.useContext(ChatMessageFormContext);

export default ChatMessageFormProvider;
