import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  BellOutlined,
  CalendarOutlined,
  CoffeeOutlined,
  DashboardOutlined,
  FileDoneOutlined,
  MessageOutlined,
  NotificationOutlined,
  ProfileOutlined,
  ShoppingCartOutlined,
  SolutionOutlined,
  StarOutlined,
  TeamOutlined,
  ToolOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useVisibilityChange } from "@uidotdev/usehooks";
import { Avatar, Badge, Menu, Spin, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { chatActions, notificationsActions, teamActions } from "@fitness-app/app-store";
import { RequestStatus } from "@fitness-app/data-models";
import { UserRole } from "@fitness-app/data-models/entities/UserProfile";
import { getUserInitials } from "@fitness-app/utils";

import { useUserRole } from "~/hooks/trainer/useUserRole";
import Chat from "~/modules/Chat/Chat";
import ChatDrawer from "~/modules/Chat/ChatDrawer";
import ChatWindowsList from "~/modules/Chat/ChatWindowsList";
import { ChatUtilitiesProvider } from "~/modules/Chat/providers/ChatUtilitiesProvider";
import DashboardWrapper from "~/modules/Dashboard/DashboardWrapper";
import TraineeList from "~/modules/Trainee/TraineeList";
import { useAppDispatch, useAppSelector } from "~/store/initializeStore";
import Home from "./Home";
import NotificationsDrawer from "./NotificationsDrawer/NotificationsDrawer";
import ProfileDrawer from "./ProfileDrawer/ProfileDrawer";

const trainerPaths = [
  {
    name: "dashboard",
    path: "/dashboard",
    component: Home,
    exact: false,
    icon: <DashboardOutlined style={{ fontSize: 18 }} />,
    label: "menu.dashboard",
    inMenu: true,
  },
  {
    name: "clientProfile",
    path: "/client/:id",
    component: Home,
    exact: false,
    icon: <DashboardOutlined />,
    label: "menu.dashboard",
    inMenu: false,
  },
  {
    name: "notifications",
    path: "/notifications",
    component: Home,
    exact: false,
    icon: <DashboardOutlined />,
    label: "menu.dashboard",
    inMenu: false,
  },
  {
    name: "products",
    path: "/products",
    component: Home,
    exact: false,
    icon: <ShoppingCartOutlined style={{ fontSize: 18 }} />,
    label: "menu.products",
    inMenu: true,
    protected: true,
  },
  {
    name: "traineeList",
    path: "/trainee",
    component: TraineeList,
    exact: false,
    icon: <StarOutlined />,
    label: "menu.trainee",
    inMenu: true,
  },
  {
    name: "programs",
    path: "/programs",
    component: Home,
    exact: false,
    icon: <SolutionOutlined style={{ fontSize: 18 }} />,
    label: "menu.programs",
    inMenu: true,
  },
  {
    name: "nutrition",
    path: "/nutrition",
    component: Home,
    exact: false,
    icon: <CoffeeOutlined style={{ fontSize: 18 }} />,
    label: "menu.nutrition",
    inMenu: true,
  },
  {
    name: "calendar",
    path: "/calendar",
    component: Home,
    exact: false,
    icon: <CalendarOutlined style={{ fontSize: 18 }} />,
    label: "menu.calendar",
    inMenu: true,
    disabled: true,
    protected: true,
  },
  {
    name: "surveys",
    path: "/surveys",
    component: Home,
    exact: false,
    icon: <FileDoneOutlined style={{ fontSize: 18 }} />,
    label: "menu.surveys",
    inMenu: true,
  },
  {
    name: "campaigns",
    path: "/campaigns",
    component: Home,
    exact: false,
    icon: <NotificationOutlined style={{ fontSize: 18 }} />,
    label: "menu.campaigns",
    inMenu: true,
    protected: true,
  },
  {
    name: "team",
    path: "/team",
    component: Home,
    exact: false,
    icon: <TeamOutlined style={{ fontSize: 18 }} />,
    label: "menu.team",
    inMenu: true,
    protected: true,
  },
  {
    name: "team",
    path: "/automation",
    component: Home,
    exact: false,
    icon: <ToolOutlined style={{ fontSize: 18 }} />,
    label: "menu.automation",
    inMenu: true,
    protected: true,
  },
  {
    name: "chat",
    path: "/chat",
    component: Chat,
    exact: false,
    icon: <ProfileOutlined />,
    label: "menu.profile",
    inMenu: false,
  },
];

const Dashboard = (): React.ReactElement => {
  const [showChatDrawer, toggleChatDrawer] = useState(false);
  const [showNotificationsDrawer, toggleNotificationDrawer] = useState(false);
  const [showProfileDrawer, toggleProfileDrawer] = useState(false);
  const { t } = useTranslation(["dashboard", "auth"]);
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const totalUnread = useAppSelector((store) => store.chat.unreadMessages?.total);
  const unreadNotifications = useAppSelector((state) => state.notifications.totalUnread);
  const unreadMessagesStatus = useAppSelector((state) => state.chat.unreadMessagesStatus);
  const navigate = useNavigate();
  const documentVisible = useVisibilityChange();
  const { isTrainer, isTeamMember, isHeadCoach } = useUserRole();
  const prevUnreadStatus = useRef<RequestStatus | null>(null);

  useEffect(() => {
    if (
      documentVisible &&
      unreadMessagesStatus === RequestStatus.SUBSCRIBED &&
      prevUnreadStatus.current === RequestStatus.SUBSCRIBED
    ) {
      void dispatch(chatActions.subscribeToUnreadMessages());
      void dispatch(notificationsActions.refreshUnreadNotifications());

      if (isTrainer || isHeadCoach) {
        void dispatch(teamActions.subscribeToTeam(isHeadCoach));
        if (isHeadCoach) {
          void dispatch(teamActions.subscribeToTeamMember());
        }
      } else {
        void dispatch(teamActions.subscribeToTeamMember());
      }
    }

    prevUnreadStatus.current = unreadMessagesStatus;
  }, [documentVisible, unreadMessagesStatus, dispatch]);

  useEffect(() => {
    if (isTrainer || isHeadCoach) {
      dispatch(teamActions.subscribeToTeam(isHeadCoach));
      if (isHeadCoach) {
        void dispatch(teamActions.subscribeToTeamMember());
      }
    } else if (isTeamMember) {
      void dispatch(teamActions.subscribeToTeamMember());
    }
  }, [dispatch, isTrainer, isTeamMember, isHeadCoach]);

  useEffect(() => {
    dispatch(chatActions.subscribeToUnreadMessages());

    return () => {
      dispatch(teamActions.unsubscribeFromTeam());
      dispatch(teamActions.unsubscribeFromTeamMember());
      dispatch(chatActions.unsubscribeFromUnreadMessages());
      dispatch(chatActions.unsubscribeFromLatestChannel());
    };
  }, [dispatch]);

  const getSelectedKey = () => {
    const activeNavItems = trainerPaths.filter((navItem) => location.pathname.startsWith(navItem.path));
    return activeNavItems?.[0] ? [activeNavItems[0].path] : undefined;
  };

  const navigationToRender = useMemo(
    () =>
      trainerPaths.filter((item) => {
        if (!item.protected) {
          return true;
        }

        if (item.protected) {
          if (user.profile?.role !== UserRole.TRAINER) return false;
          return true;
        }

        return false;
      }),
    [user],
  );

  return (
    <DashboardWrapper
      menu={
        <Menu theme="dark" mode="inline" selectedKeys={getSelectedKey()} style={{ backgroundColor: "transparent" }}>
          {navigationToRender.map((navItem) => {
            if (!navItem.inMenu) {
              return null;
            }
            return (
              <Menu.Item
                key={navItem.path}
                icon={navItem.icon}
                onClick={() => navigate(navItem.path)}
                className="bg-transparent font-medium text-white"
                style={{ paddingLeft: 12, height: 48, lineHeight: 48 }}
                disabled={navItem.disabled}
              >
                {t(navItem.label)}
              </Menu.Item>
            );
          })}
        </Menu>
      }
      rightSection={
        <>
          <div role="button" onClick={() => toggleChatDrawer(true)}>
            <Badge count={totalUnread} overflowCount={99}>
              <MessageOutlined style={{ fontSize: 26 }} className="rotate-icon" />
            </Badge>
          </div>
          <div role="button" onClick={() => toggleNotificationDrawer((prev) => !prev)}>
            <Badge overflowCount={99} count={unreadNotifications}>
              <BellOutlined style={{ fontSize: 26 }} className="rotate-icon" />
            </Badge>
          </div>
          <div role="button" onClick={() => toggleProfileDrawer((prev) => !prev)}>
            <Tooltip title={t("seeProfile")}>
              <Avatar
                icon={!user?.profile ? <UserOutlined /> : null}
                size="large"
                shape="square"
                src={user.profile?.avatarUrl || user.profile?.avatarUrl || null}
              >
                {user?.profile?.firstName
                  ? getUserInitials(`${user.profile.firstName} ${user.profile.lastName ?? ""}`)
                  : null}
              </Avatar>
            </Tooltip>
          </div>
        </>
      }
    >
      <>
        <div className="h-full flex-1 bg-gray-100 p-8">
          {user?.profile ? (
            <Outlet />
          ) : (
            <div className="flex min-h-[400px]	 flex-1 items-center justify-center">
              <Spin />
            </div>
          )}
        </div>

        <ProfileDrawer isOpen={showProfileDrawer} toggleDrawer={() => toggleProfileDrawer((prev) => !prev)} />
        <ChatDrawer isOpen={showChatDrawer} toggleDrawer={() => toggleChatDrawer((prev) => !prev)} />
        <NotificationsDrawer
          isOpen={showNotificationsDrawer}
          toggleDrawer={() => toggleNotificationDrawer((prev) => !prev)}
        />
        <ChatUtilitiesProvider>
          <div
            className="fixed bottom-0 right-0 transition-transform"
            style={{
              transform: `translateX(${showChatDrawer ? -380 : 0}px)`,
              pointerEvents: "none",
              zIndex: 1000,
              transitionDuration: "0.4s",
            }}
          >
            <ChatWindowsList />
          </div>
        </ChatUtilitiesProvider>
      </>
    </DashboardWrapper>
  );
};

export default Dashboard;
