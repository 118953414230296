import { useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs, { type Dayjs } from "dayjs";

import { chatActions, clientScheduledTasksActions } from "@fitness-app/app-store";
import { UserRole, type UserRoleEnum } from "@fitness-app/data-models";
import { TemplateType } from "@fitness-app/data-models/entities/AutomationTemplate";
import { ChatType, type ChatChannel } from "@fitness-app/data-models/entities/Chat";
import { ProgramAutomationTaskStatus } from "@fitness-app/data-models/entities/ProgramAutomation";

import { useEntityChange } from "~/hooks/useEntityChange";
import { type SelectedUsersListItem } from "~/modules/Chat/SendMessageToMultipleUsers/types";
import { supabase, useAppDispatch, useAppSelector } from "~/store/initializeStore";
import { prepareSuccessMessage } from "../SendMessageToMultipleUsers/SendMessageToMultipleUsersModal";

interface Author {
  authorId: string;
  authorRole: UserRoleEnum;
  authorName: string;
  authorAvatar: string | null;
}

export const useScheduleChatMessage = (channel: ChatChannel) => {
  const dispatch = useAppDispatch();
  const [loading, onSuccess, onFailure, onStart] = useEntityChange();
  const queryClient = useQueryClient();
  const profileId = useAppSelector((state) => state.trainee.profile?.id);

  const clientQuery = useQuery(["chatChannelClient", channel.id], {
    queryFn: async () => {
      const trainee = Object.values(channel.members).find(
        (client) => client.role === UserRole.CLIENT && client.clientId,
      );

      if (!trainee?.clientId) {
        return;
      }

      const { data } = await supabase
        .from("trainee")
        .select("id, assignedTrainerId")
        .eq("id", trainee.clientId)
        .throwOnError()
        .single();

      return data;
    },
  });

  const scheduledTasksQuery = useQuery(["scheduledMessageTasks", channel.id], {
    queryFn: async () => {
      const trainee = Object.values(channel.members).find(
        (client) => client.role === UserRole.CLIENT && client.clientId,
      );

      if (!trainee?.clientId) {
        return;
      }

      const { error, data } = await supabase
        .from("program_automation_task")
        .select("id, task->type, traineeId, sendDate, sendAt, status")
        .eq("traineeId", trainee.clientId)
        .contains("task", { type: TemplateType.ScheduledMessages })
        .eq("status", ProgramAutomationTaskStatus.Upcoming)
        .gte("sendDate", dayjs().toISOString())
        .order("sendDate", { ascending: true });

      if (error) {
        throw new Error(error.message);
      }

      if (!data) {
        return [];
      }

      return data;
    },
  });

  const sendScheduledMessage = async ({
    days,
    messages,
    author,
    client,
    onSentSuccess,
  }: {
    author: Author;
    days: Dayjs[];
    messages: string[];
    client: Omit<SelectedUsersListItem, "assignedTrainerId">;
    onSentSuccess?: () => void;
  }) => {
    onStart("Planowanie wysyłki wiadomości...");
    try {
      const dates = days.map((dayjsDate) => {
        const sendDate = new Date(
          dayjsDate.get("year"),
          dayjsDate.get("month"),
          dayjsDate.get("date"),
          dayjsDate.get("hours"),
          dayjsDate.get("minutes"),
        );

        return {
          sendDate: sendDate.toISOString(),
          sentTime: {
            hours: dayjsDate.get("hour"),
            minutes: dayjsDate.get("minutes"),
          },
          sendAt: Math.floor(sendDate.getTime() / 1000),
        };
      });

      const response = await dispatch(
        chatActions.sendMessageToMultipleUsers({
          messages: messages,
          sendImmediately: false,
          clients: [{ ...client, assignedTrainerId: clientQuery.data?.assignedTrainerId || null }],
          authorId: author.authorId,
          authorRole: author.authorRole,
          authorName: author.authorName,
          authorAvatar: author.authorAvatar,
          dates,
          sendToMarketingChannelIfExists: [ChatType.OneWayMarketing, ChatType.TwoWayMarketing].includes(channel.type),
        }),
      ).unwrap();
      if (profileId === client.id) {
        void dispatch(
          clientScheduledTasksActions.fetchClientTasks({ traineeId: client.id, selectedDate: days[0] || dayjs() }),
        );
      }
      onSuccess(
        prepareSuccessMessage({ count: response.count, clients: response.clients, now: false, scheduled: true }),
        response.clients === 0 ? "warning" : "success",
      );
      queryClient.refetchQueries(["scheduledMessageTasks", channel.id]);
      onSentSuccess?.();
    } catch (e) {
      onFailure("Wystąpił błąd podczas planowania wysyłki wiadomości.");
    }
  };

  return {
    sendScheduledMessage,
    loading,
    numberOfScheduledMessages: scheduledTasksQuery.data?.length ?? 0,
    scheduledMessages: scheduledTasksQuery.data || [],
  };
};
