import { useMemo, useState } from "react";

import { getTrainersTeam } from "@fitness-app/app-store/src/store/reducers/team/selectors";

import { useUserClaims } from "~/hooks/trainer/useUserClaims";
import { useAppSelector } from "~/store/initializeStore";

export const useTrainersFilters = () => {
  const { isRegularTrainer, isOwner, isSharedTrainer, memberId } = useUserClaims();
  const assignedTrainers = useAppSelector((store) => store.team.assignedTrainers);

  const [showOnlyMyTrainers, toggleMyTrainerFilters] = useState(true);
  const trainers = useAppSelector(getTrainersTeam);

  const filteredTrainers = useMemo(() => {
    if (isRegularTrainer) {
      return [];
    }

    if (isOwner || (isSharedTrainer && !showOnlyMyTrainers)) {
      return trainers;
    }

    const assignedTrainersIds = assignedTrainers.map((trainer) => trainer.assignedTrainerId);

    return trainers.filter((trainer) => assignedTrainersIds.includes(trainer.id) || trainer.id === memberId);
  }, [isOwner, isOwner, isRegularTrainer, showOnlyMyTrainers, trainers]);

  return {
    toggleMyTrainerFilters,
    showOnlyMyTrainers,
    trainers: filteredTrainers,
    isSharedTrainer,
  };
};
