import React, { type FunctionComponent } from "react";
import { CheckOutlined, DeleteOutlined, EditOutlined, MessageOutlined } from "@ant-design/icons";
import { Badge, Button, Popconfirm, Popover, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { type ScheduledMessagesAutomation } from "@fitness-app/data-models/entities/AutomationTemplate";
import { ProgramAutomationTaskStatus } from "@fitness-app/data-models/entities/ProgramAutomation";

import { getMinutesWithLeadingZero } from "~/modules/Automation/utils/getMinutesWithLeadingZero";

interface OwnProps {
  item: ScheduledMessagesAutomation;
  onRemove?: () => void;
  onEdit?: () => void;
  status?: ProgramAutomationTaskStatus;
}

type Props = OwnProps;

const statusMapper = {
  [ProgramAutomationTaskStatus.Upcoming]: "processing",
  [ProgramAutomationTaskStatus.Done]: "success",
  [ProgramAutomationTaskStatus.Rejected]: "error",
  [ProgramAutomationTaskStatus.Canceled]: "warning",
} as const;

const MessageAutomationItem: FunctionComponent<Props> = ({ item, onRemove, status, onEdit }) => {
  const { t } = useTranslation("automation");
  const minutesWithLeadingZero = getMinutesWithLeadingZero(item.sentTime.minutes);

  const handleEdit: React.MouseEventHandler<HTMLElement> = (event) => {
    event.stopPropagation();
    onEdit?.();
  };

  return (
    <Popover
      title={t("events.scheduleMessagesLong")}
      content={
        <Space direction="vertical">
          {status && (
            <Space>
              <Typography.Text type="secondary">{t("events.status")}</Typography.Text>
              <Badge status={statusMapper[status] || "default"} text={t(`eventsStatus.${status || "upcoming"}`)} />
            </Space>
          )}
          <Space>
            <Typography.Text type="secondary">{t("events.sequenceName")}</Typography.Text>
            <Typography.Text>{item.name}</Typography.Text>
          </Space>
          <Space>
            <Typography.Text type="secondary">{t("events.sentTime")}</Typography.Text>
            <Typography.Text>
              {item.sentTime.hours}:{minutesWithLeadingZero}
            </Typography.Text>
          </Space>
          <Space>
            <Typography.Text type="secondary">{t("events.messagesCount")}</Typography.Text>
            <Typography.Text>{item.messages.length}</Typography.Text>
          </Space>
          <Space>
            <Typography.Text type="secondary">{t("events.messagesAuthor")}</Typography.Text>
            <Typography.Text>{item.authorName}</Typography.Text>
          </Space>

          <Space direction="vertical">
            <Typography.Text type="secondary">Wiadomości:</Typography.Text>
            <ul>
              {item.messages.map((message, index) => (
                <li key={index}>
                  <Typography.Text>- {message}</Typography.Text>
                </li>
              ))}
            </ul>
          </Space>

          <Space>
            {onEdit && status !== ProgramAutomationTaskStatus.Done && (
              <Button icon={<EditOutlined style={{ pointerEvents: "none" }} />} type="link" onClick={handleEdit} />
            )}
            {onRemove && status !== ProgramAutomationTaskStatus.Done && (
              <Popconfirm
                title={t("automatedMessageForm.deleteEvent")}
                okText={t("common:switch.yes")}
                cancelText={t("common:button.cancel")}
                placement="top"
                onConfirm={onRemove}
              >
                <Button danger icon={<DeleteOutlined style={{ pointerEvents: "none" }} />} type="text" />
              </Popconfirm>
            )}
          </Space>
        </Space>
      }
    >
      <MessageOutlined className="mr-2" />
      <Typography.Text>
        {item.sentTime.hours}:{minutesWithLeadingZero}
      </Typography.Text>
      {status === ProgramAutomationTaskStatus.Done && <CheckOutlined className="ml-2 text-green-500" />}
    </Popover>
  );
};

export default MessageAutomationItem;
